import ReactDOM from 'react-dom';
import MobileDetect from 'mobile-detect';

export default class Utils {
  constructor() {
    this.mobileDetect = new MobileDetect(window.navigator.userAgent);
  }

  getElementPosition(element, container) {
    let el = element;
    let xPosition = 0;
    let yPosition = 0;

    while (el && el !== container) {
      xPosition += (el.offsetLeft - el.scrollLeft + el.clientLeft);
      yPosition += (el.offsetTop - el.scrollTop + el.clientTop);

      el = el.offsetParent;
    }

    return { x: xPosition, y: yPosition };
  }

  getElementContainer(element) {
    let el = element;
    let container;

    while (!container) {
      const parent = el.offsetParent;
      const parentPosition = window.getComputedStyle(parent).position;

      if (parentPosition !== 'static') {
        container = parent;
      } else {
        el = parent;
      }
    }

    return container;
  }

  scrollScreenTo = (() => {
    let timer;

    return (scrollTarget = 0, duration = 500) => {
      const scrollPosition = window.pageYOffset;
      const delta = scrollTarget - scrollPosition;
      const start = Date.now();
      let factor = 0;

      if (timer) {
        clearInterval(timer);
      }

      function step() {
        factor = (Date.now() - start) / duration;

        if (factor >= 1) {
          clearInterval(timer);
          factor = 1;
        }

        const y = factor * delta + scrollPosition;

        window.scrollBy(0, y - window.pageYOffset);
      }

      timer = setInterval(step, 10);
      return timer;
    };
  })();

  getFileName = filename => filename.replace(/^C:\\fakepath\\/i, '');

  isComponentMounted = (component) => {
    try {
      ReactDOM.findDOMNode(component);
      return true;
    } catch (e) {
      return false;
    }
  };

  setBlurEffect() {
    const outerWrapper = document.querySelector('.wrapper-outer');
    if (outerWrapper) {
      outerWrapper.classList.add('blur');
    }
  }

  removeBlurEffect() {
    const outerWrapper = document.querySelector('.wrapper-outer.blur');
    if (outerWrapper) {
      outerWrapper.classList.remove('blur');
    }
  }

  getQueryObject = (params) => {
    const queryObject = {};
    const queryVars = params.replace(/^\?/, '').split('&');

    queryVars.forEach((variable) => {
      const pair = variable.split('=');
      queryObject[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });

    return queryObject;
  }

  formatAmount(inputValue) {
    const formatNumber = new Intl.NumberFormat('en').format;
    if (!inputValue || !(`${inputValue}`).match(/\d/g)) {
      return { value: '', formattedValue: '' };
    }
    const value = parseFloat((`${inputValue}`).replace(/,/g, ''));
    const formattedValue = formatNumber(value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return { value, formattedValue };
  }

  isMobileDevice() {
    return this.mobileDetect.mobile();
  }
}

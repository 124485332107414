import React from 'react';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import FormTextFieldWithTooltip from './../../base/components/form-text-field-with-tooltip';
import FormMessage from './../../app/components/form-message';
import PhoneVerifyGroup,
{ schema as phoneVerifySchema } from './../../phone-verify/components/phone-verify-form-group';
import SelectCountry,
{ schema as selectCountrySchema } from './../../base/form/fields/select-country';
import Address, {
  schemaRequired as addressSchemaRequired,
} from './../../base/form/fields/address';

export default class MyDetailsForm extends FormComponent {
  static propTypes = {
    initialValues: React.PropTypes.object,
  };

  constructor(props) {
    const {
      email,
      address,
      phone,
    } = props.initialValues;
    const schema = {
      email: yup.string().email().required('Please enter email').default(email),
      mobileReference: phoneVerifySchema.mobileReference,
      phoneCode: phoneVerifySchema.phoneCode.default({value: phone ? phone.code : null}),
      mobile: phoneVerifySchema.mobile.required('Please enter a mobile number').default(phone ? phone.number : null),
      homeCode: phoneVerifySchema.phoneCode,
      homePhone: phoneVerifySchema.mobile,
      currentAddressCountry: selectCountrySchema.default({value: address ? address.country : null}),
      currentAddressPostCode: addressSchemaRequired.postCode.default(address ? address.postcode : null),
      currentAddressAddressLine1: addressSchemaRequired.address1.default(address ? address.line1 : null),
      currentAddressAddressLine2: addressSchemaRequired.address2.default(address ? address.line2 : null),
      currentAddressCity: addressSchemaRequired.city.default(address ? address.town : null),
    };

    const state = {
      formSuccessMessage: false,
      formEnabled: false,
      submitted: false,
    };

    super(props, state, schema);
  }

  onToggleEdit = e => {
    e.preventDefault();
    this.setState(state => {
      return {
        ...state,
        formEnabled: !state.formEnabled,
      };
    });
  };

  formOnSubmit = (fields) => {
    this._formOnSubmit(fields);

    this.setState({
      formSuccessMessage: false,
    });

    const stepData = {
      address: {
        country: fields.currentAddressCountry,
        line1: fields.currentAddressAddressLine1,
        line2: fields.currentAddressAddressLine2,
        postcode: fields.currentAddressPostCode,
        town: fields.currentAddressCity,
      },
      mobile: {
        code: +fields.phoneCode,
        number: +fields.mobile,
      },
      email: fields.email,
    };

    if (+fields.homePhone > 0) {
      stepData['home'] = {
        code: +fields.homeCode,
        number: +fields.homePhone,
      };
    }

    const changeDetailsGateway = serviceLocator.get('UserPersonalGateway');
    changeDetailsGateway
    .post(stepData, true)
    .then(response => {
      this.setState({
        formSuccessMessage: response.message,
        formErrors: {},
        submitted: true,
        formEnabled: false,
      });
    })
    .catch(error => { this._onFailResponse(error); });
  };

  onSetCurrentAddress = (
    currentAddressAddressLine1,
    currentAddressAddressLine2,
    currentAddressCity
  ) => {
    this.setFieldsValues({
      currentAddressAddressLine1,
      currentAddressAddressLine2,
      currentAddressCity,
    });
  };

  renderDetailsFieldset() {
    const { formEnabled, submitted, formSuccessMessage } = this.state;

    return (
      <fieldset className={formEnabled ? 'form-enabled' : null}>
        <h2>Personal Details <a href={'#'} onClick={this.onToggleEdit}><span className={"fa fa-pencil"}></span></a></h2>
        <div className="form-group-row">
          <FormGroup
            fieldName="email"
            formGroupClassName="form-group-sm"
            icons
            >
            <label htmlFor="email">Email address</label>
            <FormTextFieldWithTooltip
              name="email"
              placeholder="Email address"
              className="form-control input-sm"
              id="email"
              disabled={!formEnabled}
              />
          </FormGroup>
        </div>
        <div className="form-group-row">
          <FormGroup
            formGroupClassName="form-group-mobile wide"
            fieldName="mobile"
            icons
            >
            <label htmlFor="mobileField">Mobile number</label>
            <PhoneVerifyGroup
              verifyLabel="Verify"
              verifiedLabel="Verified"
              modalTitle="Please verify your phone number"
              tooltip
              disabled={!formEnabled}
              verifiable={false}
            />
          </FormGroup>
        </div>
        <div className="form-group-row">
          <FormGroup
            formGroupClassName="form-group-mobile wide"
            fieldName="homePhone"
            icons
            >
            <label htmlFor="mobileField">Home number</label>
            <PhoneVerifyGroup
              label={'Home Number'}
              verifyLabel="Verify"
              verifiedLabel="Verified"
              modalTitle="Please verify your home phone number"
              tooltip
              disabled={!formEnabled}
              verifiable={false}
              codeFieldName={'homeCode'}
              numberFieldName={'homePhone'}
              />
          </FormGroup>
        </div>
        <div className="form-group-row">
          <label htmlFor="mobileField">Address</label>
          <SelectCountry
            className="input-sm"
            formGroupClassName="form-group-sm"
            name="currentAddressCountry"
            label="Address"
            placeholder="Current address"
            icons
            tooltip
            disabled={!formEnabled}
            />
          <Address
            postcodeName="currentAddressPostCode"
            countryName="currentAddressCountry"
            address1Name="currentAddressAddressLine1"
            address2Name="currentAddressAddressLine2"
            cityName="currentAddressCity"
            onSetAddress={this.onSetCurrentAddress}
            disabled={!formEnabled}
            />
        </div>

        <FormMessage htmlFor="common" />

        {formEnabled
          ? <FormGroup fieldName="nextStep" formGroupClassName="form-group-btn-submit">
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <Form.Button
                type="submit"
                className="btn btn-lg btn-block btn-info btn-personal-details-submit"
                disabled={submitted}
                >{submitted ? formSuccessMessage : 'Save changes'}</Form.Button>
            </div>
          </div>
        </FormGroup>
        : null
      }

    </fieldset>
  );
}


render() {
  return (
    <Form
      ref="form"
      component="div"
      className="form"
      schema={this.schema}
      value={this.state.value}
      errors={this.state.formErrors}
      onError={this.formOnError}
      onChange={this.formOnChange}
      onSubmit={this.formOnSubmit}
    >
      {this.renderDetailsFieldset()}
    </Form>
  );
}
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from './../../base/service/service-locator';

export default class AddUserModal extends React.Component {

  static propTypes = {
    show: React.PropTypes.bool,
    onCloseHandler: React.PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      formSuccessMessage: false,
      submitted: false,
      showModal: (typeof this.props.show !== 'undefined') ? this.props.show : true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.show !== 'undefined') {
      this.setState({
        showModal: nextProps.show,
      });
    }
  }

  onClose = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      showModal: false,
    }, () => {
      if (this.props.onCloseHandler) {
        this.props.onCloseHandler();
      }
    });
  };

  onRequestCallback = (event) => {
    event.preventDefault();

    const addRequestGateway = serviceLocator.get('UserAddRequestGateway');
    addRequestGateway
    .get()
    .then(response => {
      this.setState({
        formSuccessMessage: response.message,
        submitted: true,
        showDoneModal: true,
      });
    });
  };

  utils = serviceLocator.get('Utils');

  renderMainModal() {
    const { submitted, formSuccessMessage, showModal, showDoneModal } = this.state;

    return (
      <Modal
        className={`modal-add-user`}
        id={`modal_add_user`}
        show={showModal && !showDoneModal}
        onHide={this.onClose}
        >
        <Modal.Header>
          <Modal.Title>Add a user</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-center">
            To add a user please use the request a call back button below. A member of our team will be happy to assist you over the phone during office hours.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <div className="text-center">
            <button className="btn btn-info" onClick={this.onRequestCallback} disabled={submitted}>
              {submitted ? formSuccessMessage : 'Request a call back'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>);
    }

    renderDoneModal() {
      const { showModal, showDoneModal } = this.state;

      return (
        <Modal
          className={`modal-add-user-done`}
          id={`modal_add_user_done`}
          show={showModal && showDoneModal}
          onHide={this.onClose}
          >
          <Modal.Body>
            <div className="text-center" onClick={this.onClose}>
              <img src="images/icon-done.svg" />
              <p>DONE</p>
            </div>
          </Modal.Body>
        </Modal>);
      }

      render() {
        return (
          <div>
            {this.renderMainModal()}
            {this.renderDoneModal()}
          </div>
        );
      }
    }

import React from 'react';
import { Modal } from 'react-bootstrap';

const InformPopup = ({ showModal, text, onClose, onEntering, onExiting }) => (
  <Modal
    className="modal-inform"
    show={showModal}
    onEntering={onEntering}
    onExiting={onExiting}
  >
    <Modal.Body>
      <p className="text-center">{text}</p>
    </Modal.Body>

    <Modal.Footer>
      <div className="text-center">
        <button className="btn btn-primary" onClick={onClose}>OK</button>
      </div>
    </Modal.Footer>
  </Modal>
);
InformPopup.propTypes = {
  showModal: React.PropTypes.bool.isRequired,
  text: React.PropTypes.string.isRequired,
  onClose: React.PropTypes.func.isRequired,
  onEntering: React.PropTypes.func,
  onExiting: React.PropTypes.func,
};

export default InformPopup;

import React from 'react';
import serviceLocator from './../../base/service/service-locator';

export default class ReactivateCorporate extends React.Component {
  auth = serviceLocator.get('Auth');

  onRequestCallback = (e) => {
    e.preventDefault();

    //todo: back-end request
  };

  render() {
    return (
      <div className="reactivate-step reactivate-corporate">
        <div className="clearfix">
          <div className="col-md-8 col-md-offset-2">
            <h1>Welcome back</h1>
            <p>
              As you have not used your account in a while we need to check a few details.<br/>
              Please request a call back with the below button and a member of our team
              will be happy to assist you over the phone during office hours.
            </p>
          </div>
        </div>

        <div className="row btn-wrap">
          <div className="col-sm-6 col-md-offset-3">
            <button
              type="button"
              className="btn btn-sm btn-primary btn-block btn-reactivate-corporate"
              onClick={this.onRequestCallback}
            >Request a call back</button>
          </div>
        </div>
      </div>
    );
  }
}

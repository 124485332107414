const counters = () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  // MaxMind code
  /* eslint-disable */
  window.maxmind_user_id = '117034';
  (function() {
  var loadDeviceJs = function()
  { var element = document.createElement('script'); element.src = ('https:' == document.location.protocol ? 'https:' : 'http:') + '//device.maxmind.com/js/device.js'; document.body.appendChild(element); }
  ;
  if (window.addEventListener)
  { window.addEventListener('load', loadDeviceJs, false); }
  else if (window.attachEvent)
  { window.attachEvent('onload', loadDeviceJs); }
  })();
  /* eslint-enable */
};

export default counters;

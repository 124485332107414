import React from 'react';
import Form from 'react-formal';
import Select from 'react-select';
import yup from 'yup';
import SelectWithTooltip from './../../components/select-with-tooltip';
import SelectCountry from './select-country';
import Config from 'Config';

export default class SelectPhoneCode extends SelectCountry {
  loadData() {
    this.countryGateway
      .getList(countryList => {
        const countryPhoneCodes = countryList.map(country => ({
          value: this.countryGateway.getCodeByCountryShortCode(country.value),
          label: country.label,
        }));

        this.setState({
          options: countryPhoneCodes,
        });
      });
  }

  valueRenderer(option) {
    return `+${option.value}`;
  }

  render() {
    const fieldProps = {
      id: `${this.props.name}Field`,
      name: this.props.name,
      placeholder: this.props.placeholder,
      className: this.props.className,
      options: this.state.options,
      clearable: false,
      backspaceRemoves: false,
      disabled: this.props.disabled,
      valueRenderer: this.valueRenderer || null,
      inputPosition: 'menu',
    };

    const label = this.props.label ?
      <label htmlFor={`${this.props.name}Field`} className="sr-only">{this.props.label}</label> :
      null;

    const field = this.props.tooltip ?
      <SelectWithTooltip {...fieldProps} /> :
      <Form.Field type={Select} {...fieldProps} />;

    return (
      <div
        className="form-group form-group-sm form-input-group-select form-input-group-select-left"
      >
        {label}
        {field}
      </div>
    );
  }
}

export const schema = yup.object()
  .required('Please select country')
  .default(Config.defaultPhoneCode);

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator';
import ticker from './../quote-ticker';
import { TYPE_DEBIT } from './../../payment/components/payment';
import UpdatableComponent from './../../base/components/updatable-component';
import FormattedCurrency from './../../base/components/formatted-currency';

export default class MakeTransferSummary extends UpdatableComponent {
  utils = serviceLocator.get('Utils');

  constructor(props) {
    super(props);
    this.state = {
      show: this.props.showModal,
      confirmed: false,
    };

    this.tickTimer = this.tickTimer.bind(this);
    this.showConfirmButton = this.showConfirmButton.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onRequote = this.onRequote.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      show: newProps.showModal,
    });

    if (newProps.showModal) {
      ticker.register(this.tickTimer);
    }
  }

  tickTimer(timeout) {
    if (!this.utils.isComponentMounted(this)) {
      return;
    }

    if (parseInt(timeout, 10) > 0 && this.utils.isComponentMounted(this)) {
      this.setState({ quoteTimeout: parseInt(timeout, 10) - 1 });
    } else {
      this.setState({
        confirmed: false,
      });
    }
  }

  static fees(quoteValues) {
    const { fees, crossBorderChargeApplied, creditCardChargeApplied } = quoteValues;
    const { transfer, crossBorder, creditCard } = fees;
    let totalFee = transfer;

    if (crossBorderChargeApplied) {
      totalFee += crossBorder;
    }

    if (creditCardChargeApplied) {
      totalFee += creditCard;
    }

    return totalFee;
  }

  showConfirmButton() {
    this.setState({
      confirmed: !this.state.confirmed,
    });
  }

  onConfirm() {
    this.setState({
      confirmed: false,
      show: false,
    });
    this.props.onConfirm();
  }

  onRequote() {
    this.props.onReQuote();
    this.setState({
      confirmed: false,
    });
  }

  render() {
    const { quoteValues } = this.props;
    const { smallSendOutValues } = this.props;
    const { getFormatedTimer } = serviceLocator.get('UtilsDate');
    return (
      <Modal
        className="modal-summary"
        id="modalSummary"
        show={this.state.show}
      >
        <Modal.Body>
          <div className="summary">
            <div className="summary-header">
              <h3>Please review your transaction and confirm your currency order</h3>
            </div>
            {
              quoteValues ?
                <div className="summary-body">
                  <div className="summary-info">
                    <div>
                      <h2>You are paying Currency Solutions by</h2>
                      <b>{this.props.cardType === TYPE_DEBIT ? 'Debit card' : 'Bank transfer'}</b>
                    </div>

                    {
                      smallSendOutValues && smallSendOutValues.beneficiaryId ? <div>
                        <h2>We will be sending your currency to </h2>
                        <b>{smallSendOutValues.beneficiaryId.label}</b>
                      </div> : ''
                    }

                    <br />

                    <div className="row">
                      <div className="col-xs-5">Sell</div>
                      <div className="col-xs-7 text-right">
                        <b>
                          <FormattedCurrency
                            value={quoteValues.sellAmount}
                            currency={quoteValues.sellCurrency}
                          />
                        </b>
                      </div>
                    </div>

                    <hr />
                    <div className="row">
                      <div className="col-xs-5">Buy</div>
                      <div className="col-xs-7 text-right">
                        <b>
                          <FormattedCurrency
                            value={quoteValues.buyAmount}
                            currency={quoteValues.buyCurrency}
                          />
                        </b>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col-xs-5">Transfer fee</div>
                      <div className="col-xs-7 text-right">
                        <b>
                          <FormattedCurrency
                            value={MakeTransferSummary.fees(quoteValues)}
                            currency={quoteValues.sellCurrency}
                          />
                        </b>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 visible-xs">
                        <div className="text-center">
                          <span>
                              {getFormatedTimer(this.state.quoteTimeout)}
                          </span>
                          <div className="clearfix" />
                        </div>
                      </div>
                    </div>
                    <div className="row total">
                      <div className="col-xs-5">Total Cost</div>
                      <div className="col-xs-7 text-right">
                        <b>
                          <FormattedCurrency
                            value={quoteValues.totalAmount}
                            currency={quoteValues.sellCurrency}
                          />
                        </b>
                      </div>
                      <div className="quote-tick hidden-xs">
                        {getFormatedTimer(this.state.quoteTimeout)}
                      </div>
                    </div>
                  </div>
                </div> : ''
            }

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group checkbox text-left">
                  <label>
                    <div className="row">
                      <div className="col-sm-1 col-xs-2">
                        <input
                          type="checkbox"
                          name="confirm"
                          onChange={this.showConfirmButton}
                        />
                        <span className="input" />
                      </div>
                      <div className="col-sm-11 col-xs-10">
                        <span className="cmf-text">
                          {
                            this.props.cardType === TYPE_DEBIT ?
                              <span>
                                By ticking here you are agreeing to
                                purchase the currency detailed above
                                and we will buy this currency from the
                                market for you. By clicking Confirm Purchase,
                                payment will be collected from your debit
                                card to Currency Solutions. If you no longer
                                require this currency after confirming your
                                purchase, we will sell the currency purchased
                                for you back to the market at the rate of
                                exchange at that point in time. You will be
                                liable for any costs or losses that may be
                                incurred.
                              </span> : <span>
                                By ticking here you are agreeing to purchase
                                the currency detailed above and we will buy
                                this currency from the market for you.
                                You will be required to make a payment to
                                Currency Solutions by Bank Transfer.
                                If you no longer require this currency after
                                confirming your purchase, we will sell the
                                currency purchased for you back to the market
                                at the rate of exchange at that point in time.
                                You will be liable for any costs or losses that
                                may be incurred.
                              </span>
                          }
                        </span>
                      </div>
                    </div>
                  </label>
                </div>

                <button
                  className="btn btn-lg btn-block btn-primary btn-quote-submit"
                  name="confirm"
                  onClick={this.onConfirm}
                  disabled={!this.state.confirmed}
                >
                  confirm purchase
                </button>
              </div>
              <div className="col-sm-12 text-center">
                <button
                  className="btn btn-label requote"
                  onClick={this.onRequote}
                >
                  Requote &gt;
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

MakeTransferSummary.propTypes = {
  showModal: PropTypes.bool,
  quoteValues: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onReQuote: PropTypes.func.isRequired,
};

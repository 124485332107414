import React from 'react';
import { Modal } from 'react-bootstrap';
import UpdatableComponent from './../../base/components/updatable-component';
import CaptchaField from './../../captcha/form/fields/captcha-field';

export default class CaptchaModal extends UpdatableComponent {
  constructor(props) {
    super(props);
    this.onVerify = this.onVerify.bind(this);
  }

  onVerify(response) {
    this.props.onVerify(response);
  }

  render() {
    return (
      <Modal
        show
        className="modal-Captcha"
      >
        <Modal.Header>
          <Modal.Title>Please confirm that you are not a robot</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CaptchaField onVerify={this.onVerify} />
        </Modal.Body>
      </Modal>
    );
  }
}

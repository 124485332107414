import AppConfig from 'AppConfig';
let iclaus;
let isReady = false;
let onReadyQueue = [];
const resolvers = {};

export const setIclaus = node => iclaus = node;

export const onIclausReady = () => {
  isReady = true;

  if (onReadyQueue.length) {
    onReadyQueue.map(({ action, data: body, reqId: requestId }) => {
      iclaus.postMessage(
        {
          action,
          body,
          requestId,
        },
        AppConfig.claus.hostname
      );
    });
    onReadyQueue = [];
  }
};

export const postMessage = (action, data) => new Promise((resolve, reject) => {
  const reqId = `pm-${Math.floor(Math.random() * 1e10)}-${Math.floor(Math.random() * 1e16)}`;
  resolvers[reqId] = (data) => resolve(data);

  if (isReady) {
    iclaus.postMessage(
      {
        action,
        body: data,
        requestId: reqId,
      },
      AppConfig.claus.hostname
    );
  } else {
    onReadyQueue.push({
      action,
      data,
      reqId,
    });
  }
});

export const resolveReq = (reqId, data) => {
  if (resolvers[reqId] && typeof resolvers[reqId] === 'function') {
    resolvers[reqId](data);
    delete resolvers[reqId];
  }
};

export const onMessage = origins => event => {
  if (!event || !event.origin) {
    console.error('Wrong response Post Message', event);
    return;
  }

  if (Array.isArray(origins) && origins.length && !origins.includes(event.origin)) {
    return;
  }

  const { data } = event;
  if (!data || !data.action || data.action != 'response' || !data.requestId) {
    return;
  }

  resolveReq(data.requestId, data.body)
};

import React from 'react';
import serviceLocator from './../service/service-locator';

export default class FormAmountField extends React.Component {
  static propTypes = {
    onChange: React.PropTypes.func,
    value: React.PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.formatAmount = serviceLocator.get('Utils').formatAmount;
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler(e) {
    let {value} = e.target;
    const {onChange} = this.props;

    if (value === undefined) {
      return;
    }

    value = typeof value === 'string' ? value : `${value}`;

    if (value.indexOf('.') !== -1 && !value.match(/\.\d{0,2}$/) || value.split(".").length - 1 > 1) {
      return;
    }

    if (value.slice(-1) === '.' || value.slice(-2) === '.0') {
      onChange(value);
      return;
    }

    const {formattedValue} = this.formatAmount(value);
    onChange(formattedValue);
  }

  render() {
    const fieldProps = {
      ...this.props,
      type: 'text',
      value: this.props.value || '',
    };

    delete fieldProps.meta;

    return (
      <input
        {...fieldProps}
        ref="input"
        onChange={this.onChangeHandler}
      />
    );
  }
}

import React from 'react';
import Form from 'react-formal';
import yup from 'yup';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import serviceLocator from '../../base/service/service-locator';
import FormMessage from './../../app/components/form-message';
import FormGroup from './../../app/components/form-group';
import Radio from './../../base/react-formal/inputs/radio';
import FormComponent from '../../base/components/form-component';
import BeneficiaryForm,
  { schema as beneficiarySchema } from './../../beneficiary/components/beneficiary-form';
import { schema as bankDetailsSchema } from './../../beneficiary/components/bank-details-form';
// eslint-disable-next-line max-len
import { schema as phoneVerifySchema } from './../../phone-verify/components/phone-verify-form-group';
import Loader from './../../app/components/loader';
import Config from 'Config';

export default class BeneficiaryFormModal extends FormComponent {
  utils = serviceLocator.get('Utils');
  currencyGateway = serviceLocator.get('CurrencyGateway');
  beneficiaryGateway = serviceLocator.get('BeneficiaryGateway');
  countryGateway = serviceLocator.get('CountryGateway');
  currency = null;

  constructor(props) {
    const state = {
      currencyList: [],
      reasonsList: [],
      disabledFields: {},
      formDisabled: false,
      beneficiaryId: props.beneficiaryId || -1,
      value: {
        destinationCountryId: Config.defaultCountry,
        currency: Config.defaultCurrency,
      },
      isLoading: true,
      isCurrencyLoading: true,
      isBeneficiaryLoading: false,
      isBeneficiarySubmitting: false,
      action: (props.beneficiaryId !== -1) ? 'Edit' : 'Add new',
    };

    const schema = {
      currency: yup.object().default(Config.defaultCurrency),
      thirdParty: yup.string().default('0'),
      ...beneficiarySchema,
      ...bankDetailsSchema,
      ...phoneVerifySchema,
    };

    super(props, state, schema);
  }

  componentWillMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      isCurrencyLoading: true,
      isBeneficiaryLoading: (this.state.beneficiaryId !== -1),
    }, () => {
      if (this.state.beneficiaryId !== -1) {
        this.setState({
          isBeneficiaryLoading: true,
        });
        this.beneficiaryGateway.get({
          id: this.state.beneficiaryId,
        }).then(response => {
          this.setState({
            isBeneficiaryLoading: false,
            isLoading: this.state.isCurrencyLoading,
          });

          const beneficiaryData = {
            name: response.beneficiary.name,
            reference1: response.beneficiary.reference1,
            accountNumber: response.beneficiary.accountNumber,
            bankIdentifier: response.beneficiary.bankIdentifier,
            intermediaryBank: response.beneficiary.intermediaryBank,
            address1: response.beneficiary.address1,
            address2: response.beneficiary.address2,
            transitCode: response.beneficiary.transitCode,
            ifscCode: response.beneficiary.ifscCode,
            bsbCode: response.beneficiary.bsbCode,
            thirdParty: response.beneficiary.thirdParty,
          };
          beneficiaryData.currency = {
            value: response.beneficiary.currency,
            label: response.beneficiary.currency,
          };
          beneficiaryData.destinationCountryId = {
            value: response.beneficiary.destinationCountryId,
            label: this.countryGateway.getNameByCountryShortCode(
              response.beneficiary.destinationCountryId
            ),
          };
          beneficiaryData.countryId = beneficiaryData.destinationCountryId;
          this.setFieldsValues(beneficiaryData);
        });
      }

      this.currencyGateway
        .getList(currencyList => {
          this.setState({
            currencyList,
            isCurrencyLoading: false,
            isLoading: this.state.isBeneficiaryLoading,
          });
        });
    });
  }

  formOnSubmit = (fields) => {
    this.setState({
      isBeneficiarySubmitting: true,
    });

    const fieldsToSend = fields;

    if (this.state.beneficiaryId !== -1) {
      fieldsToSend.id = this.state.beneficiaryId;
    }

    fieldsToSend.destinationCountryId = fields.destinationCountryId.value;
    fieldsToSend.countryId = fields.countryId ?
      fields.countryId.value :
      fieldsToSend.destinationCountryId;
    fieldsToSend.currency = fields.currency.value;
    fieldsToSend.address1 = fields.address1 || '';
    fieldsToSend.address2 = fields.address2 || '';
    fieldsToSend.transitCode = fields.transitCode || '';
    fieldsToSend.ifscCode = fields.ifscCode || '';
    fieldsToSend.bsbCode = fields.bsbCode || '';

    const promise = (this.state.beneficiaryId === -1) ?
      this.beneficiaryGateway.post(fieldsToSend) :
      this.beneficiaryGateway.put(fieldsToSend);

    promise
      .then(() => {
        this.setState({
          isBeneficiarySubmitting: false,
        });
        if (this.props.onSave) {
          this.props.onSave();
        }
      })
      .catch((failResponse) => {
        this.setState({
          isBeneficiarySubmitting: false,
        });
        this._onFailResponse(failResponse);
      });
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  renderForm() {
    return (
      <Form
        component="div"
        ref="form"
        schema={this.schema}
        value={this.state.value}
        errors={this.state.formErrors}
        onError={this.formOnError}
        onChange={this.formOnChange}
        onSubmit={this.formOnSubmit}
        className="form beneficiary-form-modal"
        id="beneficiaryForm"
      >
        <div className="form-group row">
          <label htmlFor="currency" className="label-col">Beneficiary currency</label>

          <FormGroup
            formGroupClassName="form-group-select input-col form-group-sm"
            fieldName="currency"
            icons
          >
            <Form.Field
              type={Select}
              name="currency"
              id="currency"
              className="input-sm"
              placeholder="Beneficiary currency"
              options={this.state.currencyList}
              clearable={false}
              searchable
            />
          </FormGroup>
        </div>

        <BeneficiaryForm context={this.getChildContext()} />

        <div className="form-group row">
          <label htmlFor="thirdPartyTrigger" className="label-col">This account belongs to</label>

          <FormGroup formGroupClassName="input-col form-group-sm">
            <div className="radio">
              <label htmlFor="thirdParty-1">
                <Form.Field type={Radio} name="thirdParty" id="thirdParty-1" optionValue="0" />
                <span className="input"></span>
                Me or I’m a joint account holder
              </label>
            </div>

            <div className="radio">
              <label htmlFor="thirdParty-2">
                <Form.Field type={Radio} name="thirdParty" id="thirdParty-2" optionValue="1" />
                <span className="input"></span>
                A third party
              </label>
            </div>
          </FormGroup>
        </div>

        <div className="form-group row">
          <FormMessage htmlFor="common" />
        </div>

        <div className="text-center">
          <Form.Button
            type="submit"
            className="btn btn-primary btn-save-beneficiary"
            disabled={this.state.isBeneficiarySubmitting}
          >
            Save
          </Form.Button>
          <button className="btn btn-link" onClick={this.onCancel}>Cancel</button>
        </div>
      </Form>);
  }

  render() {
    return (
      <Modal
        show
        className="modal fade"
        id="beneficiaryForm"
        onExiting={this.utils.removeBlurEffect}
        onHide={this.onCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.state.action} beneficiary</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-sm-12">
              {this.state.isLoading ? <Loader /> : this.renderForm()}
            </div>
          </div>
        </Modal.Body>
      </Modal>);
  }
}

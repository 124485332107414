import React from 'react';
import yup from 'yup';
import UpdatableComponent from './../../base/components/updatable-component';
import serviceLocator from '../../base/service/service-locator';
import FormGroup from './../../app/components/form-group';
import FormTextField from './../../base/components/form-text-field';
import SelectCountry from './../../base/form/fields/select-country';
import Tooltip from './../../app/components/tooltip';

export default class BankDetailsForm extends UpdatableComponent {

  sepaCountryGateway = serviceLocator.get('SepaCountryGateway');
  addressRequiredCountryGateway = serviceLocator.get('AddressRequiredCountryGateway');
  fields = {
    accountNumber: {
      show: true,
      label: 'IBAN or Acount No',
      placeholder: 'Account number',
      tooltip: 'Account Number of Beneficiary Explanation',
    },
    bankIdentifier: {
      show: true,
      label: 'SWIFT BIC',
      placeholder: 'Bank Identifier',
      tooltip: 'Bank Identifier of Beneficiary Explanation',
    },
    intermediaryBank: {
      show: false,
      label: 'Intermediary bank SWIFT BIC',
      placeholder: 'Intermediary Bank',
      // eslint-disable-next-line max-len
      tooltip: 'Only use if your beneficiary bank has specifically stated this information is necessary, otherwise please leave blank',
    },
    address1: {
      show: false,
      label: 'Beneficiary address',
      placeholder: 'Beneficiary address',
      tooltip: 'The beneficiary address (of the individual or company)',
    },
    address2: {
      show: false,
      label: 'Beneficiary town/city',
      placeholder: 'Beneficiary town/city',
    },
    countryId: {
      show: false,
      label: 'Beneficiary country',
      placeholder: 'Beneficiary country',
    },
    transitCode: {
      show: false,
      label: 'Transit number',
      placeholder: 'For CAD to Canada',
      tooltip: 'A transit number  consists of a Branch number XXXXX and an Institution number YYY',
    },
    ifscCode: {
      show: false,
      label: 'IFSC no.',
      placeholder: 'For INR to India',
      // eslint-disable-next-line max-len
      tooltip: 'India Financial System Code (IFSC) is a 11 digit code CCCC0XXXXXX. First four characters (CCCC) are alphabetical characters representing the bank, the fifth digit (Z) is kept as a reserve for the future and is always zero and the remaining six (XXXXXX) numerical digits are the identification numbers given to the bank\'s individual branch. These digits are based on the state, district, city and branch of the bank',
    },
    bsbCode: {
      show: false,
      label: 'BSB number',
      placeholder: 'For AUD to Australia or NZD to New Zeland',
      // eslint-disable-next-line max-len
      tooltip: 'Bank State Branch (BSB) is a six digit numerical code XXY - ZZZ. The First two digits (XX) specify the parent financial institution. Third digit (Y) specifies the state where the branch is located. Fourth, fifth and sixth digits (ZZZ) specify the branch location',
    },
  };

  static contextTypes = {
    formValue: React.PropTypes.object,
    formDisabledFields: React.PropTypes.object,
  };

  state = {
    sepaCountryList: [],
    addressRequiredCountryList: [],
    ...this.fields,
    context: this.context,
  };

  componentWillMount() {
    this.setState({
      context: this.props.context,
    });
    this.loadData();
    this.setupForm();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    nextContext = nextProps.context || nextContext; // eslint-disable-line no-param-reassign
    if (
      // eslint-disable-next-line max-len
      this.state.context.formValue.destinationCountryId.value !== nextContext.formValue.destinationCountryId.value
      || this.state.context.formValue.currency.value !== nextContext.formValue.currency.value
    ) {
      this.setupForm(nextContext);
    }
    this.setState({
      context: nextContext,
    });
  }

  setupForm(context = this.state.context) {
    if (context.formValue &&
      context.formValue.destinationCountryId &&
      context.formValue.currency
    ) {
      this.setState(
        this.onCountryChange(
          context.formValue.destinationCountryId.value,
          context.formValue.currency.value
        )
      );
    }
  }

  loadData() {
    this.sepaCountryGateway.get().then(sepaCountryList => {
      this.setState({ sepaCountryList }, this.setupForm);
    });

    this.addressRequiredCountryGateway.get().then(addressRequiredCountryList => {
      this.setState({ addressRequiredCountryList }, this.setupForm);
    });
  }

  onCountryChange(countryVal, currencyVal) {
    // get initial fields data (reset fields)
    let fields = Object.keys(this.fields).reduce((f, key) => {
      // eslint-disable-next-line no-param-reassign
      f[key] = Object.assign({}, this.fields[key]);
      return f;
    }, {});

    if (countryVal === 'GB' && currencyVal === 'GBP') {
      fields.accountNumber = {
        show: true,
        label: 'Account number',
        placeholder: 'Account number',
        tooltip: '8 digit numerical number XXXXXXXX',
      };
      fields.bankIdentifier = {
        show: true,
        label: 'Sort code',
        placeholder: 'Sort code',
        tooltip: '6 digit numerical code used to specify the branch location XX-XX-XX',
      };
      return fields;
    }

    if (this.state.addressRequiredCountryList.indexOf(countryVal) !== -1) {
      fields.address1.show = true;
      // eslint-disable-next-line max-len
      fields.address1.tooltip = 'When sending funds to this country, the beneficiary address (of the individual or company) must be completed';
      fields.address2.show = true;
      fields.countryId.show = true;
    }

    if (countryVal === 'US' && currencyVal === 'USD') {
      fields.bankIdentifier = {
        show: true,
        label: 'SWIFT BIC or Bank Routing No.',
        placeholder: 'SWIFT BIC or Bank Routing No.',
        // eslint-disable-next-line max-len
        tooltip: 'A <b>SWIFT (BIC) code</b> is made up of 8 or 11 characters and identifies the bank you are sending to. Example: <b>DEUTDEFF500</b>. Bank Routing no. consists of a nine digit numerical code XXXXXXXX used to identify the individual branch of a financial institution.',
      };
      fields = this.setNotSepa(fields);
      return fields;
    }

    if (countryVal === 'ZA' && currencyVal === 'ZAR') {
      fields.bankIdentifier = {
        show: true,
        label: 'SWIFT BIC or Bank branch code',
        placeholder: 'SWIFT BIC or Bank branch code',
        // eslint-disable-next-line max-len
        tooltip: 'A <b>SWIFT (BIC) code</b> is made up of 8 or 11 characters and identifies the bank you are sending to. Example: <b>DEUTDEFF500</b>. Bank branch code is a six digit numerical code XXXXXX specifying the branch location.',
      };
      fields = this.setNotSepa(fields);
      return fields;
    }

    fields.bankIdentifier = {
      show: true,
      label: 'SWIFT BIC',
      placeholder: 'SWIFT BIC',
      // eslint-disable-next-line max-len
      tooltip: 'A <b>SWIFT (BIC) code</b> is made up of 8 or 11 characters and identifies the bank you are sending to. Example: <b>DEUTDEFF500</b>',
    };

    // SEPA countries
    if (this.state.sepaCountryList.indexOf(countryVal) !== -1) {
      fields.accountNumber = {
        show: true,
        label: 'IBAN',
        placeholder: 'IBAN',
        // eslint-disable-next-line max-len
        tooltip: 'An IBAN can be verified to ensure that the digits are correct. Please note that a correct IBAN does not confirm that the account exists',
      };
      return fields;
    }

    if (countryVal === 'MX' && ['MXN', 'USD'].indexOf(currencyVal) !== -1) {
      fields.accountNumber = {
        show: true,
        label: 'CLABE number',
        placeholder: 'CLABE number',
        // eslint-disable-next-line max-len
        tooltip: 'A CLABE number is an eighteen digit numerical code XXX – XXX – XXXXXXXXXXX – X used to identify the bank, branch and account number, plus a check digit at the end',
      };

      fields.intermediaryBank.show = true;
      return fields;
    }

    // All other countries
    fields = this.setNotSepa(fields);

    if ((countryVal === 'AU' && currencyVal === 'AUD') ||
      (countryVal === 'NZ' && currencyVal === 'NZD')) {
      fields.bsbCode.show = true;
    }

    if (countryVal === 'CA' && currencyVal === 'CAD') {
      fields.transitCode.show = true;
    }

    if (countryVal === 'IN' && currencyVal === 'INR') {
      fields.ifscCode.show = true;
    }

    return fields;
  }

  setNotSepa(fields) {
    const f = fields;

    f.accountNumber = {
      show: true,
      label: 'IBAN or Account no.',
      placeholder: 'IBAN or Account no.',
      tooltip: 'Please enter an IBAN or Account number. An IBAN is preferred if available.',
    };

    f.intermediaryBank.show = true;

    return f;
  }

  renderAccountNumberRow() {
    return this.state.accountNumber.show
      ? <div className="form-group row">
        <label
          htmlFor="beneficiaryAccountTo"
          className="label-col"
        >{this.state.accountNumber.label}</label>
        <FormGroup
          formGroupClassName="input-col form-group-sm"
          fieldName="accountNumber"
          icons
        >
          <FormTextField
            name="accountNumber"
            placeholder={this.state.accountNumber.placeholder}
            type="text"
            disabled={this.context.formDisabledFields.accountNumber}
            className="form-control input-sm"
            id="beneficiaryAccountTo"
          />
        </FormGroup>
        <div className="tooltip-col hidden-xs">
          <Tooltip text={this.state.accountNumber.tooltip} />
        </div>
      </div>
      : null;
  }

  renderBankIdentifierRow() {
    return this.state.bankIdentifier.show
      ? <div className="form-group row">
        <label
          htmlFor="beneficiarySWIFT"
          className="label-col"
        >{this.state.bankIdentifier.label}</label>
        <FormGroup
          formGroupClassName="input-col form-group-sm"
          fieldName="bankIdentifier"
          icons
        >
          <FormTextField
            name="bankIdentifier"
            placeholder={this.state.bankIdentifier.placeholder}
            type="text"
            disabled={this.context.formDisabledFields.bankIdentifier}
            className="form-control input-sm"
            id="beneficiarySWIFT"
          />
        </FormGroup>
        <div className="tooltip-col hidden-xs">
          <Tooltip text={this.state.bankIdentifier.tooltip} />
        </div>
      </div>
      : null;
  }

  renderIntermediaryBankRow() {
    return this.state.intermediaryBank.show
      ? <div className="form-group row">
        <label
          htmlFor="beneficiaryIntermediaryBank"
          className="label-col"
        >{this.state.intermediaryBank.label}</label>
        <FormGroup
          formGroupClassName="input-col form-group-sm"
          fieldName="intermediaryBank"
          icons
        >
          <FormTextField
            name="intermediaryBank"
            placeholder={this.state.intermediaryBank.placeholder}
            type="text"
            disabled={this.context.formDisabledFields.intermediaryBank}
            className="form-control input-sm"
            id="beneficiaryIntermediaryBank"
          />
        </FormGroup>

        <div className="tooltip-col hidden-xs">
          <Tooltip text={this.state.intermediaryBank.tooltip} />
        </div>
      </div>
      : null;
  }

  renderAddress1Row() {
    return this.state.address1.show
      ? <div className="form-group row">
        <label
          htmlFor="beneficiaryAddress1"
          className="label-col"
        >{this.state.address1.label}</label>
        <FormGroup
          formGroupClassName="input-col form-group-sm"
          fieldName="address1"
          icons
        >
          <FormTextField
            name="address1"
            placeholder={this.state.address1.placeholder}
            type="text"
            disabled={this.context.formDisabledFields.address1}
            className="form-control input-sm"
            id="beneficiaryAddress1"
          />
        </FormGroup>
        <div className="tooltip-col hidden-xs">
          <Tooltip text={this.state.address1.tooltip} />
        </div>
      </div>
      : null;
  }

  renderAddress2Row() {
    return this.state.address2.show
      ? <div className="form-group row">
        <label
          htmlFor="beneficiaryAddress2"
          className="label-col"
        >{this.state.address2.label}</label>
        <FormGroup
          formGroupClassName="input-col form-group-sm"
          fieldName="address2"
          icons
        >
          <FormTextField
            name="address2"
            placeholder={this.state.address2.placeholder}
            type="text"
            disabled={this.context.formDisabledFields.address2}
            className="form-control input-sm"
            id="beneficiaryAddress2"
          />
        </FormGroup>
      </div>
      : null;
  }

  renderBeneficiaryCountryIdRow() {
    return this.state.countryId.show
      ? <div className="form-group row">
        <label
          htmlFor="beneficiaryCountryId"
          className="label-col"
        >{this.state.countryId.label}</label>
        <SelectCountry
          icons
          className="input-sm"
          formGroupClassName="input-col form-group-sm"
          name="countryId"
          id="beneficiaryCountryId"
          placeholder={this.state.countryId.placeholder}
          disabled={this.context.formDisabledFields.countryId}
        />
      </div>
      : null;
  }

  renderBeneficiaryTransitCodeRow() {
    return this.state.transitCode.show
      ? <div className="form-group row">
        <label
          htmlFor="beneficiaryTransitCode"
          className="label-col"
        >{this.state.transitCode.label}</label>
        <FormGroup
          formGroupClassName="input-col form-group-sm"
          fieldName="transitCode"
          icons
        >
          <FormTextField
            name="transitCode"
            placeholder={this.state.transitCode.placeholder}
            type="text"
            disabled={this.context.formDisabledFields.transitCode}
            className="form-control input-sm" id="beneficiaryTransitCode"
          />
        </FormGroup>
        <div className="tooltip-col hidden-xs">
          <Tooltip text={this.state.transitCode.tooltip} />
        </div>
      </div>
      : null;
  }

  renderBeneficiaryIfscCodeRow() {
    return this.state.ifscCode.show
      ? <div className="form-group row">
        <label
          htmlFor="beneficiaryIfscCode"
          className="label-col"
        >{this.state.ifscCode.label}</label>
        <FormGroup
          formGroupClassName="input-col form-group-sm"
          fieldName="ifscCode"
          icons
        >
          <FormTextField
            name="ifscCode"
            placeholder={this.state.ifscCode.placeholder}
            type="text"
            disabled={this.context.formDisabledFields.ifscCode}
            className="form-control input-sm"
            id="beneficiaryIfscCode"
          />
        </FormGroup>
        <div className="tooltip-col hidden-xs">
          <Tooltip text={this.state.ifscCode.tooltip} />
        </div>
      </div>
      : null;
  }

  renderBeneficiaryBsbCodeRow() {
    return this.state.bsbCode.show
      ? <div className="form-group row">
        <label
          htmlFor="beneficiaryBsbCode"
          className="label-col"
        >{this.state.bsbCode.label}</label>
        <FormGroup
          formGroupClassName="input-col form-group-sm"
          fieldName="bsbCode"
          icons
        >
          <FormTextField
            name="bsbCode"
            placeholder={this.state.bsbCode.placeholder}
            type="text"
            disabled={this.context.formDisabledFields.bsbCode}
            className="form-control input-sm"
            id="beneficiaryBsbCode"
          />
        </FormGroup>
        <div className="tooltip-col hidden-xs">
          <Tooltip text={this.state.bsbCode.tooltip} />
        </div>
      </div>
      : null;
  }

  render() {
    return (
      <div className="form-group bank-details-form">
        {this.renderAccountNumberRow()}
        {this.renderBankIdentifierRow()}
        {this.renderIntermediaryBankRow()}
        {this.renderAddress1Row()}
        {this.renderAddress2Row()}
        {this.renderBeneficiaryCountryIdRow()}
        {this.renderBeneficiaryTransitCodeRow()}
        {this.renderBeneficiaryIfscCodeRow()}
        {this.renderBeneficiaryBsbCodeRow()}
      </div>);
  }
}

export const schema = {
  accountNumber: yup.string().required('You should enter account number'),
  bankIdentifier: yup.string().required('You should enter bank identifier'),
  intermediaryBank: yup.string(),
  address1: yup.string(),
  address2: yup.string(),
  countryId: yup.object(),
  transitCode: yup.string(),
  ifscCode: yup.string(),
  bsbCode: yup.string(),
};

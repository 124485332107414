import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

const MenuTab = ({ id, currentRoute, changeTab, url, icon, label }) => {
  const itemClassNames = classNames(`tab-menu-${id}`, {
    active: currentRoute,
  });
  const iconActiveClassNames = classNames('icon-active', 'icon-active');

  return (
    <li
      className={itemClassNames}
      onClick={changeTab}
    >
      <Link to={url}>
        <span className={iconActiveClassNames}></span>
        <svg viewBox="0 0 30 27">
            <use xlinkHref={"/images/" + icon + ".svg#Layer_1"}></use>
        </svg>
        <span className="tabs-menu-text">{label}</span>
      </Link>
    </li>);
};
MenuTab.propTypes = {
  id: React.PropTypes.string.isRequired,
  currentRoute: React.PropTypes.bool.isRequired,
  changeTab: React.PropTypes.func.isRequired,
  url: React.PropTypes.string.isRequired,
  icon: React.PropTypes.string.isRequired,
  label: React.PropTypes.string.isRequired,
};

export default MenuTab;

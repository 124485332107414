export default class MenuTabModel {
  /**
   * @param tab
   * @param isCurrent
   */
  constructor(tab) {
    this.dependancyData = {
      reactivate: {
        icon: 'icon-my-details',
        tabContentName: 'tab-reactivate',
      },
      'make-transfer': {
        icon: 'icon-make-transfer',
        tabContentName: 'tab-make-transfer',
      },
      'transfer-history': {
        icon: 'icon-transfer-history',
        tabContentName: 'tab-transfer-history',
      },
      beneficiaries: {
        icon: 'icon-beneficiaries',
        tabContentName: 'tab-beneficiaries',
      },
      statements: {
        icon: 'icon-statements',
        tabContentName: 'tab-statements',
      },
      'upload-documents': {
        icon: 'icon-upload-documents',
        tabContentName: 'tab-upload-documents',
      },

      'affiliate-enquiries': {
        icon: 'icon-affiliate-enquiries',
        tabContentName: 'tab-affiliate-enquiries',
      },
      'affiliate-commissions': {
        icon: 'icon-affiliate-commissions',
        tabContentName: 'icon-affiliate-commissions',
      },

      'my-details': {
        icon: 'icon-my-details',
        tabContentName: 'tab-my-details',
      },

      'admin-users': {
        icon: 'icon-admin-users',
        tabContentName: 'tab-admin-users',
      },

      'admin-banned-ips': {
        icon: 'icon-admin-banned-ips',
        tabContentName: 'tab-admin-banned-ips',
      },

      'admin-banned-emails': {
        icon: 'icon-admin-banned-emails',
        tabContentName: 'tab-admin-banned-emails',
      },
    };

    this.icon = '';
    this.tabContentName = 'no-class-content';

    this.setTab(tab);
  }

  /**
   * @returns {*}
   */
  isCurrentTab() {
    return this.isCurrentTab();
  }

  /**
   * @param tab
   */
  setTab(tab) {
    this.tab = tab;
    this.completeModel();
  }

  /**
   * @returns {*}
   */
  getTab() {
    return this.tab;
  }

  completeModel() {
    if (!this.dependancyData.hasOwnProperty(this.tab.id)) {
      this.tab.icon = this.icon;
      this.tab.tabContentName = this.tabContentName;

      return;
    }

    const data = this.dependancyData[this.tab.id];
    this.tab.icon = data.icon;
    this.tab.tabContentName = data.tabContentName;
  }
}

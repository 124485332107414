import DataModel from './data-model';

export default class BlobModel extends DataModel {
  _onSuccess(response) {
    return response;
  }

  _executeCommand(command, args) {
    const client = this.getDataClient();
    args.push({
      headers: this._getHeaders(),
      responseType: 'blob',
    });
    return client[command](...args)
      .then(response => this._onSuccess(response));
  }
}

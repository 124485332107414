import React from 'react';
import Recaptcha from 'react-recaptcha';
import UpdatableComponent from './../../../base/components/updatable-component';
import serviceLocator from '../../../base/service/service-locator';
import Config from 'Config';
import CustomEvent from 'custom-event';

export default class Captcha extends UpdatableComponent {
  constructor() {
    super();
    this.onLoad = this.onLoad.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.onExpired = this.onExpired.bind(this);

    this.captchaGateway = serviceLocator.get('CaptchaVerifyGateway');
  }

  onLoad() {
    if (this.props.onload) {
      this.props.onload();
    }
  }

  onVerify(response) {
    this.captchaGateway.post({
      'g-recaptcha-response': response,
    }).then((data) => {
      if (data.token) {
        this.captchaGateway.jwt.set(data.token);
      }
      if (this.props.onVerify) {
        this.props.onVerify(response);
      }
      const event = new CustomEvent('onCaptchaVerified');
      document.dispatchEvent(event);
    });
  }

  onExpired() {

  }

  render() {
    return (
      <div>
        <Recaptcha
          sitekey={Config.recaptcha.key}
          render="explicit"
          verifyCallback={this.onVerify}
          onloadCallback={this.onLoad}
          expiredCallback={this.onExpired}
        />
      </div>
    );
  }
}

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Form from 'react-formal';
import Dropzone from 'react-dropzone';
import serviceLocator from '../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormMessage from './../../app/components/form-message';
import FilesList from './files-list';
import Loader from './../../app/components/loader';
import Config from 'Config';
import CallUs from './../../base/components/call-us';

export default class UploadDocumentsForm extends FormComponent {

  idFileData = null;
  uploadDocumentsGateway = serviceLocator.get('UploadDocumentsGateway');
  uploadDocumentsFinishGateway = serviceLocator.get('UploadDocumentsFinishGateway');
  utils = serviceLocator.get('Utils');
  ga = serviceLocator.get('GA');

  static propTypes = {
    header: React.PropTypes.string,
    subtitle: React.PropTypes.string,
    showSkipLink: React.PropTypes.bool,
    idIsRequired: React.PropTypes.bool,
    billIsRequired: React.PropTypes.bool,
    onSkipUpload: React.PropTypes.func,
    onSuccess: React.PropTypes.func,
    onCancel: React.PropTypes.func,
    showForm: React.PropTypes.bool,
    showDetails: React.PropTypes.bool,
    entityType: React.PropTypes.string,
    entityId: React.PropTypes.string,
  };

  static defaultProps = {
    header: 'Required documents',
    subtitle: 'In order to enable you to start trading as soon as possible, please provide the following additional information.',
    showDetails: true,
    idIsRequired: true,
    billIsRequired: true,
    showSkipLink: false,
  }

  constructor(props) {
    const state = {
      idFileName: '',
      isLoading: false,
      hasUploadedFiles: false,
      uploadedFiles: [],
      buttonLabel: 'or browse for file',
      isFileInvalid: false,
      dropRejected: false,
    };

    super(props, state);
  }

  componentWillMount() {
    if (this.utils.isMobileDevice()) {
      this.setState({ buttonLabel: 'Browse for file or take a photo' });
    }
  }

  uploadSelectedDocument = () => {
    const { entityType, entityId } = this.props;
    this.ga.logEvent(entityType, 'Documents');

    this.setState({
      isLoading: true,
      formErrors: {},
    });

    const formData = new FormData();
    formData.append('file', this.idFileData, this.idFileData.name);
    formData.append('entityType', entityType);
    formData.append('entityId', entityId);
    this.uploadDocumentsGateway.post(formData)
      .then(this.onUploadSuccess)
      .catch(failResponse => {
        this.onUploadFail(failResponse);
      });
  };

  formOnSubmit = () => {
    const { entityType, entityId } = this.props;
    const refs = this.state.uploadedFiles.reduce((r, f) => {
      r.push(f.id);
      return r;
    }, []);

    this.uploadDocumentsFinishGateway.post({ entityType, entityId, refs }, true)
      .then(result => {
        if (result.item.success === true) {
          this.props.onSuccess();
        }
      });
  };

  onUploadFail = (failResponse) => {
    let proceed = true;
    if (failResponse instanceof Array) {
      failResponse.forEach(error => {
        if (proceed && error.code === 'FILE_UPLOAD_VALIDATION_FAILED') {
          this.setState({ isFileInvalid: true });
          proceed = false;
        }
      });
    }

    this.setState({
      isLoading: false,
    });

    this.logger.reportedErrors = {};

    if (proceed) {
      this._onFailResponse(failResponse);
    }
  };

  onUploadSuccess = (data) => {
    const files = this.state.uploadedFiles;
    files.push({
      id: data.item.ref,
      name: this.utils.getFileName(this.idFileData.name),
    });

    this.setState({
      isLoading: false,
      hasUploadedFiles: true,
      uploadedFiles: files,
    });
  };

  onDrop = (files) => {
    this.setState({
      dropRejected: false,
    });

    if (!files.length || !files[0]) {
      return;
    }

    const file = files[0];
    this.idFileData = file;

    this.setState({
      idFileName: this.utils.getFileName(file.name),
      isFileInvalid: false,
    });
    this.uploadSelectedDocument();
  };

  onDropRejected = () => {
    this.setState({
      dropRejected: true,
    });
  };

  onUploadLaterClick = (event) => {
    event.preventDefault();
    if (this.props.onSkipUpload) {
      this.props.onSkipUpload();
    }
  };

  onFileRemove = (id) => {
    let files = this.state.uploadedFiles;
    files = files.filter((value) => (
       parseInt(value.id, 10) !== parseInt(id, 10)
    ));

    this.setState({
      uploadedFiles: files,
    });
  };

  renderFileList() {
    if (this.state.hasUploadedFiles) {
      return (
        <FilesList
          files={this.state.uploadedFiles}
          onFileRemove={this.onFileRemove}
        />);
    }
    return null;
  }

      renderDropzone() {
        return (
          <Dropzone
            onDrop={this.onDrop}
            onDropRejected={this.onDropRejected}
            multiple={false}
            accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/tiff"
            style={{}}
            >
            <div className="form-group-row drop-zone text-center">
              <img src="/images/icon-cloud.svg" />
              <label className="text-center hidden-xs hidden-sm">Drop files to upload</label>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-sm btn-search btn-upload-file"
                  >{this.state.buttonLabel}</button>
                <div className="file-name text-center">{this.state.idFileName}</div>
              </div>
              <FormMessage for="idFile" />
              <div className="descr text-center">You can upload your files as a pdf, a scanned photo or an e-document.</div>
            </div>
          </Dropzone>);
        }

        renderErrorMessage() {
          const message = this.state.isFileInvalid || this.state.dropRejected
          ? <div className="form-group-message form-group-message-file-invalid alert-danger">
          {/* eslint-disable max-len */}
          Minimum file size: 1Kb, maximum file size: 10Mb, accepted file types are: *.jpeg, *.png, *.giff, *.bmp, *.tiff, *.pdf
          {/* eslint-enable max-len */}
        </div>
        : null;
        return (
          <div className="form-group-row">
            <div className="col-xs-12">
              <ReactCSSTransitionGroup
                component="div"
                transitionName="form-group-message"
                transitionEnterTimeout={Config.transitionTimeout.opacity}
                transitionLeaveTimeout={Config.transitionTimeout.opacity}
                >
                {message}
              </ReactCSSTransitionGroup>
            </div>
          </div>);
        }

        renderSkipLink() {
          return !!this.props.showSkipLink && typeof this.props.onSkipUpload === 'function'
          ? <button
          onClick={this.onUploadLaterClick}
          type="button"
          className="btn btn-lg btn-link btn-block"
          >I will upload these documents later</button>
        : null;
      }

      renderSuccessMessage() {
        return (
          <div className="upload-documents">
            <div className="clearfix">
              <div className="col-sm-8 col-sm-offset-2">
                <div className="success-icon"></div>
                <h3>Documents successfully uploaded</h3>
                <p>
                  Your documents were successfully uploaded.<br />
                <CallUs message="If you have any questions, please call us" newLine={false} />
              </p>
            </div>
          </div>
        </div>);
      }

      renderDetails() {
        if (!this.props.showDetails) {
          return null;
        }

        return (
          <div className="upload-documents">
            <div className="clearfix">
              <div className="row">
                {this.props.idIsRequired
                  ? <div className="col-md-5 col-md-offset-1">
                  <h3>1. Proof of identity</h3>
                  <ul>
                    <li><i className="fa fa-check"></i> Your passport</li>
                  </ul>
                  <p>
                    Please ensure you scan the entire page, paying attention to the MRZ code section at the bottom of
                    the page.
                  </p>
                </div>
                : null
              }
              {this.props.billIsRequired
                ? <div className="col-md-5">
                <h3>2. Proof of address</h3>
                <ul>
                  <li><i className="fa fa-check"></i> Bank statement OR</li>
                  <li><i className="fa fa-check"></i> Utility bill OR</li>
                  <li><i className="fa fa-check"></i> Government issued document</li>
                </ul>
                <p>Please ensure your name and full address are clearly visible on the document you are sending.</p>
              </div>
              : null
            }

          </div>
        </div>
      </div>
    );
  }

  render() {
    const {header, subtitle, onCancel} = this.props;

    return !this.props.showForm
    ? this.renderSuccessMessage()
    : (
      <div className="make-transfer-step upload-documents">
        <div className="clearfix">
          <div className="upload-documents">
            <h1 className="text-center">{header}</h1>
            <div className="row">
              {subtitle !== '' ? <p className="intro-text text-center col-md-10 col-md-offset-1">{subtitle}</p> : null}
              {this.renderDetails()}
              {subtitle !== '' ? <h2 className="text-center">Document upload</h2> : null}
            </div>
            <Form
              ref="form"
              component="div"
              className="form form-horizontal"
              schema={this.schema}
              value={this.state.value}
              errors={this.state.formErrors}
              onError={this.formOnError}
              onChange={this.formOnChange}
              onSubmit={this.formOnSubmit}
              encType="multipart/form-data"
              >
              <div className="form-group-wrap">
                {this.renderFileList()}
                {this.renderDropzone()}
                {this.renderErrorMessage()}
                {this.state.isLoading ? <Loader /> : null}
              </div>
              <div className="row">
                <div className="btn-wrap text-center">
                  <Form.Button
                    type="submit"
                    className="btn btn-default btn-block btn-cancel-upload"
                    onClick={onCancel}
                    >Cancel</Form.Button>
                  <Form.Button
                    type="submit"
                    className="btn btn-primary btn-block btn-confirm-upload"
                    disabled={
                      !this.state.hasUploadedFiles ||
                      !this.state.uploadedFiles.length
                    }
                    >Confirm</Form.Button>
                  {this.renderSkipLink()}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }

  formOnError = (formErrors, onErrorSetHandler = null) => {
    if (onErrorSetHandler) {
      onErrorSetHandler();
    }
  }
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import FormGroup from './../../app/components/form-group';

const NewPassword = () => (
  <Modal
    className="modal-reset-password"
    show
    animation={false}
    bsSize="small"
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header>
      <div className="navbar-brand">CURRENCY SOLUTIONS</div>
      <Modal.Title>Create new password</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <span className="success-message">
        <p>
          This is your first login attempt.
          We have sent you an email with reset password link.
        </p>
        <p>
          Please follow the instructions to set up your password.
        </p>
      </span>
    </Modal.Body>
    <Modal.Footer>
      <div className="text-center">
        <FormGroup formGroupClassName="form-group-btn-submit">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block btn-reset-password"
            onClick={event => {
              event.preventDefault();
              location.href = '/';
            }}
          >Log in</button>
        </FormGroup>
      </div>
    </Modal.Footer>
  </Modal>
);

export default NewPassword;

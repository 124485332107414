import React from 'react';
import { Link, browserHistory } from 'react-router';
import serviceLocator from './../../base/service/service-locator';

export default class TradeConfirmation extends React.Component {
  static propTypes = {
    tradeId: React.PropTypes.number,
    signedTradeId: React.PropTypes.string,
    location: React.PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      tradeId: props.tradeId || parseInt(this.props.location.query.id.match(/\d+/g)[0], 0),
      signedTradeId: props.signedTradeId || this.props.location.query.id,
      showDownloadButton: !(props.tradeId),
    };
  }

  tradeConfirmationGateway = serviceLocator.get('TradeConfirmationGateway');
  handleClick = (e) => {
    this.tradeConfirmationGateway.get(this.state.tradeId).then(content => {
      const blob = new Blob([content], { type: 'application/octet-stream' });
      if (blob.size === 0) {
        browserHistory.replace('not-found');
      } else {
        const url = window.URL || window.webkitURL;
        const downloadUrl = url.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = `trade-confirmation-${this.state.signedTradeId}.pdf`;
        anchor.click();
      }
    });
    e.preventDefault();
    return false;
  }

  render() {
    return !this.state.showDownloadButton
      ?
      (
      <Link
        to={`trade-confirmation?id=${this.state.signedTradeId}`}
        onClick={this.handleClick}
      >Download PDF</Link>)
      : (<div className="tab-content platform-tabs">
        <section className="tab-pane fade in active">
          <div className="clearfix">
            <div className="col-md-9 col-md-offset-2">
              <div className="form-group">
                <h2>Please click the button to download trade confirmation</h2>
              </div>
              <div className="form-group">
                <div className="col-md-4 col-md-offset-3">
                  <Link
                    to={`trade-confirmation?id=${this.state.signedTradeId}`}
                    onClick={this.handleClick}
                    className="btn btn-lg btn-block btn-primary btn-quote-submit"
                  >Download</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>);
  }

}

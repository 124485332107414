import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Form from 'react-formal';
import yup from 'yup';
import InfiniteLoad from './../../base/components/infinite-load';
import FormattedCurrency from './../../base/components/formatted-currency';
import FormGroup from './../../app/components/form-group';
import FormComponent from '../../base/components/form-component';
import serviceLocator from '../../base/service/service-locator';
import DatePicker, { schema as dateSchema } from './../../base/form/fields/date-picker.js';
import SelectEnquiryStatus from './select-enquiry-status';
import Loader from './../../app/components/loader';
import Config from 'Config';

export default class Enquiries extends FormComponent {

  utilsDate = serviceLocator.get('UtilsDate');

  constructor(props) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const monthAgo = new Date(today);
    monthAgo.setMonth(monthAgo.getMonth() - 1);

    const state = {
      limit: 20,
      page: 0,
      enquiryList: [],
      infiniteLoadingEnabled: true,
      isLoading: true,
      isDatePeriodValid: true,
      value: {
        dateFrom: monthAgo,
        dateTo: today,
        status: { value: '0', label: 'All' },
      },
    };
    const schema = {
      dateFrom: dateSchema.max(today, Config.validationMessages.dateInFuture),
      dateTo: dateSchema.max(today, Config.validationMessages.dateInFuture),
      enquiryId: yup.string(),
      status: yup.object(),
    };

    super(props, state, schema);

    this.enquiryGateway = serviceLocator.get('EnquiryGateway');
    this.utils = serviceLocator.get('Utils');
    this.utilsDate = serviceLocator.get('UtilsDate');

    this.filters = {
      dateFrom: this.utilsDate.getFormatedDate(monthAgo),
      dateTo: this.utilsDate.getFormatedDate(today),
      enquiryId: 0,
      status: '0',
    };
  }

  componentDidMount() {
    this.getEnquiryList();
  }

  formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    this._formOnChange(value, updatedPaths, onValueUpdateHandler);
    this.setState({ isDatePeriodValid: value.dateFrom <= value.dateTo });
  }

  formOnSubmit = (fields) => {
    this._formOnSubmit(fields);
    this.filters = {
      dateFrom: this.utilsDate.getFormatedDate(fields.dateFrom),
      dateTo: this.utilsDate.getFormatedDate(fields.dateTo),
      enquiryId: +fields.enquiryId || 0,
      status: fields.status,
    };
    this.setState({
      enquiryList: [],
      page: 0,
      infiniteLoadingEnabled: true,
    }, () => {
      this.getEnquiryList();
    });
  };

  getEnquiryList = () => {
    this.setState({ isLoading: true });

    this.enquiryGateway.get({
      per_page: this.state.limit,
      page: this.state.page,
      filters: this.filters,
    }).then(res => {
      const data = Object.assign({}, res);
      if (!this.utils.isComponentMounted(this)) {
        return;
      }
      if (!data || !data.enquiries_list || !data.enquiries_list.length) {
        this.setState({
          infiniteLoadingEnabled: false,
          isLoading: false,
        });
        return;
      }

      data.enquiries_list.forEach(value => {
        // eslint-disable-next-line no-param-reassign
        value.statusClass = 'text-info';

        if (value.status === 'Traded') {
          // eslint-disable-next-line no-param-reassign
          value.statusClass = 'text-success';
        }
        if (value.status.match(/Rejected\s\(.+\)/) !== null) {
          // eslint-disable-next-line no-param-reassign
          value.statusClass = 'text-danger';
        }
      });

      const nextState = {
        enquiryList: this.state.enquiryList.concat(data.enquiries_list),
        page: this.state.page + 1,
        isLoading: false,
      };

      if (data.enquiries_list.length < this.state.limit) {
        nextState.infiniteLoadingEnabled = false;
      }

      this.setState(nextState);
    }).catch(error => {
      if (this.logger.handlePromiseCatch(error)) {
        return;
      }
      this.setState({
        infiniteLoadingEnabled: false,
        isLoading: false,
      });
    });
  }

  renderTable() {
    return (
      <div className="table-responsive">
        <InfiniteLoad
          scrollHandler={this.getEnquiryList}
          isEnabled={this.state.infiniteLoadingEnabled}
          isLoading={this.state.isLoading}
          bottomOffset={Config.infiniteLoadBottomOffset}
        >
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Enquiry Id</th>
                <th>Your Reference</th>
                <th className="text-right">Buy Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.enquiryList.map((e, i) => (
                <tr key={i}>
                  <td>{this.utilsDate.getUKDateTime(e.date)}</td>
                  <td>{e.id}</td>
                  <td>{e.affiliate_ref}</td>
                  <td className="text-right">
                    <FormattedCurrency value={e.amount} currency={e.currency_buy} />
                  </td>
                  <td>
                    <span className={e.statusClass}>
                      {e.status}
                    </span>
                  </td>
                </tr>))
              }
            </tbody>
          </table>
        </InfiniteLoad>
      </div>);
  }

  render() {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active tab-enquiries" id="tab-enquiries">
          <div className="clearfix">
            <div className="col-md-8 col-md-offset-2">
              <h2>Enquiry List</h2>

              <Form
                ref="form"
                component="div"
                className="form form-enquires-filter"
                schema={this.schema}
                value={this.state.value}
                errors={this.state.formErrors}
                onError={this.formOnError}
                onChange={this.formOnChange}
                onSubmit={this.formOnSubmit}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <DatePicker
                      name="dateFrom"
                      label="From Date"
                      required="Please enter start date"
                      placeholder="DD/MM/YYYY"
                      className="form-control input-sm"
                      icons
                      max={new Date()}
                    />
                  </div>

                  <div className="col-sm-6">
                    <DatePicker
                      name="dateTo"
                      label="To Date"
                      required="Please enter end date"
                      placeholder="DD/MM/YYYY"
                      className="form-control input-sm"
                      icons
                      max={new Date()}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <FormGroup fieldName="enquiryId" icons>
                      <Form.Field
                        name="enquiryId"
                        placeholder="Enquiry id"
                        className="form-control input-sm"
                        id="enquiryId"
                      />
                    </FormGroup>
                  </div>

                  <div className="col-sm-6">
                    <SelectEnquiryStatus
                      icons
                      className="input-sm enquiry-status"
                      name="status"
                      label="Status"
                      placeholder="Status"
                      defaultValue="0"
                    />
                  </div>
                </div>

                <div className="form-group form-group-btn text-center">
                  <ReactCSSTransitionGroup
                    component="div"
                    transitionName="form-group-message"
                    transitionEnterTimeout={Config.transitionTimeout.opacity}
                    transitionLeaveTimeout={Config.transitionTimeout.opacity}
                  >
                    {
                      !this.state.isDatePeriodValid
                      ? <div className="form-group-message alert-danger">
                        {Config.validationMessages.dateOrder}
                      </div>
                      : null
                    }
                  </ReactCSSTransitionGroup>

                  <Form.Button
                    type="submit"
                    className="btn btn-sm btn-info search-enquiries"
                    disabled={this.state.isLoading || !this.state.isDatePeriodValid}
                  >Submit</Form.Button>
                </div>
              </Form>

              <ReactCSSTransitionGroup
                component="div"
                transitionName="table-responsive"
                transitionEnterTimeout={Config.transitionTimeout.opacity}
                transitionLeaveTimeout={Config.transitionTimeout.opacity}
              >
                {this.state.enquiryList.length > 0 ? this.renderTable() : null}
              </ReactCSSTransitionGroup>

              {
                (!this.state.enquiryList.length && !this.state.isLoading)
                ? <h6 className="text-center">No results found for your search criteria</h6>
                : null
              }

              {this.state.isLoading ? <Loader /> : null}
            </div>
          </div>
        </section>
      </div>);
  }
}

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Config from 'Config';

const SessionCountdown = ({ sessionEndTime, updateLastActiveTime }) => (
  <div className="timer">
    <div className="icon-clock"></div>

    <div className="time-left">
      Your session will expire in<br />
      <strong>
        <span className="time-wrap">
          <ReactCSSTransitionGroup
            transitionName="time-seconds"
            transitionEnterTimeout={Config.transitionTimeout.timerSeconds}
            transitionLeaveTimeout={Config.transitionTimeout.timerSeconds}
          >
            <span className="time-seconds" key={sessionEndTime}>
              {sessionEndTime}
            </span>
          </ReactCSSTransitionGroup>
        </span>
        <span>seconds</span>
      </strong>
    </div>

    <div className="time-extend">
      <span onClick={updateLastActiveTime}>Continue using the system</span>
    </div>
  </div>);
SessionCountdown.propTypes = {
  sessionEndTime: React.PropTypes.number.isRequired,
  updateLastActiveTime: React.PropTypes.func.isRequired,
};

export default SessionCountdown;

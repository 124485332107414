import CrudModel from '../base/models/crud-model';
import BlobModel from '../base/models/blob-model';
import serviceLocator from './../base/service/service-locator';

export default () => {
  serviceLocator.register('TradeGateway', CrudModel, ['trade/history'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('TradeGatewayHasOpenTrade', CrudModel, ['trade/has-open'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('TradeConfirmationGateway', BlobModel, ['trade/confirmation-note'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
};

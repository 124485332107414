import React from 'react';
import { FormattedNumber } from 'react-intl';

const FormattedCurrency = ({ value, currency }) => (
  <span>
    <FormattedNumber
      value={value}
      minimumFractionDigits={2}
      maximumFractionDigits={2}
    />{currency ? ` ${currency}` : null}
  </span>);

FormattedCurrency.propTypes = {
  value: React.PropTypes.oneOfType([
    React.PropTypes.number.isRequired,
    React.PropTypes.string.isRequired,
  ]),
  currency: React.PropTypes.string,
};

export default FormattedCurrency;

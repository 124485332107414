import React from 'react';
import CallUs from './../../base/components/call-us';

const RegistrationPending = () => (
  <div className="tab-content platform-tabs">
    <section className="tab-pane fade in active registration-wrap">
      <div className="clearfix">
        <div className="col-md-8 col-lg-8 col-lg-offset-2">
          <div className="pending-icon"></div>
          <h3 className="text-center">Thank you.</h3>
          <p className="text-center">
            <br />
            We are processing your registration and will be in contact with you shortly. <br />
          </p>
          {/* eslint-disable max-len */}
          <h4 className="text-center">
            <CallUs message="If you have any questions, please call us" newLine={false} />
          </h4>
          {/* eslint-enable max-len */}
        </div>
      </div>
    </section>
  </div>);

export default RegistrationPending;

import React from 'react';

export default class UpdatableComponent extends React.Component {

  componentDidUpdate() {
    if (this.onUpdateHandler) {
      this.onUpdateHandler(Object.assign({}, this.state));
    }
  }

  setOnUpdateHandler = (onUpdateHandler) => {
    if (onUpdateHandler && typeof onUpdateHandler === 'function') {
      this.onUpdateHandler = onUpdateHandler;
    }
  };

  disableOnUpdateHandler = () => {
    this.onUpdateHandler = null;
  };

}

export default class DataClientAdapterInterface {
  /**
   * Get model by ID
   */
  get() {
  }

  /**
   * Update model by ID
   */
  post() {
  }

  /**
   * Create model by ID
   */
  put() {
  }

  /**
   * Delete model by ID
   */
  del() {
  }

  /**
   * Send header
   */
  head() {
  }

  /**
   *
   */
  normalizeParams() {
  }

}

import React from 'react';
import serviceLocator from '../../base/service/service-locator';
import Loader from './../../app/components/loader';

export default class EmailVerification extends React.Component {

  static propTypes = {
    location: React.PropTypes.object,
  };

  state = {
    linkIsInvalid: false,
    isLoading: true,
    isSuccess: false,
    isError: false,
    errorText: null,
    successText: null,
  };

  componentWillMount() {
    this.queryParams = this.props.location.query;
    if (!this.queryParams.hash || !this.queryParams.email) {
      this.setState({
        linkIsInvalid: true,
        isLoading: false,
      });
    } else {
      this.confirmEmail(this.queryParams.hash, this.queryParams.email);
    }
  }

  queryParams = {};
  emailConfirmationGateway = serviceLocator.get('EmailConfirmationGateway');
  logger = serviceLocator.get('Logger');

  confirmEmail = (token, email) => {
    this.emailConfirmationGateway
      .post({
        token,
        email,
      })
      .then(response => {
        this.setState({
          isLoading: false,
          isSuccess: true,
          successText: response.message,
        });
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.setState({
          isLoading: false,
          isError: true,
          errorText: error[0].message,
        });
      });
  };

  renderSuccessMessage() {
    return (
      <div>
        <h2>Thank you for choosing Currency Solutions.</h2>
        {
          this.state.successText
          ? <p>{this.state.successText}</p>
          : <p>Your email successfully confirmed!</p>
        }
        <p>Please, <a href="/">Log in</a></p>
      </div>);
  }

  renderInvalidMessage() {
    return (
      <div className="error-block">
        <h2>Error</h2>
        <p>Sorry, your confirmation link is invalid</p>
      </div>);
  }

  renderErrorMessage() {
    return (
      <div className="error-block">
        <h2>Error</h2>
        {
          this.state.errorText
          ? <p>{this.state.errorText}</p>
          : <p>Sorry, something goes wrong</p>
        }
      </div>);
  }

  render() {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active">
          <div className="clearfix">
            <div className="col-sm-10 col-sm-offset-1">

              {this.state.isLoading ? <Loader /> : null}

              {this.state.isSuccess ? this.renderSuccessMessage() : null}

              {this.state.linkIsInvalid ? this.renderInvalidMessage() : null}

              {this.state.isError ? this.renderErrorMessage() : null}

            </div>
          </div>
        </section>
      </div>);
  }
}

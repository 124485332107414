import StackTrace from 'stacktrace-js';
import DataModel from './../../base/models/data-model';
import ServerError from './../../base/error/server-error';
import Config from 'Config';

const LOGGER_TASK_TIMEOUT = 60; // sec

export default class Logger extends DataModel {

  constructor(route, dataClient = null) {
    super(route, dataClient);
    this.logsQueue = [];
    this.taskTimer = null;

    window.onerror = (msg, file, line, col, error) => {
      this.report(error);
      return Config.env === 'production';
    };

    this.reportedErrors = {};
    this.reportedRefs = {};
  }

  setApp(app) {
    this.app = app;
  }

  /**
   * @param {Object} error
   */
  report(error) {
    if (error.stack && error.stack.indexOf('orldpay') !== -1) {
      return;
    }

    if (error instanceof ServerError && this.app) {
      let message = error.getMessage();
      if (error.getReference()) {
        // eslint-disable-next-line max-len
        message = `${message}<br>Please use this reference number to report about it: <b>${error.getReference()}</b>`;
      }
      this.showErrorModal('Server Error', message, true);
      return;
    }

    const msg = error && error.message || error.toString && error.toString() || false;
    if (!msg || this.reportedErrors[msg]) {
      return;
    }
    this.reportedErrors[msg] = true;

    StackTrace.fromError(error)
      .then((stackframes) => {
        const ref = this.errorHandler(stackframes, error);
        this.showErrorModal(
          'Client Error',
          // eslint-disable-next-line max-len
          `An error occurred, please try again later.<br>Please use this reference number to report about it: <b>${ref}</b>`
        );
      })
      .catch(() => {});
  }

  handlePromiseCatch(failResponse) {
    if (failResponse instanceof Error ||
      failResponse instanceof TypeError ||
      failResponse instanceof ServerError
    ) {
      this.report(failResponse);
      return true;
    }
    return false;
  }

  showErrorModal(title, message, reload = false) {
    this.app.showErrorModal(title, message, reload);
  }

  /**
   * @param {Array} stackframes
   * @param {Object} error
   */
  errorHandler(stackframes, error) {
    let ref = '';
    const trace = stackframes.map((sf) => {
      if (!ref) {
        ref = '3';
        ref += `00000${sf.fileName.length + sf.columnNumber + sf.lineNumber}`.substr(-5, 5);
      }
      return `${sf.functionName}() in ${sf.fileName}:${sf.lineNumber}:${sf.columnNumber}`;
    }).join('\n');

    if (this.reportedRefs[ref]) {
      return ref;
    }

    this.reportedRefs[ref] = true;

    this.pushLogToQueue({
      message: `${error.toString()}\nRef: ${ref}\n${trace}`,
      level: 'error',
    });
    this.startLoggerTask();

    return ref;
  }

  startLoggerTask() {
    if (this.taskTimer) {
      clearTimeout(this.taskTimer);
    }

    while (this.logsQueue.length) {
      const log = this.popLogToQueue();
      this.post(log).then(data => {
        if (data.status !== 'ok') {
          throw new Error();
        }
      }).catch(() => {
        this.pushLogToQueue(log);
      });
    }

    this.taskTimer = setTimeout(() => { this.startLoggerTask(); }, LOGGER_TASK_TIMEOUT * 1000);
  }

  /**
   * @param {Object} log
   */
  pushLogToQueue(log) {
    this.logsQueue.unshift(log);
  }

  /**
   * @returns {(Object|undefined)}
   */
  popLogToQueue() {
    return this.logsQueue.pop();
  }

}

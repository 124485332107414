import React from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-formal';
import zxcvbn from 'zxcvbn';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import FormTextField from './../../base/components/form-text-field';
import PasswordStrengthMeter from './../../base/components/password-strength-meter';
import Loader from './../../app/components/loader';
import PhoneVerifyGroup, { schema as PhoneVerifyGroupSchema }
  from './../../phone-verify/components/phone-verify-form-group';

/**
 * Reset password step 2: create new password
 */
export default class ResetPasswordStep2 extends FormComponent {

  utils = serviceLocator.get('Utils');

  constructor(props) {
    const state = {
      error: false,
      success: false,
      isLoading: true,
      passwordScore: null,
      showSmsConfirmation: true,
      phoneCode: null,
      phoneNumber: null,
    };
    const { hash, id } = props.location.query;

    if (!hash || !id) {
      state.error = 'Invalid reset password link.';
      state.isLoading = false;
    }

    const schema = {
      password: yup.string().required('Please enter your new password').min(6)
        .test('Password strength', 'Provided password is not secure', value => {
          const passwordScore = zxcvbn(value).score;
          this.setState({ passwordScore });
          return passwordScore >= 1;
        }),
      repeatPassword: yup.string().min(6)
        .required('Please confirm your new password')
        .test('passwordEquals', 'Passwords don\'t match', function passwordEqualsTest(value) {
          return value === this.parent.password;
        }),
      ...PhoneVerifyGroupSchema,
    };

    super(props, state, schema);
  }

  componentDidMount() {
    const { hash, id } = this.props.location.query;
    if (hash && id) {
      this.checkToken();
    }
    this.utils.setBlurEffect();
  }

  /**
   * Check if token to reset password is still valid
   */
  checkToken = () => {
    const checkTokenGateway = serviceLocator.get('CheckTokenGateway');
    checkTokenGateway
      .post({
        token: this.props.location.query.hash,
        userId: this.props.location.query.id,
      })
      .then((data) => {
        this.setState({
          isLoading: false,
          phoneCode: data.phoneCode,
          phoneNumber: data.phoneNumber,
        });
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.setState({
          isLoading: false,
          error: error[0].message,
        });
      });
  };

  formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    this._formOnChange(value, updatedPaths, () => {
      if (onValueUpdateHandler) {
        onValueUpdateHandler();
      }
      if (updatedPaths.indexOf('password') !== -1) {
        this.validateFields(['password']);
      }
      if (updatedPaths.indexOf('password') !== -1 && this.state.value.repeatPassword) {
        this.validateFields(['repeatPassword']);
      }
    });
  }

  /**
   * Reset password
   *
   * @param fields
   */
  formOnSubmit = (fields) => {
    this._formOnSubmit(fields);
    const resetPasswordStep2Gateway = serviceLocator.get('ResetPasswordStep2Gateway');
    resetPasswordStep2Gateway
      .post({
        token: this.props.location.query.hash,
        userId: this.props.location.query.id,
        newPassword: fields.password,
        newPasswordRepeat: fields.repeatPassword,
      })
      .then(() => {
        this.setState({
          success: true,
        });
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this._onFailResponse(error);
      });
  };

  onMobileConfirmationDone = () => {
    this.setState({ showSmsConfirmation: false });
  };

  renderSmsConfirmation() {
    return this.state.showSmsConfirmation
      ? <FormGroup
        formGroupClassName="input-col form-group-mobile"
        fieldName="mobile"
        icons
      >
        <PhoneVerifyGroup
          verifyUser={true}
          phoneCode={this.state.phoneCode}
          phoneNumber={this.state.phoneNumber}
          userId={this.props.location.query.id}
          setUserVerificationDone={this.onMobileConfirmationDone}
          verifyLabel="Confirm"
          verifiedLabel="Confirmed"
          modalTitle="Please confirm mobile number"
        />
      </FormGroup>
      : null;
  }

  renderBody() {
    if (!this.state.success && !this.state.error && !this.state.isLoading) {
      return (
        <div>
          <FormGroup
            fieldName="password"
            context={this.getChildContext()}
            icons
            formGroupClassName="form-group-sm"
          >
            <label htmlFor="password" className="sr-only">Password</label>
            <FormTextField
              type="password"
              name="password"
              placeholder="New password"
              className="form-control input-sm"
              id="password"
            />
            <PasswordStrengthMeter passwordScore={this.state.passwordScore} />
          </FormGroup>
          <FormGroup
            fieldName="repeatPassword"
            context={this.getChildContext()}
            icons
            formGroupClassName="form-group-sm"
          >
            <label htmlFor="repeatPassword" className="sr-only">Confirm password</label>
            <FormTextField
              type="password"
              name="repeatPassword"
              placeholder="Confirm new password"
              className="form-control input-sm"
              id="repeatPassword"
            />
          </FormGroup>
          {this.renderSmsConfirmation()}
        </div>);
    } else if (this.state.isLoading) {
      return <Loader />;
    } else if (this.state.error) {
      return (
        <div>
          <p className="text-center">{this.state.error}</p>
          <p className="text-center"><a href="/forgot-password">Back to forgot password</a></p>
        </div>);
    }
    return (
      <p>
        {/* eslint-disable max-len */}
        <span className="success-message">Your new password was successfully saved.</span> Please, <a href="/">Log in</a>.
        {/* eslint-enable max-len */}
      </p>);
  }

  renderFooter() {
    return (!this.state.success && !this.state.error && !this.state.isLoading)
      ? <div className="text-center">
        <FormGroup formGroupClassName="form-group-btn-submit">
          <Form.Button
            type="submit"
            disabled={this.state.isloading}
            className="btn btn-primary btn-lg btn-block btn-reset-password"
          >Set password</Form.Button>
        </FormGroup>
      </div>
      : null;
  }

  render() {
    return (
      <Form
        ref="form"
        className="col-sm-4 form-horizontal"
        schema={this.schema}
        value={this.state.value}
        errors={this.state.formErrors}
        onError={this.formOnError}
        onChange={this.formOnChange}
        onSubmit={this.formOnSubmit}
      >
        <Modal
          className="modal-reset-password"
          show
          animation={false}
          bsSize="small"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <div className="navbar-brand">CURRENCY SOLUTIONS</div>
            <Modal.Title>Create new password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderBody()}
          </Modal.Body>
          <Modal.Footer>
            {this.renderFooter()}
          </Modal.Footer>
        </Modal>
      </Form>);
  }
}

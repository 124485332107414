import DataModel from './../../base/models/data-model';

export default class PhoneVerificationModel extends DataModel {

  /**
   * @param routeVerifyRequest
   * @param routeVerifyRequestResend
   * @param routeNewVerifyRequest
   * @param routeForceVerified
   * @param routeGetVerifiedPhones
   */
  constructor(
    routeVerifyRequest,
    routeVerifyRequestResend,
    routeNewVerifyRequest,
    routeForceVerified,
    routeGetVerifiedPhones
  ) {
    super(routeNewVerifyRequest);

    this.routeNewVerifyRequest = routeNewVerifyRequest;
    this.routeVerifyRequestResend = routeVerifyRequestResend;
    this.routeVerifyRequest = routeVerifyRequest;
    this.routeForceVerified = routeForceVerified;
    this.routeGetVerifiedPhones = routeGetVerifiedPhones;
  }

  /**
   * @param code
   */
  verifyCode(code = null) {
    if (code) {
      this.setCode(code);
    }

    this.setRoute(this.routeVerifyRequest);
    return this.post({ code: this.getCode(), reference: this.getReference() });
  }

  /**
   * @returns {axios.Promise}
   */
  verifyRequest(route) {
    if (!this.getPhoneNumber()) {
      return Promise.reject([{ message: 'A valid phone number must be set' }]);
    }

    if (!route) {
      // eslint-disable-next-line no-param-reassign
      route = this.routeNewVerifyRequest;
    }

    this.setRoute(route);
    this.verificationRequestSent = true;

    const postdata = {
      mobile: this.getPhoneNumber(),
      phoneCode: this.getPhoneCode(),
    };
    if (this.getUserId()) {
      postdata.userId = this.getUserId();
    }
    if (this.getReference()) {
      postdata.reference = this.getReference();
    }

    return this.post(postdata)
      .then((data) => {
        if (data.reference) {
          this.setReference(data.reference);
        }
        this.verificationRequestSent = false;

        return data;
      })
      .catch((error) => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.verificationRequestSent = false;

        throw error;
      });
  }

  verifyRequestResend() {
    return this.verifyRequest(this.routeVerifyRequestResend);
  }

  setForceVerified() {
    this.setRoute(this.routeForceVerified);
    return this.post({ reference: this.getReference() });
  }

  getVerifiedPhones() {
    this.setRoute(this.routeGetVerifiedPhones);
    return this.post({});
  }

  /**
   * @param code
   * @returns {PhoneVerificationModel}
   */
  setCode(code) {
    this.code = code;
    return this;
  }

  /**
   * @returns {*}
   */
  getCode() {
    return this.code;
  }

  /**
   * @param phone
   * @returns {PhoneVerificationModel}
   */
  setPhoneNumber(phone) {
    this.phoneNumber = phone;
    return this;
  }

  /**
   * @returns {*}
   */
  getPhoneNumber() {
    if (!this.phoneNumber) {
      return '';
    }
    return this.phoneNumber.toString().replace(/^(0|\+)+/, '');
  }

  /**
   * @param phoneCode
   * @returns {PhoneVerificationModel}
   */
  setPhoneCode(phoneCode) {
    this.phoneCode = phoneCode;
    return this;
  }

  /**
   * @returns {*}
   */
  getPhoneCode() {
    return this.phoneCode;
  }

  /**
   * @param userId
   * @returns {PhoneVerificationModel}
   */
  setUserId(userId) {
    this.userId = userId;
    return this;
  }

  /**
   * @returns {*}
   */
  getUserId() {
    return this.userId;
  }

  /**
   * @param ref
   * @returns {PhoneVerificationModel}
   */
  setReference(ref) {
    this.reference = ref;
    return this;
  }

  /**
   * @returns string
   */
  getReference() {
    return this.reference;
  }
}

import React from 'react';
import serviceLocator from '../../base/service/service-locator';

export default class Tooltip extends React.Component {
  static propTypes = {
    text: React.PropTypes.string.isRequired,
  }

  constructor() {
    super();

    this.utils = serviceLocator.get('Utils');

    this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
  }

  mouseEnterHandler() {
    const helpTooltipWrap = this.refs.helpTooltipWrap;
    const container = this.utils.getElementContainer(helpTooltipWrap);
    const containerWidth = container.offsetWidth;
    const containerStyles = window.getComputedStyle(container);
    const windowWidth = window.innerWidth;
    const helpTooltipWrapPositionBody =
      this.utils.getElementPosition(helpTooltipWrap, document.body);
    const helpTooltipWrapPositionContainer =
      this.utils.getElementPosition(helpTooltipWrap, container);
    const helpTooltipWrapStyles = window.getComputedStyle(helpTooltipWrap);
    const helpTooltip = this.refs.helpTooltip;
    const helpTooltipWidth = helpTooltip.offsetWidth + 38;

    helpTooltip.style.top = `${helpTooltipWrapPositionContainer.y - 6}px`;

    if (windowWidth - helpTooltipWrapPositionBody.x > helpTooltipWidth) {
      helpTooltipWrap.classList.add('left');

      helpTooltip.style.right = 'auto';
      // eslint-disable-next-line prefer-template
      helpTooltip.style.left = (helpTooltipWrapPositionContainer.x
        + parseInt(helpTooltipWrapStyles.marginLeft, 10)
        - 5) + 'px';
    } else {
      helpTooltipWrap.classList.add('right');

      helpTooltip.style.left = 'auto';
      // eslint-disable-next-line prefer-template
      helpTooltip.style.right = (containerWidth
        - helpTooltipWrapPositionContainer.x
        - helpTooltipWrap.offsetWidth
        - parseInt(helpTooltipWrapStyles.marginLeft, 10)
        - parseInt(containerStyles.paddingLeft, 10)
        - 5) + 'px';
    }
  }

  mouseLeaveHandler() {
    this.refs.helpTooltipWrap.classList.remove('left');
    this.refs.helpTooltipWrap.classList.remove('right');
  }

  render() {
    return (
      <span
        className="help-tooltip-wrap"
        ref="helpTooltipWrap"
        onMouseEnter={this.mouseEnterHandler}
        onMouseLeave={this.mouseLeaveHandler}
      >
        <span
          className="help-tooltip"
          ref="helpTooltip"
          dangerouslySetInnerHTML={{ __html: this.props.text }}
        />
        <span className="icon-tooltip">
          <span className="fa fa-question"></span>
        </span>
      </span>);
  }
}

import React from 'react';
import FormMessage from './../components/form-message.js';

const FormGroup = (props, context) => {
  const c = props.context || context;
  const hasError = (c.formErrors && c.formErrors[props.fieldName]);
  const hasSuccess = (c.formSuccess && c.formSuccess[props.fieldName]);
  let classNames = '';
  let icon = null;

  if (props.icons) {
    if (hasError) {
      icon = (<span
        className="fa form-control-feedback fa-times"
        aria-hidden="true"
      />);
    }
    if (hasSuccess) {
      icon = (<span
        className="fa form-control-feedback fa-check"
        aria-hidden="true"
      />);
    }
  }

  if (props.hidden) {
    classNames += ' hidden';
  }
  if (hasError) {
    classNames += ' has-error';
  }
  if (hasSuccess) {
    classNames += ' has-success';
  }
  if ((hasError || hasSuccess) && props.icons) {
    classNames += ' has-feedback';
  }

  return (
    <div className={`form-group ${props.formGroupClassName || ''} ${classNames}`}>
      {props.children}{icon}
      <FormMessage for={props.fieldName} context={c} />
    </div>
  );
};

FormGroup.contextTypes = {
  formErrors: React.PropTypes.object,
  formSuccess: React.PropTypes.object,
};
FormGroup.propTypes = {
  fieldName: React.PropTypes.string,
  formGroupClassName: React.PropTypes.string,
  icons: React.PropTypes.bool,
  context: React.PropTypes.object,
  children: React.PropTypes.node,
  hidden: React.PropTypes.bool,
};

export default FormGroup;

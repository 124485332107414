import React from 'react';
import yup from 'yup';
import serviceLocator from '../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import { transferReasonOther } from '../payment';

export const newBeneficiary = { value: '-1', label: 'Add New Beneficiary' };

export default class SendOutAbstract extends FormComponent {

  sendOutGateway = serviceLocator.get('SendOutGateway');
  beneficiaryGateway = serviceLocator.get('BeneficiaryGateway');
  countryGateway = serviceLocator.get('CountryGateway');
  reasonsForTransferGateway = serviceLocator.get('ReasonsForTransferGateway');
  getTransferGateway = serviceLocator.get('GetTransferGateway');
  ga = serviceLocator.get('GA');
  beneficiaries = {};
  beneficiaryListByCurrency = {};
  currency = null;
  lastEditableTransfers = null;

  static contextTypes = {
    showErrorModal: React.PropTypes.func,
  };

  constructor(props, state = {}, schema = {}) {
    const stateAddon = Object.assign({}, state, {
      beneficiaryList: [
        newBeneficiary,
      ],
      beneficiaryCurrency: null,
      error: null,
      showOtherReason: false,
    });
    super(props, stateAddon, schema);
    this.newBeneficiary = newBeneficiary;
    if (props.currency) {
      this.currency = {
        value: props.currency,
        label: props.currency,
      };
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    Promise.all([
      this.beneficiaryGateway.get({
        per_page: 150,
        page: 1,
      }),
      this.countryGateway.getList(),
    ]).then(response => {
      const beneficiariesResponse = response[0];

      if (beneficiariesResponse.count) {
        const beneficiaryListByCurrency = beneficiariesResponse.items.reduce((b, beneficiary) => {
          if (!b[beneficiary.currency]) {
            // eslint-disable-next-line no-param-reassign
            b[beneficiary.currency] = [];
          }
          // eslint-disable-next-line no-param-reassign
          b[beneficiary.currency].push({
            value: beneficiary.id,
            label: beneficiary.alias,
          });
          return b;
        }, {});

        const beneficiaries = beneficiariesResponse.items.reduce((b, beneficiary) => {
          const formattedBeneficiary = Object.assign({}, beneficiary);
          Object.keys(formattedBeneficiary).forEach(key => {
            if (!formattedBeneficiary[key]) {
              delete formattedBeneficiary[key];
            }
            if (
              (key === 'countryId' || key === 'destinationCountryId') &&
              formattedBeneficiary[key]
            ) {
              const countryCode = formattedBeneficiary[key];
              // eslint-disable-next-line no-param-reassign
              formattedBeneficiary[key] = {
                value: countryCode,
                label: this.countryGateway.getNameByCountryShortCode(countryCode),
              };
            }
          });

          // eslint-disable-next-line no-param-reassign
          b[formattedBeneficiary.id] = formattedBeneficiary;
          return b;
        }, {});

        this.beneficiaries = beneficiaries;
        this.beneficiaryListByCurrency = beneficiaryListByCurrency;

        if (this.state.value.beneficiaryId && this.state.value.beneficiaryId.value !== '-1') {
          this.formOnChange(Object.assign({}, this.state.value), ['beneficiaryId']);
        }

        this.onChangeCurrency();
      }
    });
  }

  loadById(id, availableStatuses, onLoadFinished = null) {
    Promise.all([
      this.getTransferGateway.get({ id }),
      this.reasonsForTransferGateway.getList(),
    ]).then(response => {
      const editablePi = response[0];
      if (!editablePi.id ||
        !editablePi.currency ||
        availableStatuses.indexOf(editablePi.status) === -1
      ) {
        this.setState({
          isLoading: false,
          error: 'Transfer cannot be edited',
        }, () => {
          if (onLoadFinished) {
            onLoadFinished();
          }
        });
        return;
      }

      this.lastEditableTransfers = { [editablePi.currency]: editablePi };
      const currencies = [{
        value: editablePi.currency,
        label: editablePi.currency,
      }];
      const newCurrencyValidation = yup.reach(this.schema, 'currency').default(currencies[0]);
      this.addValidationRules({ currency: newCurrencyValidation });
      let newState = { isLoading: false };
      if (this.state.currencyList) {
        const transferAmount = editablePi.trade ? editablePi.trade.availableAmount : 0;
        const piAmount = parseFloat(editablePi.amount) ? editablePi.amount : transferAmount;
        const balances = { [editablePi.currency]: parseFloat(piAmount) };
        newState = Object.assign({}, newState, {
          currencyList: currencies,
          balancesList: balances,
        });
      }
      this.setState(newState, () => {
        this.onChangeCurrency();
        if (onLoadFinished) {
          onLoadFinished();
        }
      });
    }).catch(error => {
      if (this.logger.handlePromiseCatch(error)) {
        return;
      }
      this.setState({ error }, () => {
        if (onLoadFinished) {
          onLoadFinished();
        }
      });
    });
  }

  getEditedPi(defaultAmount = null) {
    if (!this.lastEditableTransfers || !this.currency || !this.currency.value) {
      return {};
    }
    let disableAmountField = true;
    let setMaxAmount = true;
    const editedPiValue = {};
    if (this.lastEditableTransfers[this.currency.value]) {
      const editablePi = this.lastEditableTransfers[this.currency.value];

      editedPiValue.id = parseInt(editablePi.id, 10);

      const piAmount = parseFloat(editablePi.amount);
      if (piAmount > 0) {
        editedPiValue.amount = this.utils.formatAmount(piAmount).formattedValue;
      } else if (editablePi.trade) {
        const availableAmount = parseFloat(editablePi.trade.availableAmount);
        editedPiValue.amount = availableAmount ?
          this.utils.formatAmount(availableAmount).formattedValue : null;
        disableAmountField = false;
        setMaxAmount = false;
      } else {
        editedPiValue.amount = defaultAmount;
        disableAmountField = false;
      }

      const beneficiaryId = parseInt(editablePi.beneficiaryId, 10);
      if (beneficiaryId > 0 &&
        this.beneficiaries[beneficiaryId]
      ) {
        editedPiValue.beneficiaryId = {
          value: beneficiaryId,
          label: this.beneficiaries[beneficiaryId].alias,
        };
      } else {
        editedPiValue.beneficiaryId = this.newBeneficiary;
      }

      editedPiValue.reference1 = editablePi.reference1;
      if (editablePi.reason) {
        editedPiValue.reason = {
          value: editablePi.reason,
          label: this.reasonsForTransferGateway.getValue(editablePi.reason),
        };
      } else {
        editedPiValue.reason = null;
      }
      editedPiValue.otherReason = editablePi.otherReason;
    } else {
      editedPiValue.id = 0;
      editedPiValue.amount = defaultAmount;
      editedPiValue.beneficiaryId = this.newBeneficiary;
      editedPiValue.reference1 = '';
      editedPiValue.reason = null;
      editedPiValue.otherReason = '';
    }

    return {
      value: editedPiValue,
      disableAmountField,
      setMaxAmount,
    };
  }

  _onChangeCurrency = () => {
    if (this.currency &&
      this.currency.value &&
      this.beneficiaryListByCurrency &&
      this.beneficiaryListByCurrency[this.currency.value]
    ) {
      const beneficiaryList = this.beneficiaryListByCurrency[this.currency.value];
      this.setState({
        beneficiaryList: [this.newBeneficiary, ...beneficiaryList],
        beneficiaryCurrency: this.currency.value,
      });
    } else {
      this.setState({
        beneficiaryList: [this.newBeneficiary],
        beneficiaryCurrency: null,
      });
    }
  };
  onChangeCurrency = this._onChangeCurrency;

  formOnSubmitAbstract = (fields, onSuccessHandler, onFailHandler) => {
    const formData = Object.assign({}, fields);
    this._formOnSubmit(formData);

    this.ga.logEvent('Payment', 'Submit');

    this.sendOutGateway.post(formData)
      .then(successResponse => {
        onSuccessHandler(successResponse);
      })
      .catch(failResponse => {
        Object.keys(failResponse).map(key => {
          if (failResponse[key].code === 'TRANSFER_UNEXPECTED_STATUS' &&
            this.context.showErrorModal
          ) {
            this.context.showErrorModal(
              'Transfer status changed',
              failResponse[key].message,
              true
            );
          }

          if (failResponse[key].code === 'FINANCE_INSUFFICIENT_BALANCE' &&
            this.context.showErrorModal
          ) {
            this.context.showErrorModal(
              'Insufficient balance',
              failResponse[key].message,
              true
            );
          }

          return key;
        });
        this._onFailResponse(failResponse);
        if (onFailHandler) {
          onFailHandler(failResponse);
        }
      });
  };

  formOnChangeAbstract = (value, updatedPaths, onValueUpdateHandler = null) => {
    let afterValueUpdateHandler = () => {
      if (onValueUpdateHandler) {
        onValueUpdateHandler();
      }
    };

    // If we change currency
    if (updatedPaths.indexOf('currency') !== -1) {
      this.currency = value.currency;
      afterValueUpdateHandler = () => {
        if (onValueUpdateHandler) {
          onValueUpdateHandler();
        }
        this.onChangeCurrency();
      };
    }

    // If we select beneficiary
    if (updatedPaths.indexOf('beneficiaryId') !== -1) {
      const updatedFileds = [
        'name',
        'reference1',
        'accountNumber',
        'address1',
        'address2',
        'bankIdentifier',
        'bsbCode',
        'countryId',
        'destinationCountryId',
        'ifscCode',
        'intermediaryBank',
        'thirdParty',
        'transitCode',
      ];
      const disabledFields = {};
      const beneficiaryData = {};
      if (this.state.disabledFields.amount) {
        disabledFields.amount = true;
      }

      const beneficiaryId = parseInt(value.beneficiaryId.value, 10);
      if (beneficiaryId > 0 &&
        typeof this.beneficiaries[beneficiaryId] !== 'undefined'
      ) {
        const beneficiary = this.beneficiaries[beneficiaryId];
        updatedFileds.forEach(field => {
          // eslint-disable-next-line no-param-reassign
          beneficiaryData[field] = beneficiary[field];
          disabledFields[field] = true;
        });
      } else {
        updatedFileds.forEach(field => {
          // eslint-disable-next-line no-param-reassign
          beneficiaryData[field] = this.schema.default()[field];
        });
      }

      afterValueUpdateHandler = () => {
        if (onValueUpdateHandler) {
          onValueUpdateHandler();
        }
        this.setFieldsValues(beneficiaryData);
        this.setState({ disabledFields });
      };
    }

    // If we choose reason "Other"
    if (value.reason && updatedPaths.indexOf('reason') !== -1) {
      this.setState({ showOtherReason: (value.reason.value === transferReasonOther) });
    }

    this._formOnChange(value, updatedPaths, afterValueUpdateHandler);
  };
}

import React from 'react';
import Form from 'react-formal';
import Select from 'react-select';
import yup from 'yup';
import serviceLocator from '../../base/service/service-locator';
import FormGroup from './../../app/components/form-group';
import FormTextField from './../../base/components/form-text-field';
import SendOutAbstract, { newBeneficiary } from '../../payment/components/send-out-abstract';
import { schema as sendOutSchema } from './../../payment/components/send-out-fieldset';
import { transferReasonOther } from '../payment';

export default class SendOutSmall extends SendOutAbstract {
  reasonsForTransferGateway = serviceLocator.get('ReasonsForTransferGateway');
  utils = serviceLocator.get('Utils');

  static propTypes = {
    currency: React.PropTypes.string.isRequired,
    onValuesChange: React.PropTypes.func.isRequired,
    validateForm: React.PropTypes.bool.isRequired,
    onValidationDone: React.PropTypes.func.isRequired,
  };

  constructor(props) {
    const state = {
      reasonsList: [],
    };
    const smallSendoutNewBeneficiary = {
      ...newBeneficiary,
      label: 'Add a new beneficiary or specify later',
    };
    const schema = {
      beneficiaryId: yup.object().default(smallSendoutNewBeneficiary),
      ...sendOutSchema,
    };

    super(props, state, schema);
    this.newBeneficiary = smallSendoutNewBeneficiary;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currency && nextProps.currency !== this.props.currency) {
      this.currency = {
        value: nextProps.currency,
        label: nextProps.currency,
      };
      this.onChangeCurrency();
    }
    if (nextProps.validateForm && !this.props.validateForm) {
      if (this.state.value.beneficiaryId &&
        this.state.value.beneficiaryId.value !== newBeneficiary.value
      ) {
        this.validateFields(Object.keys(sendOutSchema), () => {
          if (this.allFieldsSetAndValidated) {
            this.props.onValidationDone();
          }
        });
      } else {
        this.props.onValidationDone();
      }
    }
  }

  loadData() {
    super.loadData();
    this.reasonsForTransferGateway.getList(reasonsList => {
      if (this.utils.isComponentMounted(this)) {
        this.setState({
          reasonsList,
        });
      }
    });
  }

  formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    // If we choose reason "Other"
    if (value.reason && updatedPaths.indexOf('reason') !== -1) {
      this.setState({ showOtherReason: (value.reason.value === transferReasonOther) });
    }

    this._formOnChange(value, updatedPaths, () => {
      if (onValueUpdateHandler) {
        onValueUpdateHandler();
      }
      this.props.onValuesChange(Object.assign({}, this.state.value));
    });
  };

  renderOtherReasonRow() {
    return this.state.showOtherReason
      ? <div className="form-group row">
        <FormGroup
          formGroupClassName="input-col"
          fieldName="otherReason"
          icons
        >
          <FormTextField
            name="otherReason"
            placeholder="Please specify other reason"
            type="text"
            className="form-control input-sm"
            id="beneficiaryReasonOther"
          />
        </FormGroup>
      </div>
      : null;
  }

  render() {
    return (
      <div className="clearfix">
        <Form
          component="div"
          ref="form"
          schema={this.schema}
          value={this.state.value}
          errors={this.state.formErrors}
          onError={this.formOnError}
          onChange={this.formOnChange}
          onSubmit={this.formOnSubmit}
          className="form clearfix"
          id="send-out-small-form"
        >
          <div className="col-sm-12">
            <div className="make-transfer-small-sendout-wrap">
              <div className="form-group row upper-text">Select your recipient</div>

              <div className="beneficiary-selector form-group row">
                <FormGroup formGroupClassName="form-group-select input-col form-group-sm">
                  <Form.Field
                    type={Select}
                    id="beneficiarySelect"
                    name="beneficiaryId"
                    defaulValue={0}
                    className="input-sm"
                    placeholder="Add a new beneficiary or specify later"
                    options={this.state.beneficiaryList}
                    clearable={false}
                    searchable={false}
                  />
                </FormGroup>
              </div>

              {
                this.state.value.beneficiaryId && this.state.value.beneficiaryId.value !== '-1'
                  ? <div className="form-group send-out-fieldset">
                    <div className="form-group row">
                      <FormGroup
                        formGroupClassName="input-col form-group-sm"
                        fieldName="reference1"
                        icons
                      >
                        <FormTextField
                          name="reference1"
                          placeholder="Reference"
                          type="text"
                          className="form-control input-sm"
                          id="sendOutReference"
                        />
                      </FormGroup>
                    </div>
                    <div className="form-group row">
                      <FormGroup
                        formGroupClassName="form-group-select input-col form-group-sm"
                        fieldName="reason"
                        icons
                      >
                        <Form.Field
                          type={Select}
                          name="reason"
                          id="beneficiaryReason"
                          className="input-sm"
                          placeholder="Specify reason"
                          options={this.state.reasonsList}
                          clearable={false}
                          searchable={false}
                        />
                      </FormGroup>
                    </div>
                    {this.renderOtherReasonRow()}
                  </div>
                  : null
              }
            </div>

          </div>
        </Form>
      </div>);
  }
}

import React from 'react';

const DEFAULT_MESSAGE = 'Call us on';

const CallUs = (props) => {
  const { message, newLine, showLink, left } = {
    message: DEFAULT_MESSAGE,
    newLine: true,
    showLink: true,
    left: false,
    ...props,
  };
  return (<span className={left ? 'left call-us-block' : 'call-us-block'}>
    {message}
    {newLine ? <br /> : null}
    {showLink
      ? <a href="tel:+442077400000"> +44(0) 20 7740 0000</a>
      : <strong> +44(0) 20 7740 0000</strong>
    }
  </span>);
};

CallUs.propTypes = {
  message: React.PropTypes.string,
  newLine: React.PropTypes.bool,
  showLink: React.PropTypes.bool,
  left: React.PropTypes.bool,
};

export default CallUs;

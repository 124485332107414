import DataModel from './../../base/models/data-model';

export default class CountryModel extends DataModel {

  countries = { GB: 'United Kingdom' };
  codes = { GB: 44 };
  nationalities = { GB: 'British (United Kingdom)' };
  parsedCountries = null;
  getCountriesPromise = null;

  /**
   * Returns country list as array: countryCode => countryName
   */
  getList(callback) {
    if (this.parsedCountries) {
      if (typeof callback === 'function') {
        return callback(this.parsedCountries);
      }
      return this.parsedCountries;
    }

    if (!this.getCountriesPromise) {
      this.getCountriesPromise = this.get({
        withCodes: 1,
        withNationality: 1,
      });
    }

    return this.getCountriesPromise.then(response => {
      const countriesList = response.country_list;

      this.setCountries(countriesList);
      this.setCodes(response.codes);
      this.setNationalities(response.nationality);

      const countries = Object.keys(countriesList).reduce((options, key) => {
        options.push({
          value: key,
          label: countriesList[key],
        });
        return options;
      }, []);

      this.parsedCountries = countries;

      if (typeof callback === 'function') {
        return callback(countries);
      }
      return countries;
    });
  }

  /**
   * @param countries
   * @returns {CountryModel}
   */
  setCountries(countries) {
    this.countries = countries;
    return this;
  }

  /**
   * @returns {*}
   */
  getCountries() {
    return this.countries;
  }

  /**
   * @param codes
   * @returns {CountryModel}
   */
  setCodes(codes) {
    this.codes = codes;
    return this;
  }

  /**
   * @returns {*}
   */
  getCodes() {
    return this.codes;
  }

  /**
   * @param nationalities
   * @returns {CountryModel}
   */
  setNationalities(nationalities) {
    this.nationalities = nationalities;
    return this;
  }

  /**
   * @returns {*}
   */
  getNationalities() {
    return this.nationalities;
  }

  /**
   * @param {string} shortCode
   * @return {string}
   */
  getCodeByCountryShortCode(shortCode = '') {
    const countryCode = shortCode.toUpperCase();
    const codes = this.getCodes();
    return codes[countryCode] && codes[countryCode].toString().replace(/^\+/, '') || '';
  }

  /**
   * @param shortCode
   */
  getNameByCountryShortCode(shortCode) {
    const country = this.getCountries()[shortCode];
    return country || shortCode;
  }
}

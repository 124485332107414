import StaticDataModel from './static-data-model';

export default class PersonTitlesModel extends StaticDataModel {
  constructor() {
    super([
      { value: 'Mr', label: 'Mr.' },
      { value: 'Mrs', label: 'Mrs.' },
      { value: 'Miss', label: 'Miss.' },
      { value: 'Ms', label: 'Ms.' },
      { value: 'Dr', label: 'Dr.' },
      { value: 'Sir', label: 'Sir' },
    ]);
  }
}

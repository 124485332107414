import React from 'react';
import yup from 'yup';
import FormGroup from './../../../app/components/form-group';
import FormTextFieldWithTooltip from './../../components/form-text-field-with-tooltip';
import PostcodeLookup from './../../../postcode-lookup/form/fields/postcode-field';

const AddressForm = (props) => {
  const {
    postcodeName,
    countryName,
    onSetAddress,
    onLoadedAddresses,
    address1Name,
    address2Name,
    cityName,
    disabled,
  } = props;

  return (
    <div>
      <FormGroup
        fieldName={postcodeName}
        formGroupClassName="clearfix form-group-postcode form-group-sm"
        icons
      >
        <PostcodeLookup
          postcodeName={postcodeName}
          countryName={countryName}
          setAddress={onSetAddress}
          onLoadedAddresses={onLoadedAddresses}
          disabled={disabled}
        />
      </FormGroup>

      <FormGroup
        fieldName={address1Name}
        formGroupClassName="form-group-sm"
        icons
      >
        <label htmlFor={address1Name} className="sr-only">Address line 1</label>
        <FormTextFieldWithTooltip
          name={address1Name}
          placeholder="Address line 1"
          className="form-control input-sm"
          id={address1Name}
          disabled={disabled}
        />
      </FormGroup>

      <FormGroup fieldName={address2Name} formGroupClassName="form-group-sm" icons>
        <label htmlFor={address2Name} className="sr-only">Address line 2</label>
        <FormTextFieldWithTooltip
          name={address2Name}
          placeholder="Address line 2"
          className="form-control input-sm"
          id={address2Name}
          disabled={disabled}
        />
      </FormGroup>

      <FormGroup fieldName={cityName} formGroupClassName="form-group-sm" icons>
        <label htmlFor={cityName} className="sr-only">City</label>
        <FormTextFieldWithTooltip
          name={cityName}
          placeholder="City"
          className="form-control input-sm"
          id={cityName}
          disabled={disabled}
        />
      </FormGroup>
    </div>);
};
AddressForm.propTypes = {
  countryName: React.PropTypes.string.isRequired,
  postcodeName: React.PropTypes.string.isRequired,
  address1Name: React.PropTypes.string.isRequired,
  address2Name: React.PropTypes.string.isRequired,
  cityName: React.PropTypes.string.isRequired,
  onSetAddress: React.PropTypes.func.isRequired,
  onLoadedAddresses: React.PropTypes.func,
};

export default AddressForm;
export const schema = {
  postCode: yup.string(),
  address1: yup.string(),
  address2: yup.string(),
  city: yup.string(),
};
export const schemaRequired = {
  postCode: yup.string().trim()
    .matches(/^[\dA-Za-z\s-]{1,15}$/, 'Please enter a valid postcode')
    .required('Please enter postcode'),
  address1: yup.string().required('Please enter address line 1'),
  address2: yup.string(),
  city: yup.string().required('Please enter city name'),
};

import StaticDataModel from './../../base/models/static-data-model.js';

export default class CompanyTypesModel extends StaticDataModel {
  constructor() {
    super([
      { value: '1', label: 'Limited Company' },
      { value: '2', label: 'Public Limited' },
      { value: '3', label: 'Partnership' },
      { value: '4', label: 'Sole Trader' },
      { value: '5', label: 'Registered Charity' },
      { value: '6', label: 'Other' },
    ]);
  }
}

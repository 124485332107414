import React from 'react';
import Config from 'Config';

export default class WorldpayForm extends React.Component {
  static propTypes = {
    url: React.PropTypes.string,
  };

  componentDidMount() {
    const { url } = this.props;

    try {
      customOptions = {
        type: 'iframe',
        iframeIntegrationId: 'csWpObject',
        iframeHelperURL: `${Config.webUrl}helper.html`,
        iframeBaseURL: Config.webUrl,
        url,
        target: 'paymentForm',
        lightboxMaskOpacity: 50,
        lightboxMaskColor: '#000000',
        accessibility: true,
        debug: true,
        language: 'en',
        country: 'gb',
        preferredPaymentMethod: '',
        successURL: `${Config.webUrl}transfer?status=success`,
        cancelURL: `${Config.webUrl}transfer?status=fail`,
        failureURL: `${Config.webUrl}transfer?status=fail`,
        pendingURL: `${Config.webUrl}transfer?status=success`,
        errorURL: `${Config.webUrl}transfer?status=fail`,
      };

      csWpObject = new WPCL.Library();
      csWpObject.setup(customOptions);
      dispatchEvent(new Event('load'));
    } catch (e) {
      console.log('Worldpay error caught', e);
    }
  }

  render () {
    return (
      <div className='card-form'>
        <div id={'paymentForm'} />
      </div>
    );
  }
};
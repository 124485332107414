import React from 'react';
import { Link } from 'react-router';
import serviceLocator from './../../base/service/service-locator';
import UpdatableComponent from '../../base/components/updatable-component';
import InfiniteLoad from './../../base/components/infinite-load';
import FormattedCurrencyShort from './../../base/components/formatted-currency-short';
import Loader from './../../app/components/loader';
import Config from 'Config';
import TradeConfirmationShort from './../../trade/components/trade-confirmation-short';

export default class TransferListInProgress extends UpdatableComponent {

  utilsDate = serviceLocator.get('UtilsDate');
  transferGateway = serviceLocator.get('TransferGateway');
  utils = serviceLocator.get('Utils');
  logger = serviceLocator.get('Logger');

  scrolling = false;

  constructor(props) {
    super(props);

    this.state = {
      perPage: 0,
      error: null,
      transferList: [],  
      isLoading: true,
      lastId: 0,
      enteredMobile: false,
      selectedTransferId: null,
    };
  }

  componentDidMount() {
    this.getTransferList();
    window.addEventListener("resize",this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    this.setState({
      enteredMobile: this.utils.isMobileDevice() || window.innerWidth < 769,
    });
  };

  onClickTransfer = (transferId) => (e) => {
    e.preventDefault();
    this.setState((state) => ({
      expandedTransferId: state.selectedTransferId !== transferId,
      selectedTransferId: transferId,
    }));
  };

  getTransferList = () => {
    this.scrolling = false;

    this.transferGateway
      .get({
        completed: 0,
        lastId: this.state.lastId,
        perPage: 0,
      })
      .then(data => {
        if (!this.utils.isComponentMounted(this)) {
          return;
        }

        if (!data || !data.length) {
          this.setState({
            isLoading: false,
          });
          return;
        }

        const transferList = this.state.transferList.concat(data);
        const lastId = data[data.length - 1].paymentInstructionId;

        this.setState({
          transferList,
          lastId,
          isLoading: false,
        });
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }

        this.setState({
          error,
          isLoading: false,
        });
      });
  };

  renderRows() {
    return (
      <tbody>
      {!this.state.enteredMobile ? this.state.transferList.map((transfer, i) => (
        <tr key={i}>
          <td>{(transfer.tradeId)}</td>
          <td>{this.utilsDate.getUKDate(transfer.tradeDate)}</td>
          <td>{this.utilsDate.getUKDate(transfer.tradeValueDate)}</td>
          <td>
            <FormattedCurrencyShort value={transfer.sellAmount} currency={transfer.sellCurrency}/>
          </td>
          <td>
            <FormattedCurrencyShort value={transfer.buyAmount} currency={transfer.buyCurrency}/>
          </td>
          <td >{(transfer.fundsInCompleted) === '1' ? 'Completed' : ''}</td>
          <td className="recipient">{(transfer.recipient)}</td>
          <td><FormattedCurrencyShort value={transfer.paymentAmount} currency={transfer.buyCurrency}/></td>
          <td className="funds-completed">{(transfer.fundsOutCompleted) === '1' ? 'Completed' : ''}</td>
          <td>
            {
              transfer.tradeId && transfer.signedTradeId && transfer.confirmationNoteFileId
                ? <TradeConfirmationShort
                  tradeId={parseInt(transfer.tradeId, 0)}
                  signedTradeId={transfer.signedTradeId}
                />
                : null
            }
          </td>
          <td>
            <table className="nested-table">
              <thead>
                <tr>
                  <td>
                    {transfer.recipient === '' || null && typeof transfer.recipient === 'string'
                      ? <Link
                          to={`/send-out?id=${transfer.paymentInstructionId}`}
                          className="icon-linked add-recipient"
                        >AddRecipient</Link>
                      : (+transfer.paymentinstructionVerified === 0
                        ? <Link
                            to={`/send-out?id=${transfer.paymentInstructionId}`}
                            className="icon-linked edit"
                          >Edit</Link>
                        : null)
                    }
                  </td>
                </tr>
              </thead>
            </table>
          </td>
        </tr>))
        :
        this.state.transferList.map((transfer, i) => (
          <tr key={i}>
            <td colSpan="4" className={"expandable-row-mobile " + (this.state.selectedTransferId === transfer.paymentInstructionId && this.state.expandedTransferId ? 'is-active' : '')}>
              <table>
                <thead>
                  <tr className="first-row" onClick={this.onClickTransfer(transfer.paymentInstructionId)}>
                    <td>{transfer.tradeId}</td>
                    <td>{this.utilsDate.getUKDate(transfer.tradeDate)}</td>
                    <td className="menu-button"><FormattedCurrencyShort value={transfer.sellAmount} currency={transfer.sellCurrency}/>
                      <button className={"hamburger hamburger--slider " + (this.state.selectedTransferId === transfer.paymentInstructionId && this.state.expandedTransferId ? 'is-active' : '')}  type="button">
                        <span className="hamburger-box">
                          <span className="hamburger-inner"></span>
                        </span>
                      </button>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="first-color text-bold">
                    <td>Value Date</td>
                    <td>Currency</td>
                    <td colSpan="2">Buy Amount</td>
                  </tr>
                  <tr className="first-color">
                    <td>{this.utilsDate.getUKDate(transfer.tradeValueDate)}</td>
                    <td>{transfer.buyCurrency}</td>
                    <td colSpan="2"><FormattedCurrencyShort value={transfer.buyAmount} currency={transfer.buyCurrency}/></td>
                  </tr>
                  <tr className="second-color text-bold">
                    <td>Funds In</td>
                    <td>Funds Out</td>
                    <td colSpan="2">Pay Amount</td>
                  </tr>
                  <tr className="second-color">
                    <td>{(transfer.fundsInCompleted) === '1' ? 'Completed' : ''}</td>
                    <td className="funds-completed">{(transfer.fundsOutCompleted) === '1' ? 'Completed' : ''}</td>
                    <td colSpan="2"><FormattedCurrencyShort value={transfer.paymentAmount} currency={transfer.buyCurrency}/></td>
                  </tr>
                  <tr className="third-color text-bold">
                    <td>Recipient</td>
                    <td colSpan="2">Confirmation</td>
                  </tr>
                  <tr className="third-color">
                    <td className="recipient">{transfer.recipient}</td>
                    <td>
                      {
                        transfer.tradeId && transfer.signedTradeId && transfer.confirmationNoteFileId
                          ? <TradeConfirmationShort
                            tradeId={parseInt(transfer.tradeId, 0)}
                            signedTradeId={transfer.signedTradeId}
                          />
                          : null
                      }
                    </td>
                    <td colSpan="2">
                      {(transfer.recipient) === '' || null && typeof transfer.recipient === 'string' ?
                        <Link to={`/send-out?id=${transfer.paymentInstructionId}`} className="icon-linked add-recipient">AddRecipient</Link>
                        :
                        <Link to={`/send-out?id=${transfer.paymentInstructionId}`} className="icon-linked edit">Edit</Link>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>))
         }
      </tbody>
    )
  };

  renderTable() {
    return (
      <div className="table-responsive" key="in-progress">
        <table className="table">
          <thead>
          {!this.state.enteredMobile
            ?
              <tr>
                <th>Trade ID</th>
                <th>Trade Date </th>
                <th>Value Date</th>
                <th>Sell Amount</th>
                <th>Buy Amount</th>
                <th>Funds In</th>
                <th>Recipient</th>
                <th>Payment Amount</th>
                <th>Funds Out</th>
                <th>Confirmation</th>
                <th className="action">Action</th>
              </tr>
            : <tr>
                <th>Trade ID</th>
                <th>Trade Date</th>
                <th className="account-cell">Sell amount</th>
              </tr>
          }
          </thead>
          {this.renderRows()}
        </table>

        {this.state.transferList.length > 0 && this.state.infiniteLoadingEnabled
          ? <div className="text-center scroll-info">Scroll down to load more transfers</div>
          : null
        }
      </div>);
  }

  render() {
    const {isLoading, transferList} = this.state;

    return (
      <div>
        {transferList.length > 0
          ? this.renderTable()
          : <h6 className={"text-center loading-status"}>No transfers in progress</h6>
        }

        {isLoading ? <Loader /> : null}
      </div>
    );
  }
}
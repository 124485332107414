import React from 'react';
import Form from 'react-formal';
import yup from 'yup';
import { Modal } from 'react-bootstrap';
import serviceLocator from './../../base/service/service-locator';
import Loader from './../../app/components/loader';
import FormComponent from './../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import FormMessage from './../../app/components/form-message';
import FormTextField from './../../base/components/form-text-field';
import SelectPhoneCode from './../../base/form/fields/select-phone-code';
import { schema as phoneVerifySchema }
  from './../../phone-verify/components/phone-verify-form-group';
import CallUs from './../../base/components/call-us';

export default class ModalEnquiry extends FormComponent {
  static propTypes = {
    showModal: React.PropTypes.bool.isRequired,
    hideModalHandler: React.PropTypes.func.isRequired,
    sellCurrency: React.PropTypes.string.isRequired,
    buyCurrency: React.PropTypes.string.isRequired,
    buyAmount: React.PropTypes.string,
    sellAmount: React.PropTypes.string,
    reason: React.PropTypes.string,
  };

  constructor(props) {
    const state = {
      isLoading: false,
    };
    const schema = {};

    if (serviceLocator.get('Auth').getCurrentUser().regId) {
      state.isClientRegistred = true;
    } else {
      state.isClientRegistred = false;
      schema.name = yup.string().required('Please enter your name');
      schema.email = yup.string().email().required('Please enter an email address');
      schema.phoneCode = phoneVerifySchema.phoneCode;
      schema.telephone = phoneVerifySchema.mobile.required('Please enter a phone number');
    }

    super(props, state, schema);

    this.makeEnquiryGateway = serviceLocator.get('MakeEnquiryGateway');
    this.utils = serviceLocator.get('Utils');

    this.onModalExit = this.onModalExit.bind(this);
  }

  onModalExit() {
    this.setState({ formErrors: {} });
  }

  formOnSubmit = (fields) => {
    this.setState({ isLoading: true });
    this._formOnSubmit(fields);

    const formattedFields = Object.assign({}, fields);

    formattedFields.sellCurrency = this.props.sellCurrency;
    formattedFields.buyCurrency = this.props.buyCurrency;

    if (this.props.sellAmount) {
      formattedFields.sellAmount = this.utils.formatAmount(this.props.sellAmount).value;
    }
    if (this.props.buyAmount) {
      formattedFields.buyAmount = this.utils.formatAmount(this.props.buyAmount).value;
    }

    const fieldsToSend = formattedFields;
    fieldsToSend.reason = 'User requested callback';
    this.makeEnquiryGateway
      .post(Object.assign({}, fieldsToSend, { requested: this.props.reason }))
      .then(() => {
        this.setState({ isLoading: false });
        this.props.hideModalHandler();
      })
      .catch(failResponse => {
        this.setState({ isLoading: false });
        this._onFailResponse(failResponse);
      });
  }

  renderForm() {
    return (
      <div>
        <FormGroup
          fieldName="name"
          icons
          formGroupClassName="form-group-sm"
          context={this.getChildContext()}
        >
          <label htmlFor="name" className="sr-only">Name</label>
          <FormTextField
            name="name"
            placeholder="Name"
            className="form-control input-sm"
            id="name"
          />
        </FormGroup>
        <FormGroup
          fieldName="email"
          icons
          formGroupClassName="form-group-sm"
          context={this.getChildContext()}
        >
          <label htmlFor="email" className="sr-only">Email</label>
          <FormTextField
            name="email"
            placeholder="Email"
            className="form-control input-sm"
            id="email"
          />
        </FormGroup>
        <FormGroup
          fieldName="telephone"
          icons
          formGroupClassName="form-group form-group-mobile form-control-mobile-wrap"
          context={this.getChildContext()}
        >
          <div
            className={'input-group input-group-sm'}
          >
            <SelectPhoneCode
              className="input-sm"
              name="phoneCode"
              label="Country Code"
              placeholder="Country Code"
            />
            <div className="form-group form-group-sm">
              <FormTextField
                name="telephone"
                className="form-control input-sm form-control-mobile"
                placeholder="Phone Number"
                id="telephone"
              />
            </div>
          </div>
        </FormGroup>
      </div>
    );
  }

  renderToProvideBestServices() {
    const { sellCurrency, buyCurrency } = this.props;
    return this.props.sellCurrency === 'OTHER' ?
      (
      <h4 className="text-center">
        To provide you with the best rates and services,<br />
        contact your personal broker<br /><br />
        <CallUs />
      </h4>
      ) :
      (
      <h4 className="text-center">
        To provide you with the best rates and services,<br />
        contact your personal broker<br />
        for trades from {sellCurrency} to {buyCurrency}<br /><br />
        <CallUs />
      </h4>
      );
  }

  render() {
    const { showModal, hideModalHandler } = this.props;
    const { setBlurEffect, removeBlurEffect } = this.utils;

    return (
      <Modal
        className="modal-enquiry"
        bsSize="sm"
        show={showModal}
        onExit={this.onModalExit}
      >
        <Form
          ref="form"
          component="div"
          className="form"
          schema={this.schema}
          value={this.state.value}
          errors={this.state.formErrors}
          onError={this.formOnError}
          onChange={this.formOnChange}
          onSubmit={this.formOnSubmit}
        >
          <Modal.Body>
            <div className="icon-wrap modal-icon-call-wrap">
              <div className="icon-call"></div>
            </div>
            {this.renderToProvideBestServices()}
            {
              this.state.isClientRegistred
              ? null
              : this.renderForm()
            }
            {this.state.isLoading ? <Loader /> : null}
          </Modal.Body>
          <Modal.Footer>
            <FormGroup formGroupClassName="form-group-btn-submit" context={this.getChildContext()}>
              <button
                type="button"
                className="btn btn-link btn-modal-close"
                onClick={hideModalHandler}
                disabled={this.state.isLoading}
              >Return to Platform</button>
            </FormGroup>
          </Modal.Footer>
        </Form>
      </Modal>);
  }
}

import React from 'react';
import serviceLocator from '../../base/service/service-locator';
import UpdatableComponent from '../../base/components/updatable-component';
import FormattedCurrency from './../../base/components/formatted-currency';
import Loader from './../../app/components/loader';

/**
 * Currency Balances
 */
export default class Balances extends UpdatableComponent {
  acl = serviceLocator.get('AclService');
  logger = serviceLocator.get('Logger');

  constructor(props) {
    super(props);

    this.balancesGateway = serviceLocator.get('BalancesGateway');
    this.utils = serviceLocator.get('Utils');

    this.state = {
      error: null,
      balancesList: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getBalancesList();
  }

  /**
   * Get balances list from the backend
   *
   * @returns {*}
   */
  getBalancesList() {
    if (!this.acl.isAllowed('balances')) {
      this.setState({
        balancesList: [],
        isLoading: false,
      });

      return;
    }

    this.setState({
      isLoading: true,
    });

    this.balancesGateway.get().then(data => {
      if (!this.utils.isComponentMounted(this)) {
        return;
      }
      this.setState({
        balancesList: data,
        isLoading: false,
      });
    }).catch(error => {
      if (this.logger.handlePromiseCatch(error)) {
        return;
      }
      this.setState({ error });
    });
  }

  render() {
    return (
      <section className="balances-section">
          {
            !this.state.isLoading
            ? <div>
                {this.state.balancesList.map((balance, i) => (
                  <div key={i}>
                    <FormattedCurrency
                      value={balance.current ? balance.current : 0}
                      currency={balance.currency}
                    />
                  </div>))
                }
              </div>
            : null
          }
        {this.state.isLoading ? <Loader /> : null}
      </section>
    );
  }
}

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import UpdatableComponent from '../../base/components/updatable-component';
import Config from 'Config';
import TransferListInProgress from './transfer-list-in-progress';
import TransferListCompleted from './transfer-list-completed';

export default class TransferList extends UpdatableComponent {
  render() {
    return (
      <div>
        {
          !this.props.isNotFront ?
          <div>
            <ReactCSSTransitionGroup
              component="div"
              transitionName="table-responsive"
              transitionEnterTimeout={Config.transitionTimeout.opacity}
              transitionLeaveTimeout={Config.transitionTimeout.opacity}
            >
              <h2 className="subtitle sub-in-progress">In progress</h2>

              <TransferListInProgress />
            </ReactCSSTransitionGroup>

            <ReactCSSTransitionGroup
              component="div"
              transitionName="table-responsive-completed"
              transitionEnterTimeout={Config.transitionTimeout.opacity}
              transitionLeaveTimeout={Config.transitionTimeout.opacity}
            >
              <h2 className="subtitle sub-completed">Completed</h2>

              <TransferListCompleted  isNotFront={this.props.isNotFront} />
            </ReactCSSTransitionGroup>
          </div>
          :
          <div>
            {
              this.props.completed === 0 ?
              <ReactCSSTransitionGroup
                component="div"
                transitionName="table-responsive"
                transitionEnterTimeout={Config.transitionTimeout.opacity}
                transitionLeaveTimeout={Config.transitionTimeout.opacity}
              >
                <TransferListInProgress />
              </ReactCSSTransitionGroup>
              : null
            }
            {
              this.props.completed === 1 ?
              <ReactCSSTransitionGroup
                component="div"
                transitionName="table-responsive-completed"
                transitionEnterTimeout={Config.transitionTimeout.opacity}
                transitionLeaveTimeout={Config.transitionTimeout.opacity}
              >

                <TransferListCompleted  isNotFront={this.props.isNotFront} />
              </ReactCSSTransitionGroup>
              :null
            }
          </div>
        }
      </div>
    );
  }
}
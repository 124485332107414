import React from 'react';
import { Link, browserHistory } from 'react-router';
import serviceLocator from './../../base/service/service-locator';

export default class TradeConfirmationShort extends React.Component {
  static propTypes = {
    tradeId: React.PropTypes.number,
    signedTradeId: React.PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      tradeId: props.tradeId,
      signedTradeId: props.signedTradeId,
    };
  }

  tradeConfirmationGateway = serviceLocator.get('TradeConfirmationGateway');
  handleClick = (e) => {
    this.tradeConfirmationGateway.get(this.state.tradeId).then(content => {
      const blob = new Blob([content], { type: 'application/octet-stream' });
      if (blob.size === 0) {
        browserHistory.replace('not-found');
      } else {
        const url = window.URL || window.webkitURL;
        const downloadUrl = url.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = `trade-confirmation-${this.state.signedTradeId}.pdf`;
        anchor.click();
      }
    });
    e.preventDefault();
    return false;
  }

  render() {
    return(
      <Link to={`trade-confirmation-${this.state.signedTradeId}.pdf`} onClick={this.handleClick} className="icon-linked fa-file-pdf-o">Trade</Link>
    )
  }

}

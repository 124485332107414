/**
 * Type notification config example.
 *
 * "link_href" and "link_title" not required
 * if "link_href" specified then "link_title" is required
 *
 * "example" : {
 *   "message": "Test notification prop1: :prop1 ; prop2: :prop2.",
 *   "link_href": "/transfer",
 *   "link_title": "Test link title"
 * },
 *
 * @type {{beneficiaryChase: {message: string}}}
 */


const NotificationConfig = {
  beneficiaryChase: {
    message: 'Please tell us where to send your funds',
  },
};

export default NotificationConfig;

const JWT_KEY = 'jwt_token';
const SESSION_STORAGE_KEY = 'session';
const TOKEN_TYPE_CSMS = 'csms';
import { browserHistory } from 'react-router';

export default class Jwt {

  token = '';
  payload = null;
  onJwtSet = [];

  constructor(storage, decoder) {
    this.setStorage(storage);
    this.setDecoder(decoder);
  }

  addOnJwtSet(handler) {
    this.onJwtSet.push(handler);
  }

  get() {
    if (!this.token) {
      this.token = this._getTokenFromStorage() || '';
    }
    return this.token;
  }

  set(token) {
    this.payload = null;
    try {
      this._checkToken(token);
      this.token = token;
      this._updateTokenInStorage(token);

      this.onJwtSet.map(handler => {
        handler();
      });

      return true;
    } catch (e) {
      this.token = '';
      this._updateTokenInStorage(null);
      return false;
    }
  }

  _checkToken(token) {
    if (!token) {
      throw new Error('JWT is empty');
    }

    const payload = this.getDecoder()(token);

    if (!payload.data) {
      throw new Error('JWT.data is empty');
    }

    const time = (new Date()).getTime();
    const jwtExpireTime = payload.exp * 1000;
    if (jwtExpireTime < time && payload.data.type === TOKEN_TYPE_CSMS) {
      browserHistory.replace('expired');
    }

    if (!payload.data.authorized) {
      // Guest jwt token
      return true;
    }

    if (!payload.data.email) {
      throw new Error('JWT token has incorrect data');
    }

    return true;
  }

  isJwtSet() {
    return this.get() !== '';
  }

  isCsmsToken() {
    return this.getPayload() && this.getPayload().type === TOKEN_TYPE_CSMS;
  }

  /**
   * Get jwt token payload
   * @returns {Object|null}
   */
  getPayload() {
    if (!this.payload) {
      if (this.get()) {
        this.payload = this.getDecoder()(this.get()).data;
      } else {
        this.payload = {};
      }

    }
    return this.payload;
  }

  /**
   * Get authorized user data
   * @returns {string|null}
   */
  getUser() {
    return this.getPayload() && this.getPayload().email || null;
  }

  /**
   * Is user marked as verified in jwt token?
   * @returns {boolean}
   */
  isUserVerified() {
    return !!(this.getPayload() && this.getPayload().verified);
  }

  /**
   * Is user marked as verified in jwt token?
   * @returns {boolean}
   */
  isUserAuthorized() {
    return this.getPayload() && this.getPayload().authorized || false;
  }

  /**
   * Get authorized user session from jwt
   * @returns {String|null}
   */
  getTokenSession() {
    const payload = this.getPayload();

    return payload && payload[SESSION_STORAGE_KEY] || null;
  }

  isTokenValid() {
    return !!this.getTokenSession();
  }

  /**
   * @returns {Storage}
   */
  getStorage() {
    return this.storage;
  }

  /**
   * @returns {Storage}
   */
  setStorage(storage) {
    this.storage = storage;
    return storage;
  }

  /**
   * @returns {function}
   */
  getDecoder() {
    return this.decoder;
  }

  /**
   * @param {function} decoder
   */
  setDecoder(decoder) {
    if (typeof decoder !== 'function') {
      throw new Error('JWT decoder must be a function');
    }
    this.decoder = decoder;
  }

  /**
   * @param {*} value
   */
  _updateTokenInStorage(value) {
    if (value) {
      this.getStorage().setItem(JWT_KEY, value);
    } else {
      this.getStorage().removeItem(JWT_KEY);
    }
  }

  _getTokenFromStorage() {
    return this.getStorage().getItem(JWT_KEY);
  }

  getSessionId() {
    return this.getTokenSession();
  }
}

import React from 'react';
import { browserHistory } from 'react-router';
import serviceLocator from '../../base/service/service-locator';

export default class StartTrading extends React.Component {
  static propTypes = {
    location: React.PropTypes.object,
  };

  componentWillMount() {
    this.auth.setJwt(null);
    this.auth.getToken(true).then(() => {
      document.location.href = '/';
    });
  }

  auth = serviceLocator.get('Auth');

  render() {
    return (
      <div className="tab-content platform-tabs">
        <div className="tab-pane fade in active tab-access-denied">
          <h1>Please wait!</h1>
          <p>We are signing you in...<br />
            <a href="/">Sign In manually</a> if you know your credentials
          </p>
        </div>
      </div>
    );
  }
}


import React from 'react';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from '../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import SelectField from './../../base/components/select-field';
import FormMessage from './../../app/components/form-message';
import PhoneVerifyGroup,
{ schema as phoneVerifySchema } from './../../phone-verify/components/phone-verify-form-group';
import SelectCountry,
{ schema as selectCountrySchema } from './../../base/form/fields/select-country';
import Address, {
  schemaRequired as addressSchemaRequired,
} from './../../base/form/fields/address';
import Config from 'Config';

export default class ReactivateStep1 extends FormComponent {
  static propTypes = {
    onComplete: React.PropTypes.func,
    initialValues: React.PropTypes.object,
  };

  occupationGateway = serviceLocator.get('OccupationModel');
  validateGateway = serviceLocator.get('ReactivateValidatePersonalDetails');

  constructor(props) {
    const {
      address,
      phone,
      occupation,
      name,
      dob,
    } = props.initialValues;
    const state = {
      occupations: [],
      name,
      dob: dob && dob !== '' ? new Date(dob) : null,
    };

    const schema = {
      mobileReference: phoneVerifySchema.mobileReference,
      phoneCode: phoneVerifySchema.phoneCode.default({value: phone ? phone.code : null}),
      mobile: phoneVerifySchema.mobile.required('Please enter a phone number').default(phone ? phone.number : null),
      currentAddressCountry: selectCountrySchema.default({value: address ? address.country : null}),
      currentAddressPostCode: addressSchemaRequired.postCode.default(address ? address.postcode : null),
      currentAddressAddressLine1: addressSchemaRequired.address1.default(address ? address.line1 : null),
      currentAddressAddressLine2: addressSchemaRequired.address2.default(address ? address.line2 : null),
      currentAddressCity: addressSchemaRequired.city.default(address ? address.town : null),
      Occupation: yup.object().required('Please select occupation').default({value: occupation}),
    };

    super(props, state, schema);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.occupationGateway
      .getList(options => {
        this.setState({
          occupations: options,
        });
      });
  }

  formOnSubmit = (fields) => {
    this._formOnSubmit(fields);

    const stepData = {
      address: {
        country: fields.currentAddressCountry,
        line1: fields.currentAddressAddressLine1,
        line2: fields.currentAddressAddressLine2,
        postcode: fields.currentAddressPostCode,
        town: fields.currentAddressCity,
      },
      phone: {
        code: +fields.phoneCode,
        number: +fields.mobile,
      },
      occupation: +fields.Occupation,
    };
    this.validateGateway
      .post(stepData, true)
      .then(() => {
        this.props.onComplete(stepData);
      })
      .catch((failResponse) => {
        this._onFailResponse(failResponse);
      });
  };

  onSetCurrentAddress = (
    currentAddressAddressLine1,
    currentAddressAddressLine2,
    currentAddressCity
  ) => {
    this.setFieldsValues({
      currentAddressAddressLine1,
      currentAddressAddressLine2,
      currentAddressCity,
    });
  };

  render() {
    const { name, dob } = this.state;

    return (
      <Form
        ref="form"
        component="div"
        className="form"
        schema={this.schema}
        value={this.state.value}
        errors={this.state.formErrors}
        onError={this.formOnError}
        onChange={this.formOnChange}
        onSubmit={this.formOnSubmit}
      >
        <div className="reactivate-step1">
          <div className="clearfix">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="text-center">Welcome back</h1>
              <p className="text-center">
                As you have not used your account in a while we need to check a few details.<br/>
                Please confirm the below information is still correct.
              </p>
            </div>
          </div>

          <div className="clearfix">
            <div className="col-md-8 col-md-offset-2">
              <h2 className="decorated text-center font-weight-bold text-uppercase"><span>Personal details</span></h2>
            </div>
          </div>

          <div className="clearfix">
            <div className="col-md-4 col-md-offset-2">
              <div className="row name-row">
                <div className="col-xs-12 name-cont">
                  <div className="row">
                    <div className="col-xs-4">
                      Name:
                    </div>
                    <div className="col-xs-6">
                      {name}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-4">
                      Date of Birth:
                    </div>
                    {dob && dob.toDateString() !== 'Invalid Date'
                      ? <div className="col-xs-6">
                          {`${dob.getDate() <= 9 ? '0' : ''}${dob.getDate()}/${dob.getMonth() < 9 ? '0' : ''}${dob.getMonth() + 1}/${dob.getFullYear()}`}
                        </div>
                      : null
                    }
                  </div>
                </div>
              </div>

              <FormGroup
                formGroupClassName="form-group-mobile"
                fieldName="mobile"
                icons
              >
                <label htmlFor="mobileField" className="sr-only">Mobile</label>
                <PhoneVerifyGroup
                  verifyLabel="Verify"
                  verifiedLabel="Verified"
                  modalTitle="Please verify your phone number"
                  tooltip
                />
              </FormGroup>

              <SelectField
                options={this.state.occupations}
                formGroupClassName="form-group-select form-group-sm"
                className="input-sm"
                name="Occupation"
                placeholder="Occupation"
                tooltip
              />
            </div>
            <div className="col-md-4">

              <SelectCountry
                className="input-sm no-arrow"
                formGroupClassName="form-group-sm"
                name="currentAddressCountry"
                label="currentAddressCountry"
                placeholder="Current address"
                icons
                tooltip
              />
              <Address
                postcodeName="currentAddressPostCode"
                countryName="currentAddressCountry"
                address1Name="currentAddressAddressLine1"
                address2Name="currentAddressAddressLine2"
                cityName="currentAddressCity"
                onSetAddress={this.onSetCurrentAddress}
              />
            </div>
          </div>

          <div className="clearfix">
            <div className="col-md-4 col-md-offset-4">
              <FormMessage htmlFor="common" />

              <FormGroup fieldName="nextStep" formGroupClassName="form-group-btn-submit">
                <Form.Button
                  type="submit"
                  className="btn btn-lg btn-block btn-info btn-reactivate-submit btn-step-1"
                >Next</Form.Button>
              </FormGroup>
            </div>
          </div>
        </div>

      </Form>);
  }
}

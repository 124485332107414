import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator.js';

const msg = { margin: '20px 0 40px' };

const WarningOpenTrade = ({ showModal, onClose, onReview }) => {
  const { setBlurEffect, removeBlurEffect } = serviceLocator.get('Utils');
  return (
    <Modal
      className="modal-call-us risk-of-trade"
      show={showModal}
      >
      <Modal.Body>
        <h4 className="modal-title">Risk of trade duplication</h4>
        <br />
        <h6>
          We can see that you have already submitted and confirmed an order to purchase currency.
        </h6>

        <div style={msg}>
          Before we give you a new quote, would you prefer to review your existing transaction(s)?
          Please note if you duplicate a transaction in error and no longer require the currency,
          we will sell the currency purchased for you back to the market at the exchange rate at
          that point of time. You will be liable for any costs or losses that may be incurred.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="form-group">
          <div className="form-grou-row">
            <div className="col-md-6">
              <button
                className="btn btn-default"
                onClick={() => { onClose(); onReview(); removeBlurEffect(); }}
                >
                Take me to my existing transactions
              </button>
            </div>
            <div className="col-md-6">
              <button className="btn btn-info" onClick={onClose}>
                Continue with a new currency quote
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>);
  };

  WarningOpenTrade.propTypes = {
    showModal: React.PropTypes.bool.isRequired,
    onClose: React.PropTypes.func.isRequired,
    onReview: React.PropTypes.func.isRequired,
  };

  export default WarningOpenTrade;

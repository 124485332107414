import serviceLocator from './../base/service/service-locator';
import DataModel from './../base/models/data-model';
import RegistrationModel from './models/registration';
import CompanyTypesModel from './models/company-types-model.js';
import UserSourcesModel from './models/user-sources-model.js';
import AssocListDataModel from './../base/models/assoc-list-data-model';

export default () => {
  serviceLocator.register(
    'OpenDedicatedDealerAccountGateway',
    RegistrationModel,
    ['client/open-dedicated-dealer-account']
  ).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('CompanyTypesModel', CompanyTypesModel);
  serviceLocator.register('CompanyIndustryModel', AssocListDataModel, ['list-legacy/indtypes'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('OccupationModel', AssocListDataModel, ['list-legacy/occupations'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('UserSourcesModel', UserSourcesModel);
  serviceLocator.register('SecurityQuestionsModel', AssocListDataModel, ['list-legacy/security-questions'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register(
    'ValidateBasicInformationGateway',
    DataModel,
    ['client/validate-basic-information']
  ).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register(
    'ValidatePersonalDetailsGateway',
    DataModel,
    ['client/validate-personal-details']
  ).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register(
    'ValidateCorporateDetailsGateway',
    DataModel,
    ['client/validate-corporate-details']
  ).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register(
    'ValidateSecurityDetailsGateway',
    DataModel,
    ['client/validate-security-details']
  ).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
};

import DataModel from './../../base/models/data-model';

export default class AssocListDataModel extends DataModel {

  assocList = null;
  parsedList = null;
  promise = null;

  /**
   * Returns list of select options as array
   */
  getList(callback) {
    if (this.parsedList) {
      if (typeof callback === 'function') {
        return callback(this.parsedList);
      }
      return this.parsedList;
    }

    if (!this.promise) {
      this.promise = this.get();
    }

    return this.promise.then(response => {
      this.assocList = (response && response.list) ? response.list : response;
      this.parsedList = Object.keys(this.assocList).reduce((options, key) => {
        options.push({
          value: key,
          label: this.assocList[key],
        });
        return options;
      }, []);
      this.parsedList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      if (typeof callback === 'function') {
        return callback(this.parsedList);
      }
      return this.parsedList;
    });
  }

  /**
   * @returns {*}
   */
  getAssocList() {
    return this.assocList;
  }

  /**
   * @param {string} key
   * @return {string}
   */
  getValue(key) {
    return this.assocList && this.assocList[key];
  }
}

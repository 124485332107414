import serviceLocator from './../base/service/service-locator';
import PhoneVerificationModel from './../phone-verify/models/phone-verification';

export default () => {
  serviceLocator.register('PhoneVerificationModel', PhoneVerificationModel, [
    'user/mobile-verify',
    'user/mobile-verify/request-resend',
    'user/mobile-verify/request',
    'user/mobile-verify/force-verify',
    'user/mobile-verify/get-verified-phones',
  ]).dependencies('dataClient=HttpClientAdapter,jwt=Jwt,logger=Logger');
};

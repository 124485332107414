import React from 'react';

const Maintenance = () => {
  return (
    <div className="tab-content platform-tabs">
      <div className="tab-pane fade in active tab-maintenance">
        <h1>Sorry, we are currently down<br /> for routine maintenance.</h1>

        <div className="maintenance-icon" />

        <p>For a live quote or more information about our service<br />
          please call us on <a href="tel:+442077400000">+44 (0)20 7740 0000</a>.</p>
      </div>
    </div>
  );
};

export default Maintenance;

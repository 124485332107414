import ReactGA from 'react-ga';
import Config from 'Config';

export default class GA {
  constructor() {
    if (!Config.gaEnabled) {
      return;
    }
    ReactGA.initialize(Config.googleAnalyticsId);
  }

  logPageView(page) {
    if (!Config.gaEnabled) {
      return;
    }
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }

  logEvent(category, action, additionalArgs = {}) {
    if (!Config.gaEnabled) {
      return;
    }
    ReactGA.event({
      ...additionalArgs,
      category,
      action,
    });
  }
}

import serviceLocator from './../base/service/service-locator';

import DataModel from './../base/models/data-model';

export default () => {
  serviceLocator.register('SendOutGateway', DataModel, ['transfer/send-out'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('CreateCardTransactionGateway', DataModel, ['card-transaction/create'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('StatusCardTransactionGateway', DataModel, ['card-transaction/status'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
};

export const transferReasonOther = '100';

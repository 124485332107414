import DataModel from './../../base/models/data-model';

export default class Registration extends DataModel {
  /**
   * @returns {object}
   * @protected
   */
  _getHeaders() {
    const headers = super._getHeaders();
    if (sessionStorage) {
      const query = sessionStorage.getItem('originalQueryString');
      if (query) {
        headers['Referer-Query'] = query;
      }
    }
    return headers;
  }
}

import axios from 'axios';
import serviceLocator from './../base/service/service-locator';
import HttpClientAdapter from './adapter/http-client-adapter';
import Config from 'Config';
import CountryModel from './models/country-model';
import CurrencyModel from './models/currency-model';
import AssocListDataModel from './models/assoc-list-data-model';
import Logger from './models/logger';
import Utils from './components/utils.js';
import UtilsDate from './components/utils-date.js';
import UtilsCurrency from './components/utils-currency.js';
import PersonTitlesModel from './models/person-titles-model.js';
import GA from './components/google-analytics';

export default () => {
  serviceLocator.register(
    'HttpClientAdapter',
    HttpClientAdapter,
    [Config.httpApiUrl, axios]
  );

  serviceLocator.register('Logger', Logger, ['log'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('CurrencyGateway', CurrencyModel, ['list-legacy/currency'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('CountryGateway', CountryModel, ['list-legacy/country'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('SepaCountryGateway', CountryModel, ['list-legacy/sepa-country'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register(
    'AddressRequiredCountryGateway',
    CountryModel,
    ['list-legacy/address-required-country']).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register(
    'ReasonsForTransferGateway',
    AssocListDataModel,
    ['list-legacy/reasons-for-transfer']).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('Utils', Utils);
  serviceLocator.register('UtilsDate', UtilsDate);
  serviceLocator.register('UtilsCurrency', UtilsCurrency);
  serviceLocator.register('PersonTitlesModel', PersonTitlesModel);
  serviceLocator.register('GA', GA);
};

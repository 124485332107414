import React from 'react';
import yup from 'yup';
import SelectWithTooltip from './../../components/select-with-tooltip';

const SelectCurrency = ({ tooltip, id, name, options, className, placeholder, inputPosition }) => (
  <SelectWithTooltip
    tooltip={tooltip}
    id={id}
    name={name}
    options={options}
    className={className}
    placeholder={placeholder}
    clearable={false}
    backspaceRemoves={false}
    inputPosition={inputPosition}
  />);
SelectCurrency.propTypes = {
  id: React.PropTypes.string.isRequired,
  name: React.PropTypes.string.isRequired,
  options: React.PropTypes.array.isRequired,
  className: React.PropTypes.string,
  placeholder: React.PropTypes.string,
  tooltip: React.PropTypes.bool,
  inputPosition: React.PropTypes.string,
};

export default SelectCurrency;
export const schema = yup.object();

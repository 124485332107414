import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from './../../base/service/service-locator';
import UploadDocumentsForm from './../../upload-documents/components/upload-documents-form';

export default class UploadModal extends React.Component {
  utils = serviceLocator.get('Utils');

  static propTypes = {
    show: React.PropTypes.bool,
    onCloseHandler: React.PropTypes.func,
    reqId: React.PropTypes.string,
    reqText: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: (typeof this.props.show !== 'undefined') ? this.props.show : true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.show !== 'undefined') {
      this.setState({
        showModal: nextProps.show,
      });
    }
  }

  onClose = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      showModal: false,
    }, () => {
      if (this.props.onCloseHandler) {
        this.props.onCloseHandler();
      }
    });
  };

  render() {
    const { reqId, reqText } = this.props;
    return (
      <Modal
        className={`modal-upload`}
        id={`modal_upload`}
        show={this.state.showModal}
        onHide={this.onClose}
      >
        <Modal.Header>
          <Modal.Title>Document upload</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UploadDocumentsForm
            onSuccess={this.onClose}
            onCancel={this.onClose}
            showDetails={false}
            idIsRequired={false}
            billIsRequired={false}
            showForm={true}
            entityType={'file_requests'}
            entityId={reqId}
            header={reqText}
            subtitle={''}
          />
        </Modal.Body>
      </Modal>);
  }
}

class Ticker {
  constructor() {
    this.callbacks = [];
    this.amountInTime = 0;
  }

  start(amountInTime, interval) {
    this.amountInTime = amountInTime;
    this.interval = interval;
    this._timer = setInterval(this._ticker.bind(this), interval);
  }

  reset() {
    this.start(this.amountInTime, this.interval);
  }

  destroy() {
    this.stop();
    this.callbacks = [];
  }

  stop() {
    if (this._timer) {
      clearInterval(this._timer);
    }
    this._timer = null;
  }

  register(callback) {
    this.callbacks.push(callback);
  }

  _ticker = () => {
    if (this.callbacks.length === 0) {
      return;
    }

    this.amountInTime -= (this.interval / 1000);
    this.callbacks.forEach((callback) => callback(this.amountInTime));
  }
}

const ticker = new Ticker();
window.myticker = ticker;
export default ticker;

import serviceLocator from './../base/service/service-locator';
import AssocListDataModel from './../base/models/assoc-list-data-model';
import DataModel from "../base/models/data-model";

export default () => {
  serviceLocator.register('ReasonsModel', AssocListDataModel, ['list/reasons'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('AnnualAmountModel', AssocListDataModel, ['list/annualAmount'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('NumOfTransfersModel', AssocListDataModel, ['list/numOfTransfers'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('TransferToModel', AssocListDataModel, ['list/transferTo'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register(
    'ReactivateValidatePersonalDetails',
    DataModel,
    ['reactivate/validate/personal']
  ).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register(
    'ReactivateValidateTransfer',
    DataModel,
    ['reactivate/validate/transfer']
  ).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register(
    'UserCurrentDetails',
    DataModel,
    ['reactivate/current-details']
  ).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register(
    'ReactivateSubmit',
    DataModel,
    ['reactivate/submit']
  ).dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
};

import React from 'react';
import CustomEvent from 'custom-event';
import serviceLocator from './../base/service/service-locator';
import { browserHistory } from 'react-router';
import Config from 'Config';

import App from './../app/components/app';

class RouteConfig {

  auth = serviceLocator.get('Auth');
  aclService = serviceLocator.get('AclService');

  childRoutes = [];
  addedRoutes = [];
  parentPath = '/';
  route = null; // Current route
  location = null; // Browser location pathname
  defaultRoute = null;
  isReady = false;

  constructor() {
    browserHistory.listen(this.onBrowserHistoryListen);
    document.addEventListener('onAclReady', () => {
      const replaceTo = this.location && this.location.state ? this.location.state.from : '/';
      this.handlePath(replaceTo);
    });
  }

  onBrowserHistoryListen = (location) => {
    this.location = location;
    // Router has no component on / path, so we need to handle it manually
    if (location.pathname === '/') {
      this.handlePath('/');
    }

    if (location.search.indexOf('token=') !== -1) {
      if (this.auth.authorizeByTokenQuery(location.search)) {
        browserHistory.replace(location.pathname);
      } else if (this.location.pathname !== 'expired') {
        browserHistory.replace('deny');
      }
    }
  };

  /**
   * Handle current location path and make necessary replacements
   * @param pathname
   */
  handlePath(pathname = null, replaceHandler = null) {
    if (pathname === '/registration') {
      const query = sessionStorage.getItem('originalQueryString');
      window.location.href = `${Config.registrationLink}${query || ''}`;
      return;
    }

    // If acl is not ready - handle path later onAclReady
    if (!this.isReady || !this.aclService.isReady()) {
      return;
    }

    if (!pathname) {
      // eslint-disable-next-line no-param-reassign
      pathname = this.location.pathname;
    }
    const path = pathname.replace(/^\//, '');

    if (this.auth.isAuthorized() && path === '') {
      const redirectTo = {
        pathname: this.getDefaultRoute().path,
      };
      if (this.location.state && this.location.state.from) {
        redirectTo.pathname = this.location.state.from;
      }
      if (typeof replaceHandler === 'function') {
        replaceHandler(redirectTo);
      }
      browserHistory.replace(redirectTo);
      return;
    }

    // Maintenance mode
    // if (path !== 'maintenance') {
    //   browserHistory.replace('maintenance');
    // }

    if (path !== '' && path !== 'not-found' && this.getRouteIndexByPath(path) === false) {
      if (typeof replaceHandler === 'function') {
        replaceHandler('not-found');
      }
      browserHistory.replace('not-found');
      return;
    }

    if (!this.checkAccess(path)) {
      let fromPath = pathname;
      if (this.location && this.location.search) {
        fromPath += this.location.search;
      }
      const redirectTo = {
        pathname: '/deny',
        state: { from: fromPath },
      };
      if (!this.auth.isAuthorized()) {
        redirectTo.pathname = '/';
      }
      if (typeof replaceHandler === 'function') {
        replaceHandler(redirectTo);
      }
      browserHistory.replace(redirectTo);
      return;
    }

    this.setCurrentRoute(path);
    this.updateTitleByPath(path);
  }

  /**
   * @param {string} path
   */
  updateTitleByPath(path) {
    const route = this.getRouteByPath(path);
    const title = route && route.title && ` - ${route.title}` || '';
    document.title = Config.html.title.replace('%s', title);
  }

  /**
   * @param path
   */
  setCurrentRoute(path) {
    if (path === '') {
      return;
    }

    const route = this.route;
    if (route && route.path === path) {
      return;
    }

    this.route = this.getRouteByPath(path);

    const e = new CustomEvent('onRouteChange', { detail: { path: this.route.path } });
    document.dispatchEvent(e);
  }

  /**
   * @param path
   */
  checkAccess(path) {
    if (path === '' || path === 'deny' || path === 'not-found' || path === 'expired') {
      return true;
    }
    const route = this.getRouteByPath(path);
    if (route !== null && route.resource && !this.aclService.isAllowed(route.resource)) {
      return false;
    }
    return true;
  }

  /**
   * @param path
   * @param component
   * @param resource
   * @param {string} title
   * @param {bool} isDefault
   * @returns {*}
   */
  addRoute(path, component, resource, title, isDefault = false) {
    if (this.addedRoutes.indexOf(path) !== -1) {
      return;
    }

    const route = {};

    if (path === '') {
      throw new Error('Path can not be empty');
    }
    route.path = path;

    if (!component instanceof React.Component) {
      // eslint-disable-next-line max-len
      throw new Error(`Component must be an instance of React.Component, (${typeof (component)} is given)`);
    }
    route.component = component;

    if (resource) {
      route.resource = resource;
    }

    route.title = title || '';

    route.onEnter = (nextState, replaceHandler) => {
      this.handlePath(nextState.location.pathname, replaceHandler);
    };

    if (isDefault && !this.defaultRoute) {
      this.defaultRoute = route;
    }
    this.childRoutes.push(route);
    this.addedRoutes.push(path);
  }

  /**
   * @returns {*}
   */
  getDefaultRoute() {
    if (this.defaultRoute && this.aclService.isAllowed(this.defaultRoute.resource)) {
      return this.defaultRoute;
    }

    this.defaultRoute = null;
    // Get the first accessible route as default
    this.childRoutes.forEach((route) => {
      if (route.resource && this.aclService.isAllowed(route.resource)) {
        if (!this.defaultRoute) {
          this.defaultRoute = route;
        }
      }
    });
    return this.defaultRoute;
  }


  /**
   * @param path
   * @returns {*}
   */
  getRouteByPath(path) {
    const routeIndex = this.getRouteIndexByPath(path);
    if (routeIndex === false) {
      return null;
    }

    return this.getRouteByIndex(routeIndex);
  }

  /**
   * @param index
   * @returns {*}
   */
  getRouteByIndex(index) {
    const routes = this.getRoutes();
    if (!routes[index]) {
      return -1;
    }
    return routes[index];
  }

  /**
   * @param path
   */
  getRouteIndexByPath(path) {
    let found = false;
    this.getRoutes().forEach((route, index) => {
      if (route.path === path) {
        found = index;
      }
    });

    return found;
  }


  /**
   * @returns {Array}
   */
  getRoutes() {
    return this.childRoutes;
  }

  /**
   * @returns {{path: string, component: App, childRoutes: Array, onEnter: RouteConfig.onEnter}}
   */
  getRoutesConfig() {
    // Firstly handle current pathname to prevent unavailable route error
    this.handlePath(this.location.pathname);

    const routeConfig = {
      path: this.parentPath,
      component: App,
      childRoutes: this.getRoutes(),
      onEnter: this.onEnter,
    };

    return routeConfig;
  }

  /**
   * @returns {*|{}|null}
   */
  getCurrentRoute() {
    return this.route || { path: '' };
  }

  /**
   * @returns {*}
   */
  getCurrentRoutePath() {
    return this.getCurrentRoute().path;
  }

  routeReady() {
    this.isReady = true;

    const event = new CustomEvent('onRouteReady');
    document.dispatchEvent(event);
  }
}

export default RouteConfig;

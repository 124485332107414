import React from 'react';
import Select from 'react-select';
import Form from 'react-formal';
import SelectWithTooltip from './select-with-tooltip';
import FormGroup from './../../app/components/form-group';

export default class SelectField extends React.Component{
  static contextTypes = {
    formValue: React.PropTypes.object,
    setFieldsValues: React.PropTypes.func,
  };

  static propTypes = {
    name: React.PropTypes.string,
    placeholder: React.PropTypes.string,
    className: React.PropTypes.string,
    formGroupClassName: React.PropTypes.string,
    label: React.PropTypes.string,
    options: React.PropTypes.array.isRequired,
    tooltip: React.PropTypes.bool,
    icons: React.PropTypes.bool,
  };

  componentDidMount() {
    this.amendLabel(this.props, this.context);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.options !== nextProps.options) {
      this.amendLabel(nextProps, nextContext);
    }
  }

  amendLabel(props, context) {
    const { options, name } = props;
    const { formValue, setFieldsValues } = context;

    if (!options.length) {
      return;
    }

    if (!formValue.hasOwnProperty(name) || !formValue[name].hasOwnProperty('value')) {
      return;
    }

    const val = formValue[name].value;
    let found = false;
    if (Array.isArray(options)) {
      options.map(op => {
        if (op.value == val || +op.value == val) {
          setFieldsValues({
            [name]: op,
          });
          found = true;
        }
      })
    }

    if (!found) {
      setFieldsValues({
        [name]: null,
      });
    }
  }

  render() {
    const props = this.props;
    const formGroupClassName = props.formGroupClassName ?
      props.formGroupClassName :
      '';

    const fieldProps = {
      id: `${props.name}Field`,
      name: props.name,
      placeholder: props.placeholder,
      className: props.className,
      options: props.options,
      clearable: false,
      searchable: false,
      disabled: props.disabled,
    };

    const label = props.label ?
      <label htmlFor={`${props.name}Field`} className="sr-only">{props.label}</label> :
      null;

    const field = props.tooltip ?
      <SelectWithTooltip {...fieldProps} /> :
      <Form.Field type={Select} {...fieldProps} />;

    return (
      <FormGroup
        fieldName={props.name}
        formGroupClassName={`form-group-select ${formGroupClassName}`}
        icons={props.icons}
      >
        {label}
        {field}
      </FormGroup>
    );
  }
};


import PropTypes from 'prop-types'
import serviceLocator from './../../base/service/service-locator'

let intercomUserSet = false

const Intercom = ({ enabled, id }) => {
  if (enabled) {
    window.intercomSettings = { app_id: `${id}` }
    ;(function() {
      var w = window
      var ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        var d = document
        var i = function() {
          i.c(arguments)
        }
        i.q = []
        i.c = function(args) {
          i.q.push(args)
        }
        w.Intercom = i
        var l = function() {
          var s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = `https://widget.intercom.io/widget/${id}`
          var x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }
        l()
      }
    })()
    const auth = serviceLocator.get('Auth')
    const onUserLoaded = () => {
      if (intercomUserSet) {
        return
      }

      intercomUserSet = true
      const user = auth.getCurrentUser()
      if (!user.clientId) {
        return
      }

      window.intercomSettings = {
        app_id: `${id}`,
        // email: user.email,
        user_id: user.clientId,
        // name: user.name,
      }
      window.Intercom('boot', window.intercomSettings)
      window.Intercom('update', window.intercomSettings)

      if (window.ga) {
        ga('set', 'userId', user.clientId);
      }
    }

    auth.onCurrentUserLoaded(onUserLoaded)
    const user = auth.getCurrentUser()
    if (user.clientId) {
      onUserLoaded()
    }
  }

  return null
}
Intercom.propTypes = {
  enabled: PropTypes.bool,
  id: PropTypes.string,
}

export default Intercom

import StaticDataModel from './../../base/models/static-data-model.js';

export default class EnquiryStatusModel extends StaticDataModel {
  constructor() {
    super([
      { value: '0', label: 'All' },
      { value: 'New', label: 'New' },
      { value: 'Rejected', label: 'Rejected' },
      { value: 'Registered', label: 'Registration Pending' },
      { value: 'Authorized', label: 'Authorized' },
      { value: 'Traded', label: 'Traded' },
    ]);
  }
}

import React from 'react';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from '../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import SelectField from './../../base/components/select-field';
import FormMessage from './../../app/components/form-message';
import SelectCountry,
{ schema as selectCountrySchema } from './../../base/form/fields/select-country';
import Loader from './../../app/components/loader';

export default class ReactivateStep2 extends FormComponent {
  reasonsModel = serviceLocator.get('ReasonsModel');
  annualAmountModel = serviceLocator.get('AnnualAmountModel');
  numOfTransfersModel = serviceLocator.get('NumOfTransfersModel');
  transferToModel = serviceLocator.get('TransferToModel');
  validateGateway = serviceLocator.get('ReactivateValidateTransfer');
  auth = serviceLocator.get('Auth');
  utils = serviceLocator.get('Utils');

  constructor(props) {
    const {
      annualAmount,
      numOfTransfers,
      reasonForTransfer,
      sendToCountries,
      transferTo,
    } = props.initialValues;
    const state = {
      reasons: [],
      annualAmounts: [],
      numOfTransfers: [],
      transferTos: [],
      submitted: false,
    };

    const schema = {
      ReasonForTransfer: yup.object().required('Please select reason for transfer').default({value: reasonForTransfer}),
      AnnualAmount: yup.object().required('Please select your annual transfer amount').default({value: annualAmount}),
      NumOfTransfers: yup.object().required('Please select the number of transfers').default({value: numOfTransfers}),
      TransferTo: yup.object().required('Please select who will receive the money').default({value: transferTo}),
      SendToCountries: selectCountrySchema.required('Please select destination country').default({value: sendToCountries}),
    };

    super(props, state, schema);
  }

  static propTypes = {
    onComplete: React.PropTypes.func,
    initialValues: React.PropTypes.object,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.reasonsModel.getList(options => {
      this.setState({reasons: options});
    });
    this.annualAmountModel.getList(options => {
      this.setState({annualAmounts: options});
    });
    this.numOfTransfersModel.getList(options => {
      this.setState({numOfTransfers: options});
    });
    this.transferToModel.getList(options => {
      this.setState({transferTos: options});
    });
  }

  formOnSubmit = (fields) => {
    this._formOnSubmit(fields);

    const stepData = {
      annualAmount: +fields.AnnualAmount,
      numOfTransfers: +fields.NumOfTransfers,
      reasonForTransfer: +fields.ReasonForTransfer,
      transferTo: +fields.TransferTo,
      sendToCountries: [fields.SendToCountries],
    };

    this.validateGateway
    .post(stepData, true)
    .then(() => {
      this.props.onComplete(stepData);
      this.setState({submitted: true});
    })
    .catch((failResponse) => {
      this._onFailResponse(failResponse);
    });
  };

  render() {
    const {submitted} = this.state;

    return (
      <Form
        ref="form"
        component="div"
        className="form"
        schema={this.schema}
        value={this.state.value}
        errors={this.state.formErrors}
        onError={this.formOnError}
        onChange={this.formOnChange}
        onSubmit={this.formOnSubmit}
        >
        <div className="reactivate-step2">
          <div className="clearfix">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="text-center">Welcome back</h1>
              <p className="text-center">
                As you have not used your account in a while we need to check a few details.<br/>
              Please confirm the below information is still correct.
            </p>
          </div>
        </div>

        <div className="clearfix">
          <div className="col-md-8 col-md-offset-2">
            <h2 className="decorated text-center font-weight-bold text-uppercase"><span>About your transfer</span></h2>
          </div>
        </div>

        <div className="clearfix">
          <div className="col-md-5 col-md-offset-1">
            <SelectField
              options={this.state.reasons}
              formGroupClassName="form-group-select form-group-sm"
              className="input-sm"
              name="ReasonForTransfer"
              placeholder="Reason for transfer"
              tooltip
              />

            <SelectField
              options={this.state.annualAmounts}
              formGroupClassName="form-group-select form-group-sm"
              className="input-sm"
              name="AnnualAmount"
              placeholder="What is your annual transfer amount? (Estimate)"
              tooltip
              />

            <SelectField
              options={this.state.numOfTransfers}
              formGroupClassName="form-group-select form-group-sm"
              className="input-sm"
              name="NumOfTransfers"
              placeholder="How many transfers will you make? (Estimate)"
              tooltip
              />
          </div>
          <div className="col-md-5">
            <SelectField
              options={this.state.transferTos}
              formGroupClassName="form-group-select form-group-sm"
              className="input-sm"
              name="TransferTo"
              placeholder="Who will the money be transferred to?"
              tooltip
              />

            <SelectCountry
              className="input-sm"
              formGroupClassName="form-group-sm"
              name="SendToCountries"
              label="Destination Country"
              placeholder="Which country will you send the money to?"
              tooltip
              />
          </div>
        </div>

        <div className="clearfix">
          <div className="col-md-4 col-md-offset-4">
            <FormMessage htmlFor="common" />

            {submitted ? <Loader /> : null}

            <FormGroup fieldName="nextStep" formGroupClassName="form-group-btn-submit">
              <Form.Button
                type="submit"
                className="btn btn-lg btn-block btn-info btn-reactivate-submit btn-step-1"
                disabled={submitted}
                >Confirm</Form.Button>
            </FormGroup>
          </div>
        </div>
      </div>
    </Form>);
  }
}

/**
 *
 */
export default class NotificationMapper {

  /**
   *
   * @param config
   */
  constructor(config) {
    this.config = config;
  }

  /**
   *
   * @param data
   * @returns {Array}
   */
  map(data) {
    const notificationList = [];
    for (let i = 0; i < data.length; i++) {
      if (typeof this.config[data[i].type] === 'undefined' || this.config[data[i].type] == null) {
        continue;
      }
      const notification = Object.assign({}, this.config[data[i].type]);
      notification.id = i;
      Object.keys(data[i].parameters).forEach(prop => {
        notification.message = notification.message
          .replace(new RegExp(`:${prop}`, 'g'), data[i].parameters[prop]);
      });
      notificationList.push(notification);
    }
    return notificationList;
  }
}

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import InfiniteLoad from './../../base/components/infinite-load';
import FormGroup from './../../app/components/form-group.js';
import FormComponent from '../../base/components/form-component';
import Loader from './../../app/components/loader';
import ConfirmPopup from './../../app/components/confirm-popup';
import Config from 'Config';

/**
 * User list
 */
export default class BannedEmails extends FormComponent {


  constructor(props) {
    const state = {
      page: 1,
      limit: props.limit || 10,
      error: null,
      emailsList: [],
      infiniteLoadingEnabled: true,
      isLoading: true,
      filter: {},
      showConfirmation: false,
    };

    const schema = {
      email: yup.string(),
      bannedOnly: yup.bool(),
    };

    super(props, state, schema);

    this.bannedEmailsGateway = serviceLocator.get('BannedEmailsGateway');

    this.onCancelDelete = this.onCancelDelete.bind(this);
    this.deleteEmail = this.deleteEmail.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.getBannedEmailsList();
  }

  getBannedEmailsList = () => {
    this.setState({
      isLoading: true,
    });

    this.state.filter.per_page = this.state.limit;
    this.state.filter.page = this.state.page;

    this.bannedEmailsGateway
      .get(this.state.filter)
      .then(data => {
        const nextState = {
          emailsList: this.state.emailsList.concat(data.items),
          page: this.state.page + 1,
          isLoading: false,
        };

        if (this.state.page * this.state.limit >= data.count) { // nothing to load
          nextState.infiniteLoadingEnabled = false;
        }

        this.setState(nextState);
      })
      .catch(error => {
        this.setState({
          error,
          infiniteLoadingEnabled: false,
        });
      });
  };

  formOnSubmit = (fields) => {
    this.setState({
      page: 1,
      emailsList: [],
      infiniteLoadingEnabled: true,
      filter: fields,
    }, () => {
      this.getBannedEmailsList();
    });
  };

  onDelete = (id) => {
    this.setState({
      showConfirmation: true,
      selectedEmailId: id,
    });
  };

  onCancelDelete() {
    this.setState({
      showConfirmation: false,
    });
  }

  deleteEmail() {
    const id = this.state.selectedEmailId;

    this.setState({
      showConfirmation: false,
      isLoading: true,
    });
    this.bannedEmailsGateway
      .del(parseInt(id, 10))
      .then(() => {
        const emailsList = this.state.emailsList;

        Object.keys(emailsList).forEach(i => {
          if (emailsList[i].id === id) {
            emailsList.splice(i, 1);
          }
        });

        this.setState({
          emailsList,
          selectedEmailId: null,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          selectedEmailId: null,
          isLoading: false,
        });
      });
  }

  renderTable() {
    return (
      <div className="table-responsive">
        <InfiniteLoad
          scrollHandler={this.getBannedEmailsList}
          isEnabled={this.state.infiniteLoadingEnabled}
          isLoading={this.state.isLoading}
          bottomOffset={Config.infiniteLoadBottomOffset}
        >
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Is banned</th>
                <th>Count of tries</th>
                <th>Last try</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              {this.state.emailsList.map((e, i) => (
                <tr key={i}>
                  <td>{e.email}</td>
                  {
                    e.is_banned
                    ? <td className="text-danger">Yes</td>
                    : <td className="text-success">No</td>
                  }
                  <td>{e.badtries}</td>
                  <td>{e.last_badtry_date}</td>
                  <td className="text-right">
                    <a
                      href="#"
                      className="text-danger delete-email"
                      onClick={() => this.onDelete(e.id)}
                    >Delete</a>
                  </td>
                </tr>))
              }
            </tbody>
          </table>
        </InfiniteLoad>
      </div>);
  }

  render() {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active tab-banned-emails" id="tab-banned-emails">
          <div className="clearfix">
            <div className="col-md-10 col-md-offset-1">
              <h2>Blocked Emails</h2>

              <Form
                component="div"
                schema={this.schema}
                value={this.state.value}
                errors={this.state.formErrors}
                onError={this.formOnError}
                onChange={this.formOnChange}
                onSubmit={this.formOnSubmit}
                className="form form-inline"
              >
                <FormGroup fieldName="email" icons>
                  <Form.Field
                    name="email"
                    placeholder="Email"
                    className="form-control input-sm"
                    id="email"
                  />
                </FormGroup>
                <FormGroup fieldName="bannedOnly">
                  <div className="checkbox">
                    <label htmlFor="bannedOnly">
                      <Form.Field
                        id="bannedOnly"
                        name="bannedOnly"
                        placeholder="Banned only"
                      />
                      <span className="input"></span>
                      <span>Banned only</span>
                    </label>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Form.Button
                    type="submit"
                    className="btn btn-sm btn-info search-users"
                    disabled={this.state.isLoading}
                  >Search</Form.Button>
                </FormGroup>
              </Form>

              <ReactCSSTransitionGroup
                component="div"
                transitionName="table-responsive"
                transitionEnterTimeout={Config.transitionTimeout.opacity}
                transitionLeaveTimeout={Config.transitionTimeout.opacity}
              >
                {this.state.emailsList.length > 0 ? this.renderTable() : null}
              </ReactCSSTransitionGroup>

              {
                (!this.state.emailsList.length && !this.state.isLoading)
                ? <h6 className="text-center">No results found for your search criteria</h6>
                : null
              }

              {this.state.isLoading ? <Loader /> : null}

              <ConfirmPopup
                text="Are you sure?"
                onConfirm={this.deleteEmail}
                onCancel={this.onCancelDelete}
                showModal={this.state.showConfirmation}
              />
            </div>
          </div>
        </section>
      </div>);
  }
}

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import InfiniteLoad from './../../base/components/infinite-load';
import FormGroup from './../../app/components/form-group';
import FormComponent from '../../base/components/form-component';
import SelectUserRole from './../form/fields/select-user-role';
import Loader from './../../app/components/loader';
import InformPopup from './../../app/components/inform-popup';
import UserForm from './user-form';
import Config from 'Config';

export default class UserList extends FormComponent {

  constructor(props) {
    const state = {
      page: 1,
      limit: props.limit || 10,
      error: null,
      userList: [],
      infiniteLoadingEnabled: true,
      isLoading: true,
      showUserForm: false,
      showInformModal: false,
      userId: null,
      filterFields: {},
      informModalText: '',
    };

    const schema = {
      email: yup.string(),
      clientId: yup.string(),
      role: yup.object(),
    };

    super(props, state, schema);

    this.userGateway = serviceLocator.get('AdminUserGateway');
    this.resetPasswordGateway = serviceLocator.get('ResetPasswordStep1Gateway');
    this.resendEmailConfirmationGateway = serviceLocator.get('ResendEmailConfirmationGateway');
    this.utils = serviceLocator.get('Utils');

    this.showInformModal = this.showInformModal.bind(this);
    this.hideInformModal = this.hideInformModal.bind(this);
    this.onSaveUser = this.onSaveUser.bind(this);
  }

  componentWillMount() {
    this.getUserList();
  }

  getUserList = (params) => {
    this.setState({
      isLoading: true,
    });

    const requestParams = params || Object.assign({}, this.state.filterFields);
    requestParams.per_page = this.state.limit;
    requestParams.page = this.state.page;

    this.userGateway
      .get(requestParams)
      .then(data => {
        const nextState = {
          userList: this.state.userList.concat(data.items),
          page: this.state.page + 1,
          isLoading: false,
        };

        if (this.state.page * this.state.limit >= data.count) { // nothing to load
          nextState.infiniteLoadingEnabled = false;
        }

        this.setState(nextState);
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.setState({
          error,
          infiniteLoadingEnabled: false,
        });
      });
  };

  formOnSubmit = (fields) => {
    const filterParams = Object.assign({}, fields);
    if (filterParams.role) {
      filterParams.role = filterParams.role.value;
    }
    this.setState({
      page: 1,
      userList: [],
      renderedUsers: null,
      infiniteLoadingEnabled: true,
      filterFields: filterParams,
    }, () => {
      this.getUserList(filterParams);
    });
  };

  onAddUser = () => {
    this.setState({
      showUserForm: true,
    });
  };

  onEditUser = (userId) => {
    this.setState({
      showUserForm: true,
      userId,
    });
  };

  onCloseEditUserModal = () => {
    this.setState({
      showUserForm: false,
      userId: null,
    });
  };

  onSaveUser = (data) => {
    const userList = [].concat(this.state.userList);
    const newUserData = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      role: data.role,
      id: data.userId,
      client_id: data.clientId,
      reg_id: data.regId,
    };
    let wasUpdated = false;

    for (let i = 0; i < userList.length; i++) {
      if (userList[i].id === data.userId) {
        userList[i] = newUserData;
        wasUpdated = true;
        break;
      }
    }

    if (!wasUpdated) {
      userList.unshift(newUserData);
    }

    this.setState({
      userList,
    });
  };

  showInformModal(text) {
    const nextState = {
      showInformModal: true,
    };

    if (text && text.length) {
      nextState.informModalText = text;
    }

    this.setState(nextState);
  }

  hideInformModal() {
    this.setState({ showInformModal: false });
  }

  onResetPassword = (userEmail) => (
    this.resetPasswordGateway
        .post({ email: userEmail }).then(data => {
          if (data.message) {
            this.showInformModal(data.message);
          } else {
            this.showInformModal('Error occured, please try again later');
          }
        }
      )
  );

  onResendConfirmationEmail = (userEmail) => (
    this.resendEmailConfirmationGateway
        .post({ email: userEmail }).then(data => {
          if (data.message) {
            this.showInformModal(data.message);
          } else {
            this.showInformModal('Error occured, please try again later');
          }
        }
    )
  );

  renderTable() {
    return (
      <div className="table-responsive">
        <InfiniteLoad
          scrollHandler={this.getUserList}
          isEnabled={this.state.infiniteLoadingEnabled}
          isLoading={this.state.isLoading}
          bottomOffset={Config.infiniteLoadBottomOffset}
        >
          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Email</th>
                <th>Registration Id</th>
                <th>Client Id</th>
                <th>Name</th>
                <th>Role</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              {this.state.userList.map((u, i) => (
                <tr key={i}>
                  <td>{u.id}</td>
                  <td>{u.email}</td>
                  <td>{u.reg_id}</td>
                  <td>{u.client_id}</td>
                  <td>{u.first_name} {u.last_name}</td>
                  <td>{u.role}</td>
                  <td className="text-right">
                    <DropdownButton
                      title={''}
                      id={`dropdown-action${u.id}`}
                      bsStyle="info"
                      bsSize="xsmall"
                      pullRight
                    >
                      <MenuItem onClick={() => this.onEditUser(u.id)}>Edit</MenuItem>
                      <MenuItem
                        onClick={() => this.onResetPassword(u.email)}
                      >Reset Password</MenuItem>
                      {
                        !u.email_confirmed
                        ? <MenuItem
                          onClick={() => this.onResendConfirmationEmail(u.email)}
                        >Email Confirmation</MenuItem>
                        : null
                      }
                    </DropdownButton>
                  </td>
                </tr>))
              }
            </tbody>
          </table>
        </InfiniteLoad>
      </div>);
  }

  /**
   * Render user list
   */
  render() {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active tab-user-list" id="tab-user-list">
          <div className="clearfix">
            <div className="col-md-10 col-md-offset-1">
              <div className="clearfix">
                <div className="pull-left"><h2>Users</h2></div>
                <div className="pull-right">
                  <FormGroup fieldName="add-user-button" icons>
                    <button
                      type="button"
                      className="btn btn-sm btn-info add-user"
                      onClick={this.onAddUser}
                    >Add User</button>
                  </FormGroup>
                </div>
              </div>

              <Form
                ref="form"
                component="div"
                className="form"
                schema={this.schema}
                value={this.state.value}
                errors={this.state.formErrors}
                onError={this.formOnError}
                onChange={this.formOnChange}
                onSubmit={this.formOnSubmit}
              >
                <div className="row">
                  <div className="col-sm-4">
                    <FormGroup fieldName="email" icons>
                      <Form.Field
                        name="email"
                        placeholder="Email"
                        className="form-control input-sm"
                        id="email"
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup fieldName="clientId" icons>
                      <Form.Field
                        name="clientId"
                        placeholder="Client Id"
                        className="form-control input-sm"
                        id="clientId"
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup fieldName="role" icons>
                      <SelectUserRole
                        className="input-sm"
                        name="role"
                        label="userRole"
                        placeholder="Role"
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="form-group text-center">
                  <Form.Button
                    type="submit"
                    className="btn btn-sm btn-info search-users"
                    disabled={this.state.isLoading}
                  >Search</Form.Button>
                </div>
              </Form>

              <ReactCSSTransitionGroup
                component="div"
                transitionName="table-responsive"
                transitionEnterTimeout={Config.transitionTimeout.opacity}
                transitionLeaveTimeout={Config.transitionTimeout.opacity}
              >
                {this.state.userList.length > 0 ? this.renderTable() : null}
              </ReactCSSTransitionGroup>

              {
                (!this.state.userList.length && !this.state.isLoading)
                ? <h6 className="text-center">No results found for your search criteria</h6>
                : null
              }

              {this.state.isLoading ? <Loader /> : null}

              <UserForm
                show={this.state.showUserForm}
                userId={this.state.userId}
                onClose={this.onCloseEditUserModal}
                onSaveUser={this.onSaveUser}
              />
            </div>
          </div>
        </section>

        <InformPopup
          showModal={this.state.showInformModal}
          text={this.state.informModalText}
          onClose={this.hideInformModal}
          onEntering={this.utils.setBlurEffect}
          onExiting={this.utils.removeBlurEffect}
        />
      </div>);
  }
}

import React from 'react';
import MenuTab from './menu-tab';
import serviceLocator from './../../base/service/service-locator';
import UpdatableComponent from '../../base/components/updatable-component';

export default class Menu extends UpdatableComponent {
  /**
   * @param props
   */
  constructor(props) {
    super(props);

    this.routeConfig = serviceLocator.get('RouteConfig');
    this.aclService = serviceLocator.get('AclService');
    this.menuModel = serviceLocator.get('MenuModel');
    this.auth = serviceLocator.get('Auth');

    this.state = {
      tabs: [],
      currentRoute: this.routeConfig.getCurrentRoutePath(),
      isRendering: true,
    };
  }

  componentWillMount() {
    document.addEventListener('onRouteReady', this.startTabsRender);
    this.auth.onAuthorize(this.startTabsRender);
    document.addEventListener('onRouteChange', (e) => {
      this.changeTab(e.detail.path);
    });
  }

  startTabsRender = () => {
    this.setState({
      isRendering: true,
    }, () => {
      this.renderTabs();
    });
  };

  /**
   * @param route
   */
  changeTab(route) {
    this.setState({
      currentRoute: route,
      isRendering: true,
    }, () => {
      this.renderTabs();
    });
  }

  componentDidMount() {
    this.menuList = this.menuModel.loadMenu();
    this.renderTabs();
  }

  renderTabs() {
    const tabs = [];

    this.menuList.forEach((tab) => {
      if (this.routeConfig.getRouteByPath(tab.route)) {
        tab.resource = this.routeConfig.getRouteByPath(tab.route).resource;
        tab.isActive = tab.route === this.state.currentRoute;
        if (this.aclService.isAllowed(tab.resource)) {
          tabs.push(tab);
        }
      }
    });
    this.setState({
      tabs,
      isRendering: false,
    });
  }

  render() {
    const {tabs} = this.state;

    if (!tabs || !tabs.length) {
      return null;
    }

    return (
      <nav className="tabs-menu">
        <ul>
          {tabs.map(t => (
            <MenuTab
              key={t.id}
              currentRoute={t.isActive}
              id={t.id}
              url={t.route}
              icon={t.icon}
              label={t.label}
              changeTab={() => this.changeTab(t.route)}
            />))
          }
        </ul>
      </nav>
    );
  }
}

import serviceLocator from './../base/service/service-locator';
import CrudModel from '../base/models/crud-model';
import EnquiryStatusModel from './models/enquiry-status-model';

export default () => {
  serviceLocator
    .register('EnquiryGateway', CrudModel, ['enquiry'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator
    .register('CommissionGateway', CrudModel, ['commission'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator
    .register('EnquiryStatusModel', EnquiryStatusModel);
};

import React from 'react';
import Form from 'react-formal';

const FormTextField = (props) => {
  const newProps = Object.keys(props).reduce((returnValue, key) => {
    const changedReturnValue = returnValue;
    if (key !== 'meta' && key !== 'context') {
      changedReturnValue[key] = props[key];
    }
    return changedReturnValue;
  }, {});
  return <Form.Field {...newProps} events={['onBlur']} />;
};

export default FormTextField;

import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import FormTextField from './form-text-field';
import { Tooltip } from 'react-bootstrap';

export default class FormTextFieldWithTooltip extends React.Component {
  static propTypes = {
    placeholder: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    context: React.PropTypes.object,
  }

  static contextTypes = {
    formValue: React.PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { showTooltip: false };
    this.isFocused = false;
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { name } = this.props;

    if (
      typeof nextContext.formValue[name] === 'string' &&
      this.context.formValue[name] !== nextContext.formValue[name] &&
      this.isFocused === true
    ) {
      if (nextContext.formValue[name].length > 0 && this.state.showTooltip === false) {
        this.setState({ showTooltip: true });
      }
      if (nextContext.formValue[name].length === 0 && this.state.showTooltip === true) {
        this.setState({ showTooltip: false });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  onFocusHandler() {
    const { formValue } = this.props.context || this.context;
    const { name } = this.props;
    this.isFocused = true;
    if (formValue[name] && typeof formValue[name] !== 'undefined' && formValue[name] !== '') {
      this.setState({ showTooltip: true });
    }
  }

  onBlurHandler() {
    this.isFocused = false;
    if (this.state.showTooltip) {
      this.setState({ showTooltip: false });
    }
  }

  render() {
    const props = this.props;
    const tooltip = this.state.showTooltip ?
      (<Tooltip
        id={`${props.name}Tooltip`}
        placement="top"
        className="in"
      >
        {props.placeholder}
      </Tooltip>) :
      null;

    return (
      <div>
        {tooltip}
        <FormTextField
          {...props}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
        />
      </div>
    );
  }
}

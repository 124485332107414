import AppConfig from 'AppConfig';
import Iclaus from './../iclaus/component/IclausLegacy';
import serviceLocator from './../base/service/service-locator';

const appCore = () => {
  Iclaus(AppConfig.claus.hostname, AppConfig.claus.origins);

  document.addEventListener('onAppReady', () => {
    const auth = serviceLocator.get('Auth');

    auth.jwt.addOnJwtSet(() => {
      auth.ready();
    });
    auth.getToken();
  });
};

export default appCore;

import React from 'react';
import { FormattedNumber } from 'react-intl';

const FormattedCurrencyShort = ({ value, currency }) => (
  <span>
    <FormattedNumber
      value={value}
      style="currency"
      currency={currency}
      minimumFractionDigits={2}
      maximumFractionDigits={2}
    />
  </span>);

FormattedCurrencyShort.propTypes = {
  value: React.PropTypes.oneOfType([
    React.PropTypes.number.isRequired,
    React.PropTypes.string.isRequired,
  ]),
  currency: React.PropTypes.string,
};

export default FormattedCurrencyShort;

import StaticDataModel from './../../base/models/static-data-model.js';

export default class UserSourcesModel extends StaticDataModel {
  constructor() {
    super([
      { value: 'internet', label: 'Internet' },
      { value: 'referral', label: 'Referral' },
      { value: 'newspaper', label: 'Newspaper/Magazine' },
      { value: 'exhibition', label: 'Exhibition' },
      { value: 'other', label: 'Other' },
    ]);
  }
}

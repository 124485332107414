import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator';
import FormattedCurrency from './../../base/components/formatted-currency';
import Loader from './../../app/components/loader';

export default class ModalBookingTrade extends React.Component {
  static propTypes = {
    buyAmount: React.PropTypes.oneOfType([
      React.PropTypes.number.isRequired,
      React.PropTypes.string.isRequired,
    ]),
    buyCurrency: React.PropTypes.oneOfType([
      React.PropTypes.object.isRequired,
      React.PropTypes.string.isRequired,
    ]),
    sellAmount: React.PropTypes.oneOfType([
      React.PropTypes.number.isRequired,
      React.PropTypes.string.isRequired,
    ]),
    sellCurrency: React.PropTypes.oneOfType([
      React.PropTypes.object.isRequired,
      React.PropTypes.string.isRequired,
    ]),
    showModal: React.PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    return (nextProps.showModal !== this.props.showModal);
  }

  utils = serviceLocator.get('Utils');

  renderBody() {
    return (<Modal.Body>
      <h4 className="modal-title">
        <div>
          We are processing your transaction for <span
            className="quoted-part"
          ><FormattedCurrency
            value={this.props.sellAmount}
            currency={this.props.sellCurrency}
          /></span> to <span
            className="quoted-part"
          ><FormattedCurrency
            value={this.props.buyAmount}
            currency={this.props.buyCurrency}
          /></span>
        </div>
      </h4>

      Please do not refresh the browser or press the back button<br /><br />

      <Loader />
    </Modal.Body>);
  }

  render() {
    return (
      <Modal
        className="modal-booking-trade"
        id="modalBookingTrade"
        show={this.props.showModal}
      >
        <Modal.Header>
          <Modal.Title>Please wait</Modal.Title>
        </Modal.Header>

        {this.renderBody()}
      </Modal>);
  }
}

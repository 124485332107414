import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import Form from 'react-formal';
import Select from 'react-select';
import { Tooltip } from 'react-bootstrap';

export default class SelectWithTooltip extends React.Component {
  static propTypes = {
    placeholder: React.PropTypes.string,
    name: React.PropTypes.string.isRequired,
    tooltip: React.PropTypes.bool,
    context: React.PropTypes.object,
  }

  static contextTypes = {
    formValue: React.PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { showTooltip: false };
    this.onOpenHandler = this.onOpenHandler.bind(this);
    this.onCloseHandler = this.onCloseHandler.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  onOpenHandler() {
    const { formValue } = this.props.context || this.context;
    const { name } = this.props;
    if (formValue[name] && typeof formValue[name] !== 'undefined') {
      this.setState({ showTooltip: true });
    }
  }

  onCloseHandler() {
    if (this.state.showTooltip) {
      this.setState({ showTooltip: false });
    }
  }

  render() {
    const props = this.props;
    const tooltip = props.tooltip && this.state.showTooltip ?
      (<Tooltip
        id={`${props.name}Tooltip`}
        placement="top"
        className="in"
      >
        {props.placeholder}
      </Tooltip>) :
      null;

    return (
      <div>
        {tooltip}
        <Form.Field
          type={Select}
          {...props}
          onOpen={this.onOpenHandler}
          onClose={this.onCloseHandler}
        />
      </div>
    );
  }
}

import React from 'react';
import yup from 'yup';
import serviceLocator from '../../service/service-locator';
import SelectField from './../../components/select-field';

export default class SelectCountry extends React.Component {
  state = {
    options: [],
  };

  componentWillMount() {
    this.loadData();
  }

  countryGateway = serviceLocator.get('CountryGateway');

  loadData() {
    this.countryGateway
      .getList(countryList => {
        this.setState({
          options: countryList,
        });
      });
  }

  render() {
    const { options, ...other } = this.props;

    return (
      <SelectField
        {...other}
        backspaceRemoves={false}
        options={this.state.options}
      />
    );
  }
}

export const schema = yup.object().required('Please select country');

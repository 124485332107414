import React from 'react';
import Form from 'react-formal';
import Select from 'react-select';
import serviceLocator from '../../../base/service/service-locator';
import FormGroup from './../../../app/components/form-group';
import SelectWithTooltip from './../../../base/components/select-with-tooltip';

export default class SelectUserRole extends React.Component {

  static propTypes = {
    context: React.PropTypes.object,
    name: React.PropTypes.string.isRequired,
    label: React.PropTypes.string,
    className: React.PropTypes.string,
    placeholder: React.PropTypes.string,
    icons: React.PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      options: [],
    };

    this.userRoleGateway = serviceLocator.get('UserRolesGateway');
  }

  componentWillMount() {
    this.loadData();
  }

  loadData(callback) {
    this.userRoleGateway
      .getList(userRoles => {
        this.setState({
          options: userRoles,
        }, callback);
      });
  }

  render() {
    const { props, state } = this;
    const fieldProps = {
      id: `${props.name}Field`,
      name: props.name,
      ref: `role-${props.name}`,
      placeholder: props.placeholder,
      className: props.className,
      options: state.options,
      clearable: false,
      searchable: false,
    };

    return (
      <FormGroup
        fieldName={props.name}
        formGroupClassName="form-group-select form-group-sm"
        context={props.context}
        icons={props.icons}
      >
        {
          props.label
          ? <label
            htmlFor={`${props.name}Field`}
            className="sr-only"
          >{props.label}</label>
          : null
        }
        {
          props.tooltip
          ? <SelectWithTooltip
            {...fieldProps}
            context={props.context}
            tooltip
          />
          : <Form.Field
            {...fieldProps}
            type={Select}
          />
        }
      </FormGroup>);
  }
}

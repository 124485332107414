import serviceLocator from './../base/service/service-locator';
import CrudModel from './../base/models/crud-model';
import NotificationMapper from './services/notification-mapper';
import NotificationConfig from './configs/types';

export default () => {
  serviceLocator.register('NotificationGateway', CrudModel, ['notification'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('NotificationMapper', NotificationMapper, [NotificationConfig]);
};

import React from 'react';
import CallUs from './../../base/components/call-us';
import serviceLocator from './../../base/service/service-locator';

const SessionExpired = () => {
  const jwt = serviceLocator.get('Jwt');
  jwt.set(null);

  return (
    <div className="tab-content platform-tabs">
      <div className="tab-pane fade in active tab-access-denied">
        <h1>Session expired!</h1>
        <p>Sorry your session has been expired.<br />
          <a href="/">Please sign in</a>
        </p>
        <CallUs message="If you have any questions, please call us" />
      </div>
    </div>
  );
};

export default SessionExpired;

import React from 'react';

const NotFound = () => (
  <div className="tab-content platform-tabs">
    <div className="tab-pane fade in active tab-not-found">
      <h1>Page Not Found!</h1>
      <p>Sorry we can't find this page.</p>
      <a href="/">Go to the main page</a>
    </div>
  </div>);

export default NotFound;

import { postMessage } from './'

let getTokenHandler = payload => {
  const payloadToSend = Object.assign({
    query: window.location.search,
    referrer: document.referrer,
  }, payload);
  return postMessage('token.get', payloadToSend);
};
export const setGetTokenHandler = handler => getTokenHandler = handler;
export const getToken = (...params) => getTokenHandler(...params);

let refreshTokenHandler = () => postMessage('token.refresh');
export const setRefreshTokenHandler = handler => refreshTokenHandler = handler;
export const refreshToken = (...params) => refreshTokenHandler(...params);

let signOutHandler = () => postMessage('auth.signout');
export const setSignOutHandler = handler => signOutHandler = handler;
export const signOut = (...params) => signOutHandler(...params);
import React from 'react';

const ReactivateInProgress = () => (
  <div className="reactivate-step reactivate-in-progress">
    <div className="clearfix">
      <div className="col-md-8 col-md-offset-2">
        <h1>Welcome</h1>
        <p>
          You have recently submitted information which we are currently processing.<br/>
          Once your account is active we will send you an email to confirm.
        </p>
      </div>
    </div>
  </div>
);

export default ReactivateInProgress;
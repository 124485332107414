import React from 'react';
import TransferList from './transfer-list';

const TransferHistory = (props) => {
  return (
    <div className="tab-content platform-tabs">
      <section className="tab-pane fade in active tab-transfer-history" id="tab-transfer-history">
        <div className="clearfix">
          <div className="col-md-12 col-md-offset-0">
            <h2 className={(props.headerText === 'Transfer History' ? 'transfer-history' : '')}>{props.headerText}</h2>
            <TransferList headerText={props.headerText} completed={props.completed} isNotFront={props.isNotFront} />
          </div>
        </div>
      </section>
    </div>
  );


};

TransferHistory.propTypes = {
  headerText: React.PropTypes.string,
  completed: React.PropTypes.number,
  isNotFront: React.PropTypes.bool,
};

TransferHistory.defaultProps = {
  headerText: 'Transfer History',
  completed:0,
  isNotFront: false,
};

export default TransferHistory;

import React from 'react';
import CustomEvent from 'custom-event';
import serviceLocator from './../base/service/service-locator';
import RouteConfig from './route-config';

import Reactivate from './../reactivate/components/reactivate';
import TransferHistory from './../transfer/components/transfer-history';
import MakeTransfer from './../transfer/components/make-transfer';
import SendOut from './../payment/components/send-out';
import TradeHistory from './../trade/components/trade-history';
import TradeConfirmation from './../trade/components/trade-confirmation';
import MyDetails from './../user/components/my-details';
import BeneficiaryList from './../beneficiary/components/beneficiary-list';
import AccessDenied from './../app/components/access-denied';
import SessionExpired from './../app/components/session-expired';
import NotFound from './../app/components/not-found';
import StartTrading from './../app/components/start-trading';
import EmailVerification from './../email-verification/components/email-verification';
import Enquiries from './../affiliate/components/enquiries';
import Commissions from './../affiliate/components/commissions';
import ResetPasswordStep1 from './../user/components/reset-password-step1';
import ResetPasswordStep2 from './../user/components/reset-password-step2';
import NewPassword from './../user/components/new-password';
import UploadDocuments from './../upload-documents/components/upload-documents';
import RegistrationPending from './../registration/components/registration-pending';
import Statements from './../statement/components/statements';
import Maintenance from './../app/components/maintenance';

const TransferHistoryCompleted = () => (<TransferHistory headerText={'Completed'} completed={1} isNotFront={true} />);
const TransferHistoryInProgress = () => (<TransferHistory headerText={'In progress'} completed={0} isNotFront={true} />);


export default () => {
  serviceLocator.register('RouteConfig', RouteConfig).dependencies('aclService=AclService');

  const onAclReady = () => {
    // This method should be handled once on initial acl ready
    document.removeEventListener('onAclReady', onAclReady);

    const event = new CustomEvent('onAddRoute');
    document.dispatchEvent(event);

    const routeConfig = serviceLocator.get('RouteConfig');

    routeConfig.addRoute('enquiries', Enquiries, 'affiliate/enquiry/index', 'Enquiries');
    routeConfig.addRoute('commissions', Commissions, 'affiliate/commission/index', 'Commissions');

    // routeConfig.addRoute(
    //   'registration',
    //   Registration,
    //   'client/registration/open-dedicated-dealer-account',
    //   'Registration'
    // );
    routeConfig.addRoute(
      'upload-documents',
      UploadDocuments,
      'client/document/attach-files',
      'Upload Documents'
    );
    routeConfig.addRoute(
      'processing-registration',
      RegistrationPending,
      'client/registration/processing',
      'Processing Registration'
    );

    routeConfig.addRoute('reactivate', Reactivate, 'reactivate/index/index', 'Reactivate Account');

    routeConfig.addRoute(
        'transfer',
        MakeTransfer,
        'transfer/index/get-quote',
        'Make transfer',
        true
    );
    routeConfig.addRoute(
      'transfer-history',
      TransferHistory,
      'transfer/index/index',
      'Transfer history'
    );
    routeConfig.addRoute(
      'transfers-inprogress',
      TransferHistoryInProgress,
      'transfer/index/index',
      'Transfers in progress'
    );
    routeConfig.addRoute(
      'transfers-completed',
      TransferHistoryCompleted,
      'transfer/index/index',
      'Transfers completed'
    );
    routeConfig.addRoute('send-out', SendOut, 'transfer/index/send-out', 'Send out');
    routeConfig.addRoute(
      'beneficiaries',
      BeneficiaryList,
      'beneficiary/index/beneficiarieslist',
      'Beneficiary list'
    );
    routeConfig.addRoute('trade-history', TradeHistory, 'trade/index/history', 'Trade history');
    routeConfig.addRoute(
        'trade-confirmation',
        TradeConfirmation,
        'trade/index/trade-confirmation',
        'Trade confirmation'
    );
    routeConfig.addRoute('statements', Statements, 'client/statements/statements', 'Statements');
    routeConfig.addRoute('my-details', MyDetails, 'user/index/personaldetails', 'Personal details');
    routeConfig.addRoute(
      'email-confirmation',
      EmailVerification,
      'user/index/confirmation',
      'Email confirmation'
    );
    routeConfig.addRoute('deny', AccessDenied, null, 'Access Denied');
    routeConfig.addRoute('expired', SessionExpired, null, 'Session Expired');
    routeConfig.addRoute('not-found', NotFound, null, 'Page Not Found');
    routeConfig.addRoute('forgot-password', ResetPasswordStep1, null, 'Forgot password');
    routeConfig.addRoute('reset-password', ResetPasswordStep2, null, 'Create new password');
    routeConfig.addRoute('new-password', NewPassword, null, 'Create new password');
    routeConfig.addRoute('start-trading', StartTrading, null, 'Start Trading');
    routeConfig.addRoute('maintenance', Maintenance, null, 'Maintenance');

    routeConfig.routeReady();
  };

  document.addEventListener('onAclReady', onAclReady);
};

import React from 'react';
import { browserHistory } from 'react-router';
import AppHeaderTop from './header-top';
import AppHeader from './header';
import AppFooter from './footer';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './form-group';
import FormMessage from './form-message';
import FormTextField from './../../base/components/form-text-field';
import CaptchaField from './../../captcha/form/fields/captcha-field';

export default class AppModalLogin extends FormComponent {
  auth = serviceLocator.get('Auth');
  utils = serviceLocator.get('Utils');
  ga = serviceLocator.get('GA');

  constructor(props) {
    const state = {
      btnDisabled: false,
      isAuthorized: serviceLocator.get('Auth').isAuthorized(),
      captchaRequired: false,
    };

    const schema = {
      login: yup.string().email('Please enter a valid email').required('Please enter your email'),
      password: yup.string().min(6).required('Please enter your password'),
    };

    super(props, state, schema);

    this.captchaVerified = this.captchaVerified.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.isAuthorized !== nextState.isAuthorized && !nextState.isAuthorized) {
      this.utils.setBlurEffect();
    }
  }

  captchaVerified() {
    this.setState({
      captchaRequired: false,
    });
  }

  formOnSubmit = (fields) => {
    this.setState({ btnDisabled: true });

    this._formOnSubmit(fields);

    this.auth.authorize(fields.login, fields.password)
      .then(data => {
        if (data !== true) {
          throw data;
        }
        this.ga.logEvent('Registration', 'Login');
        if (this.utils.isComponentMounted(this)) {
          this.setState({ isAuthorized: true });
        }
        this.utils.removeBlurEffect();
        const { location } = this.props;
        const replaceTo = location && location.state ? location.state.from : '/';
        document.addEventListener('onAclReady', () => {
          browserHistory.replace(replaceTo);
        });
      })
      .catch(failResponse => {
        if (failResponse && failResponse.code === 'RESET_PASSWORD_REQUIRED') {
          location.href = '/new-password';
          return;
        }
        const err = Array.isArray(failResponse) && failResponse[0] || failResponse;
        if (this.utils.isComponentMounted(this)) {
          this.setState({
            captchaRequired: err.code === 'CAPTCHA_FIELD_REQUIRED',
          });

          this.setState({btnDisabled: false});
        }
        if (this.logger.handlePromiseCatch(failResponse)) {
          return;
        }
        let errors;
        if (Array.isArray(failResponse) && failResponse[0] && failResponse[0].message) {
          errors = failResponse;
        } else {
          errors = [{ message: 'Please try again' }];
        }
        this._onFailResponse(errors);
      });
  };

  render() {
    return (
      <div className="login-screen">
        <AppHeaderTop />
        <AppHeader />
        <Form
          ref="form"
          className="form"
          schema={this.schema}
          value={this.state.value}
          errors={this.state.formErrors}
          onError={this.formOnError}
          onChange={this.formOnChange}
          onSubmit={this.formOnSubmit}
        >
          <div>
            <FormGroup
              fieldName="login"
              formGroupClassName="form-group-sm"
              context={this.getChildContext()}
              icons
            >
              <label htmlFor="loginEmail" className="sr-only">Your Email</label>
              <FormTextField
                type="email"
                name="login"
                placeholder="Your Email"
                className="form-control input-sm"
                id="loginEmail"
              />
            </FormGroup>

            <FormGroup
              fieldName="password"
              formGroupClassName="form-group-sm"
              context={this.getChildContext()}
              icons
            >
              <label htmlFor="loginPassword" className="sr-only">Your Password</label>
              <FormTextField
                type="password"
                name="password"
                placeholder="Password"
                className="form-control input-sm"
                id="loginPassword"
              />
            </FormGroup>

            {this.state.captchaRequired ? <CaptchaField onVerify={this.captchaVerified} /> : null}
          </div>

          <div>
            <FormGroup
              fieldName="submit"
              context={this.getChildContext()}
            >
              <Form.Button
                type="submit"
                name="submit"
                className="btn btn-lg btn-block btn-primary"
                disabled={this.state.btnDisabled}
              >
                Login
              </Form.Button>
            </FormGroup>
            <FormMessage context={this.getChildContext()} for="common" />

            <div className="login-links">
              <a href="/forgot-password">I forgot my password</a>
              <a href="/registration">I don't have an account</a>
            </div>
          </div>
        </Form>
        <AppFooter />
      </div>);
  }
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import serviceLocator from './../../base/service/service-locator';
import CallUs from './../../base/components/call-us';

const topMargin = {
  marginTop: '10px',
};

export default class ModalErrorMessage extends React.Component {

  static propTypes = {
    show: React.PropTypes.bool,
    redirectToOnClose: React.PropTypes.string,
    icon: React.PropTypes.string,
    id: React.PropTypes.string,
    title: React.PropTypes.string.isRequired,
    message: React.PropTypes.string,
    closeButtonText: React.PropTypes.string,
    onCloseHandler: React.PropTypes.func,
    children: React.PropTypes.node,
    footer: React.PropTypes.node,
    noCloseButton: React.PropTypes.bool,
    titleText: React.PropTypes.string,
  };
  static defaultProps = {
    id: 'error-message',
    closeButtonText: 'Close',
  };


  constructor(props) {
    super(props);

    this.state = {
      showModal: (typeof this.props.show !== 'undefined') ? this.props.show : true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.show !== 'undefined') {
      this.setState({
        showModal: nextProps.show,
      });
    }
  }

  onClose = (event) => {
    event.preventDefault();
    if (this.props.redirectToOnClose) {
      browserHistory.replace(this.props.redirectToOnClose);
      return;
    }

    this.setState({
      showModal: false,
    }, () => {
      if (this.props.onCloseHandler) {
        this.props.onCloseHandler();
      }
    });
  };

  utils = serviceLocator.get('Utils');

  render() {
    return (
      <Modal
        className={`modal-error-message modal-${this.props.id}`}
        id={`modal${this.props.id}`}
        show={this.state.showModal}
      >
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
          {
            this.props.titleText ?
              <p
                style={topMargin}
                className="text-left"
                dangerouslySetInnerHTML={{ __html: this.props.titleText }}
              /> : null
          }
        </Modal.Header>

        <Modal.Body>
          {
            this.props.icon
            ? <div className="icon-wrap"><div className={this.props.icon}></div></div>
            : null
          }
          {
            this.props.message
            ? <p className="text-center" dangerouslySetInnerHTML={{ __html: this.props.message }} />
            : null
          }
          {
            this.props.children ? this.props.children : <p className="text-center">
              <CallUs message="If you have any questions, please call us" />
            </p>
          }
        </Modal.Body>

        <Modal.Footer>
          {this.props.footer}
          {
            !this.props.noCloseButton ? <button className="btn btn-primary" onClick={this.onClose}>
              {this.props.closeButtonText}
            </button> : null
          }
        </Modal.Footer>
      </Modal>);
  }
}

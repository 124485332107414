import DataModel from './../../base/models/data-model';

export default class UserRoleModel extends DataModel {

  rolesList = [];

  getList(callback) {
    if (this.rolesList.length > 0) {
      if (typeof callback === 'function') {
        return callback(this.rolesList);
      }
      return this.rolesList;
    }

    return this
      .get()
      .then((response) => {
        Object.keys(response.rolesList).forEach(i => {
          this.rolesList.push({
            value: i,
            label: response.rolesList[i],
          });
        });

        if (typeof callback === 'function') {
          return callback(this.rolesList);
        }

        return this.rolesList;
      });
  }

}

import React from 'react';
import { browserHistory } from 'react-router';
import Form from 'react-formal';
import yup from 'yup';
import Select from 'react-select';
import serviceLocator from '../../base/service/service-locator';
import FormGroup from './../../app/components/form-group';
import FormMessage from './../../app/components/form-message';
import FormTextField from './../../base/components/form-text-field';
import FormAmountField from './../../base/components/form-amount-field';
import FormattedCurrency from './../../base/components/formatted-currency';
import SendOutAbstract, { newBeneficiary } from '../../payment/components/send-out-abstract';
import SendOutFieldset, { schema as sendOutSchema } from './send-out-fieldset';
import { schema as beneficiarySchema } from './../../beneficiary/components/beneficiary-form';
import { schema as bankDetailsSchema } from './../../beneficiary/components/bank-details-form';
import Config from 'Config';
import { schema as phoneVerifySchema } from './../../phone-verify/components/phone-verify-form-group';
import Loader from './../../app/components/loader';
import {LOGOUT_ACTION_DEFAULT} from "../../user/models/auth";

export default class SendOut extends SendOutAbstract {

  balancesGateway = serviceLocator.get('BalancesGateway');
  utils = serviceLocator.get('Utils');
  lastEditableTransfersGateway = serviceLocator.get('LastEditableTransfersGateway');
  auth = serviceLocator.get('Auth');

  constructor(props) {
    const state = {
      currencyList: [],
      balancesList: [],
      isFormSubmiting: false,
      dataSubmited: false,
      value: {
        beneficiaryId: newBeneficiary,
        destinationCountryId: Config.defaultCountry,
      },
    };

    const schema = {
      id: yup.number().default(0),
      currency: yup.object(),
      amount: yup.string().required('Enter the amount of Send Out'),
      beneficiaryId: yup.object().default(newBeneficiary),
      ...beneficiarySchema,
      ...sendOutSchema,
      ...bankDetailsSchema,
      ...phoneVerifySchema,
    };

    super(props, state, schema);
  }

  doesFieldTriggerSuccess = (field) => {
    if (field === 'mobile') {
      return false;
    }
    return true;
  };


  loadData() {
    this.setState({
      isLoading: true,
    });

    super.loadData();

    if (this.props.location &&
      this.props.location.query &&
      this.props.location.query.id
    ) {
      this.loadById(parseInt(this.props.location.query.id, 10), ['Submitted', 'New']);
    } else {
      this.loadAllAvailable();
    }
  }

  loadAllAvailable() {
    Promise.all([
      this.lastEditableTransfersGateway.get(),
      this.reasonsForTransferGateway.getList(),
    ]).then(response => {
      this.lastEditableTransfers = response[0];
      this.onChangeCurrency();
    }).catch(error => {
      if (this.logger.handlePromiseCatch(error)) {
        return;
      }
      this.setState({ error });
    });

    this.balancesGateway.get().then(response => {
      const currencies = Object.keys(response).reduce((c, key) => {
        if (parseFloat(response[key].future) > 0) {
          c.push({
            value: response[key].currency,
            label: response[key].currency,
          });
        }

        return c;
      }, []);

      const balances = Object.keys(response).reduce((b, key) => {
        if (parseFloat(response[key].future) > 0) {
          // eslint-disable-next-line no-param-reassign
          b[response[key].currency] = parseFloat(response[key].future);
        }

        return b;
      }, {});

      if (currencies.length) {
        let defaultCurrency = currencies[0];
        if (this.props.location &&
          this.props.location.query &&
          this.props.location.query.currency &&
          balances[this.props.location.query.currency]
        ) {
          defaultCurrency = {
            value: this.props.location.query.currency,
            label: this.props.location.query.currency,
          };
        }

        const newCurrencyValidation = yup.reach(this.schema, 'currency').default(defaultCurrency);
        this.addValidationRules({ currency: newCurrencyValidation });
      }

      this.setState({
        currencyList: currencies,
        balancesList: balances,
        isLoading: false,
      }, () => {
        this.onChangeCurrency();
      });
    }).catch(error => {
      if (this.logger.handlePromiseCatch(error)) {
        return;
      }
      this.setState({ error });
    });
  }

  onChangeCurrency = () => {
    this._onChangeCurrency();
    const editedPi = this.getEditedPi();

    if (!editedPi || !editedPi.value) {
      this.changeMaxAmount();
    } else {
      this.setFieldsValues(editedPi.value, () => {
        this.changeMaxAmount(editedPi.disableAmountField, editedPi.setMaxAmount);
      });
    }
  };

  formOnSubmit = (fields) => {
    const formattedFields = Object.assign({}, fields);

    if ('amount' in formattedFields) {
      formattedFields.amount = this.utils.formatAmount(formattedFields.amount).value;
    }

    this.setState({ isFormSubmiting: true });

    this.formOnSubmitAbstract(formattedFields, () => {
      this.finishFormSubmiting();
      this.setState({
        dataSubmited: true,
      });
    }, () => {
      this.finishFormSubmiting();
    });
  };

  formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    // Prepare handler which will run after setState of value
    const afterValueSetHandler = () => {
      // Run chained onValueUpdateHandler
      if (onValueUpdateHandler) {
        onValueUpdateHandler();
      }
    };

    this.formOnChangeAbstract(value, updatedPaths, afterValueSetHandler);
  };

  onFinishClick = () => {
    if (this.auth.isAuthorizedByToken()) {
      this.auth.logout(LOGOUT_ACTION_DEFAULT);
    }
    browserHistory.replace('/');
  };

  changeMaxAmount(disableAmountField = true, setMaxAmount = true) {
    if (!this.currency ||
      !this.state.balancesList ||
      !this.state.balancesList[this.currency.value]
    ) {
      return;
    }

    const maxAmount = this.state.balancesList[this.currency.value];
    const newFieldValidation = yup.reach(this.schema, 'amount')
      .max(
        maxAmount,
        `Maximum available balance is ${maxAmount} ${this.currency.label}`
      );

    this.changeFieldValidation('amount', newFieldValidation);

    if (setMaxAmount) {
      this.setFieldsValues({
        amount: this.utils.formatAmount(maxAmount).formattedValue,
      });
    }

    const disabledFields = Object.assign({}, this.state.disabledFields);
    if (disableAmountField) {
      disabledFields.amount = true;
    } else if (disabledFields.amount) {
      delete disabledFields.amount;
    }
    this.setState({
      disabledFields,
    });
  }

  finishFormSubmiting() {
    this.setState({ isFormSubmiting: false });
    this.utils.scrollScreenTo();
  }

  renderSuccessMessage() {
    return (
      <div>
        <div className="clearfix">
          <div className="col-sm-8 col-sm-offset-2 send-out-finished">
            <h3>Thank you for submitting your beneficiary details</h3>

            <p>We will now process your transaction.</p>

            <div className="row btn-wrap">
              <div className="col-sm-4 col-sm-offset-4">
                <button
                  type="button"
                  className="btn btn-lg btn-primary btn-block btn-make-new-payment"
                  onClick={this.onFinishClick}
                >Finish</button>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }

  renderForm() {
    return (
      <div className="make-transfer-step make-transfer-step2">
        {
          this.state.error || !this.state.currencyList.length
          ? <div className="col-md-8 col-md-offset-2">
            {
             this.state.error
             ? <div>{this.state.error}</div>
             : <div id="insufficient-funds">
               <h3>You have no available funds to Send Out.</h3>
             </div>
            }
          </div>
          : null
        }

        {
          this.state.currencyList.length && !this.state.error
          ? (<Form
            component="div"
            ref="form"
            schema={this.schema}
            value={this.state.value}
            errors={this.state.formErrors}
            onError={this.formOnError}
            onChange={this.formOnChange}
            onSubmit={this.formOnSubmit}
            className="form clearfix"
            id="send-out-form"
          >
            <div className="col-md-8 col-md-offset-2">
              <FormGroup
                formGroupClassName="form-group-select-currency send-out-amount"
                fieldName="amount"
              >
                <div className="clearfix">
                  <label htmlFor="iHave">I Wish to Send Out</label>

                  <Form.Field
                    type={Select}
                    id="iHave"
                    name="currency"
                    placeholder="..."
                    options={this.state.currencyList}
                    clearable={false}
                    searchable={false}
                  />

                  <FormTextField
                    name="amount"
                    placeholder="Enter amount"
                    type={FormAmountField}
                    className="form-control"
                    disabled={this.state.disabledFields.amount}
                  />

                  <div className="amount-available text-success">
                    <div>
                      Available <FormattedCurrency
                        value={this.state.balancesList[this.state.value.currency.value]}
                        currency={this.state.value.currency.label}
                      />
                    </div>
                  </div>
                </div>

                <FormMessage htmlFor="currency" />
              </FormGroup>

              <div className="make-transfer-beneficiary-wrap">
                <div className="beneficiary-selector form-group row">
                  <label
                    htmlFor="beneficiarySelect"
                    className="label-col"
                  >Existing or Add New Beneficiary</label>

                  <FormGroup formGroupClassName="form-group-select input-col" icons>
                    <Form.Field
                      type={Select}
                      id="beneficiarySelect"
                      name="beneficiaryId"
                      className="input-sm"
                      placeholder="Select Beneficiary"
                      options={this.state.beneficiaryList}
                      clearable={false}
                      searchable={false}
                    />
                  </FormGroup>
                </div>

                <SendOutFieldset showOtherReason={this.state.showOtherReason} />
              </div>

              <div className="send-out-desc row">
                <div className="col-xs-12">
                  {/* eslint-disable max-len */}
                  <strong>Important:</strong> Please double check the beneficiary details are correct before pushing the button below.
                  {/* eslint-enable max-len */}
                </div>
              </div>

              <div className="send-out-errors-wrap row">
                <div className="col-md-6 col-md-offset-3">
                  <FormMessage htmlFor="common" />
                </div>
              </div>

              <div className="make-transfer-beneficiary-btns-wrap row">
                <div className="col-md-6 col-md-offset-3">
                  <Form.Button
                    type="submit"
                    className="btn btn-block btn-lg btn-primary btn-finish-transfer"
                    disabled={this.state.isFormSubmiting}
                  >
                    Send
                  </Form.Button>
                </div>
              </div>

              {this.state.isFormSubmiting ? <Loader /> : null}
            </div>
          </Form>)
          : null
        }
      </div>);
  }

  render() {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active tab-make-transfer" id="tab-make-transfer">
          <header className="clearfix">
          </header>

          {
            this.state.isLoading
            ? <Loader />
            : null
          }

          {
            this.state.dataSubmited
            ? this.renderSuccessMessage()
            : null
          }

          {
            (!this.state.isLoading && !this.state.dataSubmited)
            ? this.renderForm()
            : null
          }
        </section>
      </div>);
  }
}

import React from 'react';
import TradeList from './trade-list';

const TradeHistory = () => (
  <div className="tab-content platform-tabs">
    <section className="tab-pane fade in active tab-trade-history" id="tab-trade-history">
      <div className="clearfix">
        <div className="col-md-8 col-md-offset-2">

          <h2>Trade History</h2>

          <TradeList infiniteEnabled />

        </div>
      </div>
    </section>
  </div>);

export default TradeHistory;

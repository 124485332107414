import React from 'react';
import Form from 'react-formal';
import Select from 'react-select';
import serviceLocator from './../../base/service/service-locator';
import FormGroup from './../../app/components/form-group';

const SelectEnquiryStatus = ({ name, label, className, placeholder, icons }) => {
  // 'NEW','ASSIGNED','LOW_VALUE','NOT_TRADED','NOT_COVERED','SPAM','CYPRUS','CASH','TEST','HANDLED'
  const options = serviceLocator.get('EnquiryStatusModel').getData();

  return (
    <FormGroup
      fieldName={name}
      formGroupClassName="form-group-select"
      icons={icons}
    >
      {
        label
        ? <label
          htmlFor={`${name}Field`}
          className="sr-only"
        >{label}</label>
        : null
      }
      <Form.Field
        type={Select}
        id={`${name}Field`}
        name={name}
        placeholder={placeholder}
        className={className}
        options={options}
        clearable={false}
        searchable={false}
      />
    </FormGroup>);
};
SelectEnquiryStatus.propTypes = {
  name: React.PropTypes.string,
  label: React.PropTypes.string,
  className: React.PropTypes.string,
  placeholder: React.PropTypes.string,
  icons: React.PropTypes.bool,
};

export default SelectEnquiryStatus;

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import InfiniteLoad from './../../base/components/infinite-load';
import FormGroup from './../../app/components/form-group';
import FormComponent from '../../base/components/form-component';
import Loader from './../../app/components/loader';
import ConfirmPopup from './../../app/components/confirm-popup';
import Config from 'Config';

/**
 * User list
 */
export default class BannedIps extends FormComponent {

  constructor(props) {
    const state = {
      page: 1,
      limit: props.limit || 10,
      error: null,
      ipsList: [],
      infiniteLoadingEnabled: true,
      isLoading: true,
      filter: {},
      showConfirmation: false,
    };

    const schema = {
      ip: yup.string(),
      bannedOnly: yup.bool(),
    };

    super(props, state, schema);

    this.bannedIpsGateway = serviceLocator.get('BannedIpsGateway');

    this.onCancelDelete = this.onCancelDelete.bind(this);
    this.deleteIp = this.deleteIp.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.getBannedIpsList();
  }

  getBannedIpsList = () => {
    this.setState({
      isLoading: true,
    });

    this.state.filter.per_page = this.state.limit;
    this.state.filter.page = this.state.page;

    this.bannedIpsGateway
      .get(this.state.filter)
      .then(data => {
        const nextState = {
          ipsList: this.state.ipsList.concat(data.items),
          page: this.state.page + 1,
          isLoading: false,
        };

        if (this.state.page * this.state.limit >= data.count) { // nothing to load
          nextState.infiniteLoadingEnabled = false;
        }

        this.setState(nextState);
      })
      .catch(error => {
        this.setState({
          error,
          infiniteLoadingEnabled: false,
        });
      });
  };

  formOnSubmit = (fields) => {
    this.setState({
      page: 1,
      ipsList: [],
      infiniteLoadingEnabled: true,
      filter: fields,
    }, () => {
      this.getBannedIpsList();
    });
  };

  onDelete = (id) => {
    this.setState({
      showConfirmation: true,
      selectedIpId: id,
    });
  };

  onCancelDelete = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  deleteIp() {
    const id = this.state.selectedIpId;

    this.setState({
      showConfirmation: false,
      isLoading: true,
    });
    this.bannedIpsGateway
      .del(parseInt(id, 10))
      .then(() => {
        const ipsList = this.state.ipsList;

        Object.keys(ipsList).forEach(i => {
          if (ipsList[i].id === id) {
            ipsList.splice(i, 1);
          }
        });

        this.setState({
          ipsList,
          selectedIpId: null,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          selectedEmailId: null,
          isLoading: false,
        });
      });
  }

  renderTable() {
    return (
      <div className="table-responsive">
        <InfiniteLoad
          scrollHandler={this.getBannedIpsList}
          isEnabled={this.state.infiniteLoadingEnabled}
          isLoading={this.state.isLoading}
          bottomOffset={Config.infiniteLoadBottomOffset}
        >
          <table className="table">
            <thead>
              <tr>
                <th>Ip</th>
                <th>Is banned</th>
                <th>Count of tries</th>
                <th>Last try</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.ipsList.map((ip, i) => (
                <tr key={i}>
                  <td>{ip.readable_ip}</td>
                  {
                    ip.is_banned
                    ? <td className="text-danger">Yes</td>
                    : <td className="text-success">No</td>
                  }
                  <td>{ip.badtries}</td>
                  <td>{ip.last_badtry_date}</td>
                  <td className="text-right">
                    <a
                      href="#"
                      className="text-danger delete-ip"
                      onClick={() => this.onDelete(ip.id)}
                    >Delete</a>
                  </td>
                </tr>))
              }
            </tbody>
          </table>
        </InfiniteLoad>
      </div>);
  }

  render() {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active tab-banned-ips" id="tab-banned-ips">
          <div className="clearfix">
            <div className="col-md-10 col-md-offset-1">
              <h2>Blocked Ips</h2>

              <Form
                ref="form"
                component="div"
                schema={this.schema}
                value={this.state.value}
                errors={this.state.formErrors}
                onError={this.formOnError}
                onChange={this.formOnChange}
                onSubmit={this.formOnSubmit}
                className="form form-inline"
              >
                <FormGroup fieldName="ip" icons>
                  <Form.Field
                    name="ip"
                    placeholder="Ip"
                    className="form-control input-sm"
                    id="ip"
                  />
                </FormGroup>
                <FormGroup fieldName="bannedOnly">
                  <div className="checkbox">
                    <label htmlFor="bannedOnly">
                      <Form.Field
                        id="bannedOnly"
                        name="bannedOnly"
                        placeholder="Banned only"
                      />
                      <span className="input"></span>
                      <span>Banned only</span>
                    </label>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Form.Button
                    type="submit"
                    className="btn btn-sm btn-info search-users"
                    disabled={this.state.isLoading}
                  >Search</Form.Button>
                </FormGroup>
              </Form>

              <ReactCSSTransitionGroup
                component="div"
                transitionName="table-responsive"
                transitionEnterTimeout={Config.transitionTimeout.opacity}
                transitionLeaveTimeout={Config.transitionTimeout.opacity}
              >
                {this.state.ipsList.length > 0 ? this.renderTable() : null}
              </ReactCSSTransitionGroup>

              {
                (!this.state.ipsList.length && !this.state.isLoading)
                ? <h6 className="text-center">No results found for your search criteria</h6>
                : null
              }

              {this.state.isLoading ? <Loader /> : null}

              <ConfirmPopup
                text="Are you sure?"
                onConfirm={this.deleteIp}
                onCancel={this.onCancelDelete}
                showModal={this.state.showConfirmation}
              />
            </div>
          </div>
        </section>
      </div>);
  }
}

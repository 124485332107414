import React from 'react';
import AppHeaderTop from './header-top';
import AppHeader from './header';
import AppFooter from './footer';
import Menu from './../../menu/components/menu';
import UpdatableComponent from "../../base/components/updatable-component";

export default class Content extends UpdatableComponent {
  static propTypes = {
    clickHandler: React.PropTypes.func.isRequired,
    mouseMoveHandler: React.PropTypes.func.isRequired,
    logout: React.PropTypes.func.isRequired,
    isAuthorized: React.PropTypes.bool.isRequired,
    children: React.PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  state = {
    expanded: false,
  };

  onToggle = e => {
    if (e && typeof e === 'object') {
      e.preventDefault();
    }

    this.setState(s => ({
      expanded: !s.expanded,
    }))
  };

  onClose = e => {
    if (e) {
      e.preventDefault();
    }

    this.setState(s => ({
      expanded: false,
    }))
  };

  render() {
    const {clickHandler, mouseMoveHandler, logout, isAuthorized, children} = this.props;
    const {expanded} = this.state;

    return (
      <div
        className={`wrapper-outer${expanded ? ' menu-expanded' : ''}`}
        onClick={clickHandler}
        onMouseMove={mouseMoveHandler}
      >
        <AppHeaderTop />
        <AppHeader logoutHandler={logout} isAuthorized={isAuthorized} onToggle={this.onToggle} onClose={this.onClose} expanded={expanded} />
        <div className="wrapper" id="wrapper">
          <div className={'content'}>
            <div className="container">
              <Menu />
              {children}
            </div>
          </div>
        </div>
        <AppFooter />
      </div>
    );
  }
};

import React from 'react';
import { Navbar } from 'react-bootstrap';
import ComponentHeaderUser from './../../user/components/header-user';
import Config from 'Config';

const AppHeader = ({ isAuthorized, logoutHandler, expanded, onToggle, onClose }) => (
  <Navbar bsStyle="default" fixedTop expanded={expanded} onToggle={onToggle}>
    <Navbar.Header>
      {isAuthorized
        ? <Navbar.Toggle />
        : null
      }
      <Navbar.Brand>
        <a href={Config.website.origin}>CURRENCY SOLUTIONS</a>
      </Navbar.Brand>
    </Navbar.Header>

    <Navbar.Collapse>
      <div className="navbar-right">
        {isAuthorized
          ? <ComponentHeaderUser logoutHandler={logoutHandler} onClose={onClose} />
          : null
        }
      </div>
    </Navbar.Collapse>
  </Navbar>
);
AppHeader.propTypes = {
  isAuthorized: React.PropTypes.bool.isRequired,
  logoutHandler: React.PropTypes.func,
  expanded: React.PropTypes.bool,
  onToggle: React.PropTypes.func,
  onClose: React.PropTypes.func,
};
AppHeader.defaultProps = {
  isAuthorized: false,
};

export default AppHeader;

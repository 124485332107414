import React from 'react';

export default class RadioInput extends React.Component {
  static propTypes = {
    value: React.PropTypes.string,
    optionValue: React.PropTypes.oneOfType([
      React.PropTypes.number,
      React.PropTypes.string,
    ]),
    name: React.PropTypes.string,
    id: React.PropTypes.string,
    onChange: React.PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({ value: nextProps.value.toString() });
    }
  }

  _onChange = e => {
    const val = e.target.value;
    const current = this.state.value;

    if (val !== current && this.props.onChange) {
      return this.props.onChange(val);
    }

    return false;
  };

  render() {
    const { optionValue, ...otherProps } = this.props;
    const filteredOtherProps = Object.keys(otherProps).reduce((returnValue, key) => {
      const changedReturnValue = returnValue;
      if (key !== 'meta' && key !== 'context' && key !== 'type') {
        changedReturnValue[key] = otherProps[key];
      }
      return changedReturnValue;
    }, {});

    return (
      <input
        type="radio"
        {...filteredOtherProps}
        value={optionValue}
        checked={(optionValue === this.state.value)}
        onChange={this._onChange}
      />
    );
  }

}

import React from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import FormMessage from './../../app/components/form-message';
import FormGroup from './../../app/components/form-group';
import FormComponent from './../../base/components/form-component';
import FormTextFieldWithTooltip from './../../base/components/form-text-field-with-tooltip';
import SelectWithTooltip from './../../base/components/select-with-tooltip';
import SelectUserRole from './../form/fields/select-user-role';
import Loader from './../../app/components/loader';

const defaultHiddenFields = () => ({
  password: true,
  passwordRepeat: true,
  expiryDate: true,
  ipWhiteList: true,
  regId: true,
  clientId: true,
  jwtToken: true,
  serviceProviderId: true,
  serviceProviderHash: true,
});
const defaultServiceProvider = { label: 'Online Platform', value: 0 };

export default class UserForm extends FormComponent {

  constructor(props) {
    const state = {
      userId: props.userId,
      title: props.userId ? 'Edit user' : 'Add new user',
      createdDate: props.userId ? '' : false,
      user: null,
      isLoading: false,
      clientName: '',
      hiddenFields: defaultHiddenFields(),
      serviceProviders: [defaultServiceProvider],
    };

    const schema = {
      firstName: yup.string().required('Enter user first name'),
      lastName: yup.string().required('Enter user last name'),
      email: yup.string().required('Enter user email'),
      role: yup.string().required('Select user role'),
      password: yup.string().min(6),
      passwordRepeat: yup.string().min(6)
          .test('passwordEquals', 'Passwords don\'t match', function passwordEqualsTest(value) {
            return (this.parent.password === value);
          }),
      clientId: yup.string(),
      regId: yup.string(),
      expiryDate: yup.string().typeError('Please enter a valid date'),
      ipWhiteList: yup.string(),
      serviceProviderId: yup.object().default(defaultServiceProvider),
      serviceProviderHash: yup.string(),
      jwtToken: yup.string(),
    };

    super(props, state, schema);

    this.userGateway = serviceLocator.get('AdminUserGateway');
    this.utilsDate = serviceLocator.get('UtilsDate');
    this.adminUserCSMSNameGateway = serviceLocator.get('AdminUserCSMSNameGateway');
    this.serviceProvidersGateway = serviceLocator.get('ServiceProvidersGateway');
    this.utils = serviceLocator.get('Utils');
  }

  static propTypes = {
    show: React.PropTypes.bool,
    userId: React.PropTypes.string,
    onClose: React.PropTypes.func,
    onSaveUser: React.PropTypes.func,
  };

  /**
   * Init
   * @param {Object} props
   */
  componentWillReceiveProps(props) {
    this.setState({
      userId: props.userId,
      title: props.userId ? 'Edit user' : 'Add new user',
      createdDate: props.userId ? '' : false,
    });
    if (!props.userId) {
      this.setState({
        hiddenFields: defaultHiddenFields(),
      });
    }

    if (props.userId && this.props.userId !== props.userId) {
      this.loadUserInfo(props.userId);
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.serviceProvidersGateway
      .getList()
      .then(response => {
        const serviceProviders = [defaultServiceProvider];

        response.forEach(user => {
          const u = {
            value: +user.client_id,
            label: `${user.first_name} ${user.last_name}`,
          };

          serviceProviders.push(u);
        });

        this.setState({
          serviceProviders,
        });
      });
  }

  getVisibleFieldsByRole = () => ({
    pending: [
      'password', 'passwordRepeat', 'regId',
    ],
    affiliate: [
      'password', 'passwordRepeat', 'clientId',
    ],
    'new-client': [
      'password', 'passwordRepeat', 'clientId', 'regId',
    ],
    'trade-client': [
      'password', 'passwordRepeat', 'clientId', 'regId',
    ],
    'api-client': [
      'expiryDate', 'ipWhiteList', 'clientId', 'jwtToken', 'serviceProviderId',
    ],
    'service-provider': [
      'expiryDate', 'ipWhiteList', 'clientId', 'jwtToken', 'serviceProviderHash',
    ],
    client: [
      'password', 'passwordRepeat', 'clientId', 'regId',
    ],
    manager: [
      'password', 'passwordRepeat',
    ],
    admin: [
      'password', 'passwordRepeat',
    ],
    suspended: [
      'password', 'passwordRepeat', 'clientId', 'regId',
    ],
  });

  onBlurClientId = (event) => {
    this.updateUserNameCSMS(event.target.value);
  };

  formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    const processedValue = Object.assign({}, value);
    // If we select role
    if (updatedPaths.indexOf('role') !== -1) {
      const roleValue = value.role.value || value.role;
      processedValue.role = roleValue;

      const hiddenFields = defaultHiddenFields();

      if (this.getVisibleFieldsByRole()[roleValue] !== undefined) {
        this.getVisibleFieldsByRole()[roleValue].forEach(field => {
          hiddenFields[field] = false;
        });
        if (value.jwtToken) {
          hiddenFields.jwtToken = false;
        }
      }

      this.setState({
        hiddenFields,
      });
    }
    this._formOnChange(processedValue, updatedPaths, onValueUpdateHandler);
  };

  /**
   * Save user
   * @param {Object} fields
   */
  formOnSubmit = (fields) => {
    const requestData = Object.assign({}, fields);
    if (this.state.userId) {
      requestData.id = this.state.userId;
    }
    if (requestData.expiryDate) {
      requestData.expiryDate = this.utilsDate.getISODate(requestData.expiryDate);
    }
    this._formOnSubmit(requestData);

    this.setState({
      isLoading: true,
    });

    let promise;
    if (this.state.userId) {
      promise = this.userGateway.put(requestData);
    } else {
      promise = this.userGateway.post(requestData);
    }

    promise
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.onClose();
        requestData.userId = response.user.id;
        this.props.onSaveUser(requestData);
      })
      .catch((failResponse) => {
        this.setState({
          isLoading: false,
        });
        this._onFailResponse(failResponse);
      });
  };

  onRenewToken = () => {
    if (!this.state.userId) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    const requestData = {
      id: this.state.userId,
      renewToken: true,
    };
    this.userGateway.put(requestData)
      .then(() => {
        this.loadUserInfo(this.state.userId);
      })
      .catch((failResponse) => {
        this.setState({
          isLoading: false,
        });
        this._onFailResponse(failResponse);
      });
  };

  /**
   * Get user details and open form to view/edit user
   *
   * @param {int} userId
   */
  loadUserInfo = (userId) => {
    this.setState({
      isLoading: true,
    });

    this.userGateway
      .get({
        id: userId,
      })
      .then((response) => {
        this.setState({ createdDate: response.user.created_date });
        let serviceProviderId = defaultServiceProvider;
        this.state.serviceProviders.map(sp => {
          if (sp.value === +response.user.service_provider_id) {
            serviceProviderId = sp;
          }
        });
        const formData = {
          role: response.user.role,
          email: response.user.email,
          firstName: response.user.first_name,
          lastName: response.user.last_name,
          clientId: response.user.client_id,
          regId: response.user.reg_id,
          ipWhiteList: response.user.ip_white_list,
          jwtToken: response.user.jwtToken,
          serviceProviderId,
          serviceProviderHash: response.user.service_provider_hash,
        };

        if (response.user.expiry_date) {
          formData.expiryDate = this.utilsDate.getFormatedDate(
            new Date(response.user.expiry_date),
            'DD/MM/YYYY'
          );
        }

        this.setFieldsValues(formData, () => {
          this.setState({
            isLoading: false,
          });
        });
        this.updateUserNameCSMS(response.user.client_id);
      });
  };

  updateUserNameCSMS = (userClientId) => {
    this.adminUserCSMSNameGateway.get({ clientId: userClientId }).then((data) => {
      if (data.name !== undefined) {
        this.setState({
          clientName: data.name,
        });
      }
    })
    .catch(failResponse => {
      if (failResponse[0] !== undefined) {
        this.setState({
          clientName: failResponse[0].message,
        });
      }
    });
  }
  /**
   * Close user form popup
   */
  onClose = () => {
    this.clearFieldsValues();
    this.props.onClose();
  };

  renderForm() {
    const context = this.getChildContext();

    return (
      <div>
        {this.state.createdDate ? <p>Created date: {this.state.createdDate}</p> : ''}

        <div className={'row'}>
          <div className={'col-sm-12 col-md-6'}>
            <SelectUserRole
              className="input-sm"
              name="role"
              label="User Role"
              placeholder="User role"
              context={this.getChildContext()}
              tooltip
            />
          </div>

          <div className={this.state.hiddenFields.clientId ? '' : 'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="clientId"
              context={context}
              icons
              formGroupClassName="form-group-sm"
              hidden={this.state.hiddenFields.clientId}
            >
              <label htmlFor="clientId" className="sr-only">Client ID</label>
              <FormTextFieldWithTooltip
                name="clientId"
                placeholder="Client ID"
                className="form-control input-sm"
                id="clientId"
                onBlur={this.onBlurClientId}
                context={context}
              />
              {
                this.state.clientName
                  ? <small className="text-muted">{this.state.clientName}</small>
                  : null
              }
            </FormGroup>
          </div>

          <div className={'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="firstName"
              context={context}
              icons
              formGroupClassName="form-group-sm"
            >
              <label htmlFor="firstName" className="sr-only">First Name</label>
              <FormTextFieldWithTooltip
                name="firstName"
                placeholder="First Name"
                className="form-control input-sm"
                id="firstName"
                context={context}
              />
            </FormGroup>
          </div>

          <div className={'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="lastName"
              context={context}
              icons
              formGroupClassName="form-group-sm"
            >
              <label htmlFor="lastName" className="sr-only">Last Name</label>
              <FormTextFieldWithTooltip
                name="lastName"
                placeholder="Last Name"
                className="form-control input-sm"
                id="lastName"
                context={context}
              />
            </FormGroup>
          </div>

          <div className={'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="email"
              context={context}
              icons
              formGroupClassName="form-group-sm"
            >
              <label htmlFor="email" className="sr-only">Email</label>
              <FormTextFieldWithTooltip
                name="email"
                placeholder="Email"
                className="form-control input-sm"
                id="email"
                context={context}
              />
            </FormGroup>
          </div>

          <div className={this.state.hiddenFields.serviceProviderId ? '' : 'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="serviceProviderId"
              context={context}
              formGroupClassName="form-group-select form-group-sm"
              hidden={this.state.hiddenFields.serviceProviderId}
            >
              <label htmlFor="serviceProviderId" className="sr-only">Service Provider</label>
              <SelectWithTooltip
                name="serviceProviderId"
                placeholder="Service Provider"
                className="input-sm"
                id="serviceProviderId"
                context={context}
                options={this.state.serviceProviders}
                tooltip
                clearable={false}
                searchable={false}
              />
            </FormGroup>
          </div>

          <div className={this.state.hiddenFields.regId ? '' : 'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="regId"
              context={context}
              icons
              formGroupClassName="form-group-sm"
              hidden={this.state.hiddenFields.regId}
            >
              <label htmlFor="regId" className="sr-only">Registration ID</label>
              <FormTextFieldWithTooltip
                name="regId"
                placeholder="Registration ID"
                className="form-control input-sm"
                id="regId"
                context={context}
              />
            </FormGroup>
          </div>

          <div className={this.state.hiddenFields.password ? '' : 'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="password"
              context={context}
              icons
              formGroupClassName="form-group-sm"
              hidden={this.state.hiddenFields.password}
            >
              <label htmlFor="password" className="sr-only">Password</label>
              <FormTextFieldWithTooltip
                name="password"
                type="password"
                placeholder="Password"
                className="form-control input-sm"
                id="password"
                context={context}
              />
            </FormGroup>
          </div>

          <div className={this.state.hiddenFields.passwordRepeat ? '' : 'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="passwordRepeat"
              context={context}
              icons
              formGroupClassName="form-group-sm"
              hidden={this.state.hiddenFields.passwordRepeat}
            >
              <label htmlFor="passwordRepeat" className="sr-only">Password</label>
              <FormTextFieldWithTooltip
                name="passwordRepeat"
                type="password"
                placeholder="Repeat password"
                className="form-control input-sm"
                id="passwordRepeat"
                context={context}
              />
            </FormGroup>
          </div>

          <div className={this.state.hiddenFields.expiryDate ? '' : 'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="expiryDate"
              context={context}
              icons
              formGroupClassName="form-group-sm"
              hidden={this.state.hiddenFields.expiryDate}
            >
              <label htmlFor="expiryDate" className="sr-only">Expiry Date</label>
              <FormTextFieldWithTooltip
                type="text"
                name="expiryDate"
                placeholder="Expiry Date DD/MM/YYYY"
                className="form-control input-sm"
                id="expiryDate"
                context={context}
              />
            </FormGroup>
          </div>

          <div className={this.state.hiddenFields.ipWhiteList ? '' : 'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="ipWhiteList"
              context={context}
              icons
              formGroupClassName="form-group-sm"
              hidden={this.state.hiddenFields.ipWhiteList}
            >
              <label htmlFor="ipWhiteList" className="sr-only">IP white list</label>
              <FormTextFieldWithTooltip
                name="ipWhiteList"
                type="textarea"
                placeholder="IP white list"
                className="form-control input-sm input-textarea"
                id="ipWhiteList"
                context={context}
              />
            </FormGroup>
          </div>

          <div className={this.state.hiddenFields.serviceProviderHash ? '' : 'col-sm-12 col-md-6'}>
            <FormGroup
              fieldName="serviceProviderHash"
              context={context}
              icons
              formGroupClassName="form-group-sm"
              hidden={this.state.hiddenFields.serviceProviderHash}
            >
              <label htmlFor="serviceProviderHash" className="sr-only">Service Provider Hash</label>
              <FormTextFieldWithTooltip
                name="serviceProviderHash"
                type="textarea"
                placeholder="Service Provider Hash"
                className="form-control input-sm input-textarea"
                id="serviceProviderHash"
                context={context}
              />
            </FormGroup>
          </div>
        </div>

        <div className={'row'}>
          <div className={this.state.hiddenFields.jwtToken ? '' : 'col-sm-12'}>
            <FormGroup
              fieldName="jwtToken"
              context={context}
              icons
              formGroupClassName="form-group-sm"
              hidden={this.state.hiddenFields.jwtToken}
            >
              <label htmlFor="jwtToken" className="sr-only">JWT Token</label>
              <FormTextFieldWithTooltip
                name="jwtToken"
                type="textarea"
                placeholder="JWT Token"
                className="form-control input-sm input-textarea"
                id="jwtToken"
                context={context}
              />
            </FormGroup>
          </div>
        </div>

      </div>);
  }

  render() {
    return (
      <Modal
        className="modal-login user-form"
        show={this.props.show}
        animation={false}
        bsSize="small"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className="navbar-brand">CURRENCY SOLUTIONS</div>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>

        <Form
          ref="form"
          component="div"
          className="form"
          schema={this.schema}
          value={this.state.value}
          errors={this.state.formErrors}
          onError={this.formOnError}
          onChange={this.formOnChange}
          onSubmit={this.formOnSubmit}
        >
          <Modal.Body>
            {this.state.isLoading ? <Loader /> : this.renderForm()}
          </Modal.Body>

          <Modal.Footer>
            <FormGroup
              formGroupClassName="form-group-btn-submit"
              context={this.getChildContext()}
            >
              <FormMessage context={this.getChildContext()} htmlFor="common" />

              <div className={'row'}>

                {
                  (!this.state.hiddenFields.jwtToken && this.state.userId)
                  ? <div className={'col-sm-12 col-md-6'}>
                      <Form.Button
                        type="button"
                        className="btn btn-primary btn-inline btn-reset-password"
                        onClick={this.onRenewToken}
                      >Renew</Form.Button>
                    </div>
                  : null
                }

                <div className={!this.state.hiddenFields.jwtToken && this.state.userId ? 'col-sm-12 col-md-6' : 'col-sm-12'}>
                  <Form.Button
                    type="submit"
                    className="btn btn-primary btn-inline btn-reset-password"
                  >Save</Form.Button>
                </div>
              </div>

              <div className={'row'}>
                <div className={'col-sm-12'}>
                  <Form.Button
                    type="button"
                    className="btn btn-inline btn-link top-mg"
                    onClick={this.onClose}
                  >Cancel</Form.Button>
                </div>
              </div>
            </FormGroup>
          </Modal.Footer>
        </Form>
      </Modal>);
  }
}

import { setIclaus, onIclausReady, onMessage } from './..';

const styles = 'position: absolute; opacity: 0; border: 0px; width: 1px; height: 1px; bottom: 0px';

const IclausLegacy = (url, origins) => {
  window.addEventListener('message', onMessage(origins), false);

  const n = document.createElement('iframe');
  n.id = 'iclaus';
  n.style.cssText = styles;
  n.src = url;
  n.onload = onIclausReady;
  document.getElementsByTagName('body')[0].appendChild(n);

  setIclaus(n.contentWindow);
};

export default IclausLegacy;
import React from 'react';
import yup from 'yup';
import FormGroup from './../../../app/components/form-group';
import FormTextField from './../../../base/components/form-text-field';
import FormTextFieldWithTooltip from './../../../base/components/form-text-field-with-tooltip';

const DatePicker = (props) => {
  const formGroupClassName = props.formGroupClassName ? props.formGroupClassName : '';
  const fieldProps = {
    type: 'text',
    id: `${props.name}Field`,
    name: props.name,
    placeholder: props.placeholder,
    className: props.className,
  };
  let label = null;
  let field;

  if (props.label) {
    label = <label htmlFor={`${props.name}Field`} className="sr-only">{props.label}</label>;
  }

  if (props.fieldWithTooltip) {
    field = <FormTextFieldWithTooltip {...fieldProps} />;
  } else {
    field = <FormTextField {...fieldProps} />;
  }

  return (
    <FormGroup
      fieldName={props.name}
      formGroupClassName={`${formGroupClassName}`}
      icons={props.icons}
      context={props.context}
      hidden={props.hidden}
    >
      {label}
      {field}
    </FormGroup>
  );
};

DatePicker.propTypes = {
  name: React.PropTypes.string.isRequired,
  label: React.PropTypes.string,
  className: React.PropTypes.string,
  formGroupClassName: React.PropTypes.string,
  placeholder: React.PropTypes.string,
  icons: React.PropTypes.bool,
  fieldWithTooltip: React.PropTypes.bool,
  context: React.PropTypes.object,
  hidden: React.PropTypes.bool,
};

export default DatePicker;
export const schema = yup.date().typeError('Please enter a valid date');

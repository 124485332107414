import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import serviceLocator from '../../base/service/service-locator';
import Config from 'Config';
import UpdatableComponent from './../../base/components/updatable-component';
import FormattedCurrency from './../../base/components/formatted-currency';
import CardPaymentForm from './card-payment-form';
import TermsAndConditions from './../../terms-and-conditions/components/terms-and-conditions';

export const TYPE_DEBIT = 'DB';
export const TYPE_CREDIT = 'CR';
export const TYPE_TRANSFER = 'TRANSFER';
export const METHOD_DEBIT = 'debit_card';
export const METHOD_BANK = 'bank_transfer';
const SUCCESS_PAYMENT_RESPONSE = 'success';

export default class Payment extends UpdatableComponent {

  static propTypes = {
    currency: React.PropTypes.string,
    buyCurrency: React.PropTypes.string,
    amount: React.PropTypes.number,
    changeCardType: React.PropTypes.func.isRequired,
    onAgreementApprove: React.PropTypes.func.isRequired,
    agreementApproved: React.PropTypes.bool,
    showAgreementWarning: React.PropTypes.bool,
    showCardOptions: React.PropTypes.bool,
    cardType: React.PropTypes.string.isRequired,
    quoteValues: React.PropTypes.object,
    cardStatus: React.PropTypes.string,
  };

  static defaultProps = {
    cardType: TYPE_DEBIT,
  };

  static contextTypes = {
    submitPaymentForm: React.PropTypes.func,
    setPaymentResult: React.PropTypes.func,
    onPaymentFailedResponse: React.PropTypes.func,
  };

  state = {
    showTermsAndConditions: false,
  };

  logger = serviceLocator.get('Logger');
  auth = serviceLocator.get('Auth');
  statusCardTransactionGateway = serviceLocator.get('StatusCardTransactionGateway');

  componentDidMount() {
    if (this.props.cardStatus && this.props.cardStatus !== '') {
      const handler = this.props.cardStatus === SUCCESS_PAYMENT_RESPONSE ? this.context.submitPaymentForm : this.context.onPaymentFailedResponse;
      handler(this.props.cardStatus);

      this.processCardStatus(this.props.cardStatus)();
    }
  }

  processCardStatus = status => () => {
    if (status === SUCCESS_PAYMENT_RESPONSE && this.props.quoteValues) {
      const {buyCurrency, sellCurrency} = this.props.quoteValues;
      const params = Object.assign({},
        {
          buyCurrency,
          sellCurrency,
        });

      this.statusCardTransactionGateway
        .post(params)
        .then(response => {
          if (response && response.status === 'SENT_FOR_AUTHORISATION') {
            setTimeout(this.processCardStatus(status), 5000);

            return;
          }

          if (
            response && parseInt(response.id) > 0 &&
            [
              'AUTHORIZED',
              'AUTHORISED',
            ].includes(response.status)
          ) {
            this.context.setPaymentResult(response);

            return;
          }

          this.context.onPaymentFailedResponse(response);

        })
        .catch(failResponse => {
          if (this.logger.handlePromiseCatch(failResponse)) {
            return;
          }

          this.context.onPaymentFailedResponse(failResponse);
        });
    }
  };



  onTypeChange = (event) => {
    this.props.changeCardType(event.target.value);
  };

  hasDebitCardPayment() {
    const { paymentMethods } = this.props.quoteValues;
    return paymentMethods.indexOf(METHOD_DEBIT) !== -1;
  }

  hasBankTransferPayment() {
    const { paymentMethods } = this.props.quoteValues;
    return paymentMethods.indexOf(METHOD_BANK) !== -1;
  }

  showTermsAndConditions = (e) => {
    e.preventDefault();
    this.setState({
      showTermsAndConditions: true,
    });
  };

  hideTermsAndConditions = () => {
    this.setState({
      showTermsAndConditions: false,
    });
  };

  renderCardPaymentForm() {
    const { agreementApproved } = this.props;

    if (!agreementApproved) {
      return null;
    }

    return (
      <CardPaymentForm
        currency={this.props.currency}
        buyCurrency={this.props.buyCurrency}
        amount={this.props.amount}
        type={this.props.cardType}
        quoteValues={this.props.quoteValues}
      />
    );
  }

  renderBankTransfer() {
    return (
      <div className="payment-type-transfer-wrapper">
        <h1><span>Please note</span></h1>
        <ul>
          <li><span>Our bank details will be provided once the transaction
            has been confirmed. This may change depending on
            the currency being transacted.</span></li>
          <li><span>We do not accept cash payments under any
            circumstances.</span></li>
          <li><span>The name on your bank account must be the same as
            your Currency Solutions account. We may need further
            documentation for Third Party funds to clear before a
            trade can be processed.</span></li>
        </ul>
      </div>
    );
  }

  renderPaymentVariants() {
    const { showCardOptions, amount, currency, onAgreementApprove, cardType, showAgreementWarning } = this.props;

    return (
      <div className="">
        <div className="form-group">
          <div className="row row-card-type text-center">
            {showCardOptions && this.hasDebitCardPayment() ?
              (
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      name="cardType"
                      value={TYPE_DEBIT}
                      onChange={this.onTypeChange}
                      checked={this.props.cardType === TYPE_DEBIT}
                    />
                    <span className="input"/>
                    <span>Debit Card</span>
                  </label>
                </div>
              ) : null
            }

            {this.hasBankTransferPayment() ?
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    name="cardType"
                    value={TYPE_TRANSFER}
                    onChange={this.onTypeChange}
                    checked={cardType === TYPE_TRANSFER}
                  />
                  <span className="input"></span>
                  <span>Bank Transfer</span>
                </label>
              </div> : null
            }

            {
              cardType === TYPE_CREDIT
                ? (
                  <div className="label-col card-type-desc">
                    <strong
                      className="text-primary"
                    >A 2.5% fee applies for payments by credit cards.</strong>
                    The total amount payable by credit card for this transaction will be
                    <strong>
                      <FormattedCurrency
                        value={amount}
                        currency={currency}
                      />
                    </strong><br/>
                    <strong
                      className="text-primary"
                    >Please note:</strong>
                    Your credit card issuer may charge a cash advance fee,
                    which could be up to 5%. Currency Solutions are not liable for these charges.
                  </div>
                )
                : null
            }
          </div>
        </div>

        <div className="clearfix btn-wrap">
          <div className="form-group checkbox text-center">
            <label>
              <input
                type="checkbox"
                name="agreement"
                onChange={onAgreementApprove}
                checked={this.state.agreementApproved}
              />
              <span className="input"/>
              <span>I agree to your <a
                href="#"
                onClick={this.showTermsAndConditions}
              >Terms and Conditions</a></span>
            </label>
          </div>

          <ReactCSSTransitionGroup
            component="div"
            transitionName="form-group-message"
            transitionEnterTimeout={Config.transitionTimeout.opacity}
            transitionLeaveTimeout={Config.transitionTimeout.opacity}
          >
            {
              showAgreementWarning ?
                <div className="form-group-message alert-danger">
                  Please agree with the Terms and Conditions
                </div> : null
            }
          </ReactCSSTransitionGroup>

          <TermsAndConditions
            show={this.state.showTermsAndConditions}
            close={this.hideTermsAndConditions}
          />
        </div>

        {
          cardType === TYPE_TRANSFER
            ? this.renderBankTransfer()
            : this.renderCardPaymentForm()
        }
      </div>
    );
  }

  render() {
    const { cardStatus } = this.props;

    return (
      <div className="clearfix">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 make-transfer-payment-container">
            <div className="make-transfer-payment-form">
              <div className="clearfix">
                {!cardStatus || cardStatus === ''
                  ? <h3>Payment Method</h3>
                  : <h3>Processing card payment...</h3>
                }

              </div>

              <div className="make-transfer-card-details clearfix">
                {!cardStatus || cardStatus === ''
                  ? this.renderPaymentVariants()
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
}

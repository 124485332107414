const MenuConfig = {
  tabs: [
    {
      id: 'reactivate',
      label: 'Reactivate Account',
      route: 'reactivate',
    },
    {
      id: 'make-transfer',
      label: 'Transfer Currency',
      route: 'transfer',
    },
    {
      id: 'transfer-history',
      label: 'Transfer History',
      route: 'transfer-history',
    },
    {
      id: 'beneficiaries',
      label: 'Recipients',
      route: 'beneficiaries',
    },
    {
      id: 'statements',
      label: 'Statements',
      route: 'statements',
    },
    {
      id: 'affiliate-enquiries',
      label: 'Enquiries',
      route: 'enquiries',
    },
    {
      id: 'affiliate-commissions',
      label: 'Commissions',
      route: 'commissions',
    },
    {
      id: 'admin-users',
      label: 'Users',
      route: 'admin-users',
    },
    {
      id: 'admin-banned-ips',
      label: 'Blocked IPs',
      route: 'admin-banned-ips',
    },
    {
      id: 'admin-banned-emails',
      label: 'Blocked Emails',
      route: 'admin-banned-emails',
    },
    {
      id: 'upload-documents',
      label: 'Upload Documents',
      route: 'upload-documents',
    },
    {
      id: 'my-details',
      label: 'My Details',
      route: 'my-details',
    },
  ],
};

export default MenuConfig;

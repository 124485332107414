import React from 'react';
import serviceLocator from './../../base/service/service-locator';
import Loader from './../../app/components/loader';
import UpdatableComponent from "../../base/components/updatable-component";
import Balances from './../../statement/components/balances';
import TransferListShort from "./transfer-list-short";

export default class QuotesInProgress extends UpdatableComponent {
  auth = serviceLocator.get('Auth');

  state = {
    isLoading: true,
    user: null,
  };

  componentDidMount() {
    if (this.auth.isUserDetailsLoaded()) {
      this.onCurrentUserLoaded();
    } else {
      this.auth.addListener('onCurrentUserLoaded', () => { this.onCurrentUserLoaded(); });
    }
  }

  onCurrentUserLoaded() {
    this.setState({
      isLoading: false,
      user: this.auth.getCurrentUser(),
    });
  }

  render() {
    const { isLoading, user } = this.state;

    if (isLoading) {
      return (
        <div className="tab-content platform-tabs">
          <section className="tab-pane fade in active tab-my-details" id="tab-my-details">
            <Loader />
          </section>
        </div>
      );
    }

    return (
      <div className="quote-in-progress clearfix">
        <div className="row">
          <div className="col-md-12 user-header">
            <img src="/images/icon-user.svg" />
            <div className="user-details">
              <div className="user-name">{user.name}</div>
              <div className="user-email">{user.email}</div>
            </div>
          </div>
        </div>
        <div className="account-balances">
          <div className="row">
            <div className="col-md-6 my-details-accnr">
              <label>Account Number:</label>
              <div>{user.clientSignedId ? user.clientSignedId : user.clientId}</div>
            </div>
            <div className="col-md-6">
              <label>Available funds:</label>
              <Balances />
            </div>
          </div>
        </div>

        <div className="in-progress-transfers">
          <TransferListShort />
        </div>
      </div>
    );
  }
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator.js';

const ModalCallUs = ({ showModal, title, subtitle, onClose }) => {
  const { setBlurEffect, removeBlurEffect } = serviceLocator.get('Utils');
  return (
    <Modal
      className="modal-call-us"
      show={showModal}
    >
      <Modal.Body>
        <div className="icon-wrap modal-icon-call-wrap">
          <div className="icon-call"></div>
        </div>

        <h4 className="modal-title">
          {title || 'Please call us'}<br />
          {
            subtitle
            ? <small>{subtitle}</small>
            : null
          }
          <br />
          <a href="tel:+442077400000">+44 20 7740 0000</a>
        </h4>
      </Modal.Body>

      <Modal.Footer>
        <a
          href="#"
          onClick={
            (e) => {
              e.preventDefault();
              onClose();
            }
          }
          className="btn-modal-close"
        >Close</a>
      </Modal.Footer>
    </Modal>);
};
ModalCallUs.propTypes = {
  showModal: React.PropTypes.bool.isRequired,
  title: React.PropTypes.string.isRequired,
  subtitle: React.PropTypes.string,
  onClose: React.PropTypes.func,
};

export default ModalCallUs;

import serviceLocator from './../base/service/service-locator';
import CrudModel from './../base/models/crud-model';
import UserRolesModel from './models/user-role-model';
import UserList from './../admin/components/user-list';
import BannedIps from './../admin/components/banned-ips';
import BannedEmails from './../admin/components/banned-emails';
import DataModel from './../base/models/data-model.js';

export default () => {
  serviceLocator.register('AdminUserGateway', CrudModel, ['admin/users'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('AdminUserCSMSNameGateway', CrudModel, ['admin/user-csms-name'])
      .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('ServiceProvidersGateway', CrudModel, ['admin/service-providers'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('ResetPasswordStep1Gateway', DataModel, ['reset-password-step1'])
      .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('ResendEmailConfirmationGateway',
        DataModel,
        ['admin/resendEmailConfirmation']
      )
      .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('UserRolesGateway', UserRolesModel, ['admin/user/roles'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('BannedIpsGateway', CrudModel, ['admin/banned-ips'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('BannedEmailsGateway', CrudModel, ['admin/banned-emails'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  const onAddRoute = () => {
    document.removeEventListener('onAddRoute', onAddRoute);

    const routeConfig = serviceLocator.get('RouteConfig');
    routeConfig.addRoute('admin-users', UserList, 'user/adminuser/getlist', 'Users');
    routeConfig.addRoute(
      'admin-banned-ips',
      BannedIps,
      'user/adminbannedip/getlist',
      'Blocked IPs'
    );
    routeConfig.addRoute(
      'admin-banned-emails',
      BannedEmails,
      'user/adminbannedemail/getlist',
      'Blocked Emails'
    );
  };

  document.addEventListener('onAddRoute', onAddRoute);
};

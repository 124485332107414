import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import QuoteBar from './quote-bar';
import QuoteTable from './quote-table';
import Config from 'Config';
import UpdatableComponent from "../../base/components/updatable-component";
import SendOutSmall from './../../payment/components/send-out-small';
import {TYPE_CREDIT, TYPE_TRANSFER} from './../../payment/components/payment';
import serviceLocator from "../../base/service/service-locator";

export default class QuoteReview extends UpdatableComponent {
  static propTypes = {
    quoteValues: React.PropTypes.object,
    quoteTimeout: React.PropTypes.number,
    bookAllowed: React.PropTypes.bool,
    registerAllowed: React.PropTypes.bool,
    onDone: React.PropTypes.func,
    onBack: React.PropTypes.func,
    onSendOutValuesChange: React.PropTypes.func,
    cardType: React.PropTypes.string,
  };

  state = {
    validateForm: false,
  };

  utils = serviceLocator.get('Utils');

  componentDidMount() {
    this.utils.scrollScreenTo(0);
  }

  onRegisterClick = (event) => {
    event.preventDefault();
    window.location.href = Config.registrationLink;
  };

  onNextButtonClicked = (e) => {
    e.preventDefault();
    this.setState({
      validateForm: true,
    });
  };

  onValidationDone = () => {
    const {onDone} = this.props;
    this.setState({
      validateForm: false,
    });
    onDone();
  };

  onSendOutValuesChange = (values) => {
    const {onSendOutValuesChange} = this.props;
    this.setState({
      validateForm: false,
    });
    onSendOutValuesChange(values);
  };

  render() {
    const {quoteValues, quoteTimeout, bookAllowed, registerAllowed, cardType, onBack} = this.props;
    const {validateForm} = this.state;
    let quoteBar = null;

    const sendOutAmount = !!(
      quoteValues &&
      quoteValues.buyAmount
    );

    if (false && this.state.quoteBarVisible) {
      quoteBar = (
        <QuoteBar
          quoteValues={quoteValues}
          quoteTimeout={quoteTimeout}
        />
      );
    }

    return (
      <div>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="make-transfer-rate"
          transitionEnterTimeout={Config.transitionTimeout.opacity}
          transitionLeaveTimeout={Config.transitionTimeout.opacity}
        >
          <QuoteTable
            quoteAvailable={true}
            quoteValues={quoteValues}
            quoteTimeout={quoteTimeout}
            quoteLoading={false}
            creditCardChargeApplied={cardType === TYPE_CREDIT}
            sendOutAmount={sendOutAmount}
            estimatedDate={cardType === TYPE_TRANSFER ?
              quoteValues.expectedValueDate :
              quoteValues.expectedValueDateBankTransfer
            }
          />
        </ReactCSSTransitionGroup>

        <ReactCSSTransitionGroup
          component="div"
          transitionName="make-transfer-quote-bar"
          transitionEnterTimeout={Config.transitionTimeout.opacity}
          transitionLeaveTimeout={Config.transitionTimeout.opacity}
        >
          {quoteBar}
        </ReactCSSTransitionGroup>

        {bookAllowed
          ? <SendOutSmall
              currency={quoteValues.buyCurrency}
              onValuesChange={this.onSendOutValuesChange}
              validateForm={validateForm}
              onValidationDone={this.onValidationDone}
            />
          : null
        }

        {!quoteValues.bookTradeAllowed && !registerAllowed
          ? <div className="trade-limit-exceed">
              You've reached your daily limit for online dealing.
              For any further payments, please call us on 0207 740 0000
            </div>
          : null
        }

        {bookAllowed
          ? <div className="make-transfer-submit-block">
            <div className="clearfix btn-wrap">
              <div className="col-sm-6">
                <button
                  type="button"
                  onClick={onBack}
                  className="btn btn-block btn-default"
                >Requote</button>
              </div>
              <div className="col-sm-6">
                <button
                  type="button"
                  onClick={this.onNextButtonClicked}
                  className="btn btn-primary btn-block"
                >Next</button>
              </div>
            </div>
          </div>
          : null
        }

        {!bookAllowed && registerAllowed
          ? <div className="make-transfer-submit-block">
              <div className="clearfix btn-wrap">
                <div className="col-sm-6">
                  <button
                    type="button"
                    onClick={onBack}
                    className="btn btn-block btn-default"
                  >Requote</button>
                </div>
                <div className="col-sm-6">
                  <button
                    type="button"
                    onClick={this.onRegisterClick}
                    className="btn btn-primary btn-block btn-buy-currency"
                  >Register</button>
                </div>
              </div>
            </div>
          : null
        }
      </div>);
  }
}

import React from 'react';

export default class InfiniteLoad extends React.Component {
  static propTypes = {
    scrollHandler: React.PropTypes.func.isRequired,
    isEnabled: React.PropTypes.bool.isRequired,
    isLoading: React.PropTypes.bool.isRequired,
    bottomOffset: React.PropTypes.number.isRequired,
    children: React.PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.triggerScrollHandler = this.triggerScrollHandler.bind(this);
  }

  componentWillMount() {
    window.addEventListener('scroll', this.triggerScrollHandler, false);
  }

  componentDidMount() {
    this.triggerScrollHandler();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading === false && nextProps.isEnabled === true) {
      this.triggerScrollHandler();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.triggerScrollHandler);
  }

  triggerScrollHandler() {
    const documentBottomVisibleOffset = window.innerHeight - document.body.scrollTop
      - document.documentElement.scrollTop;

    if (this.props.isEnabled &&
      !this.props.isLoading &&
      documentBottomVisibleOffset <= this.props.bottomOffset
    ) {
      this.props.scrollHandler();
    }
  }

  render() {
    return this.props.children;
  }
}

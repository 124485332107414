import DataModel from './data-model';

export default class CrudModel extends DataModel {

  /**
   * Create new entity
   *
   * @param data
   * @returns {*|axios.Promise|Promise}
   */
  put(data = {}) {
    let url = this.getRoute();
    if (data && typeof data === 'object') {
      if ('id' in data) {
        url += `/${data.id}`;
        // eslint-disable-next-line no-param-reassign
        delete data.id;
      }
    }
    return this.executeCommand('put', [url, data]);
  }

  /**
   * Delete entity by id
   *
   * @param data
   * @returns {*|Promise}
   */
  del(data = {}) {
    let url = this.getRoute();
    if (typeof data === 'number') {
      url += `/${data}`;
    }
    return this.executeCommand('del', [url]);
  }

  /**
   * Get set of entities by params
   *
   * @param data
   * @returns {*}
   */
  getList(data = {}) {
    return this.get(data);
  }
}

import React from 'react';
import serviceLocator from '../../../base/service/service-locator';
import UpdatableComponent from './../../../base/components/updatable-component';
import FormTextFieldWithTooltip from './../../../base/components/form-text-field-with-tooltip';
import ModalAddressLookup from './../../components/modal-address-lookup';

export default class PostcodeField extends UpdatableComponent {

  postcodeLookupGateway = serviceLocator.get('PostcodeLookupGateway');

  static propTypes = {
    postcodeName: React.PropTypes.string.isRequired,
  };

  static contextTypes = {
    formValue: React.PropTypes.object,
    formSuccess: React.PropTypes.object,
    formDisabledFields: React.PropTypes.object,
    addState: React.PropTypes.func,
    setFieldsValues: React.PropTypes.func,
  };

  state = {
    addressObjects: {},
    addressList: {},
    openAddressModal: false,
    addressListIsLoaded: false,
    disabled: false,
    error: null,
  }

  /**
   * Disables find address button
   *
   * @param {Object} newProps
   * @param {Object} context
   */
  componentWillReceiveProps(newProps, newContext) {
    this.setState({
      disabled: newContext.formValue[this.props.countryName] &&
        newContext.formValue[this.props.countryName].value !== 'GB',
    });
  }

  /**
   * Load address list by postcode
   */
  loadAddressList = () => {
    const postcode = this.context.formValue[this.props.postcodeName];
    if (this.context.formValue[this.props.countryName] === undefined) {
      this.setState({
        error: [
          {
            message: 'Please select a country',
          },
        ],
        openAddressModal: true,
        addressListIsLoaded: true,
      });
      return;
    }
    const country = this.context.formValue[this.props.countryName] ?
      this.context.formValue[this.props.countryName].value :
      null;

    this.setState({ openAddressModal: true });

    this.postcodeLookupGateway.getList(
      postcode,
      country,
      addressList => {
        this.setState({
          addressList,
          addressListIsLoaded: true,
        }, () => {
          if (this.props.onLoadedAddresses) {
            this.props.onLoadedAddresses();
          }
        });
      },
      error => {
        this.setState({
          addressListIsLoaded: true,
          error,
        });
      }
    );
  };

  /**
   * Change states when address was selected
   */
  onSelectAddress = (addressId) => {
    this._setAddress(addressId);
    this.onCloseModal();
  };

  onCloseModal = () => {
    this.setState({
      addressListIsLoaded: false,
      openAddressModal: false,
      error: null,
      addressList: {},
    });
  };

  /**
   * Set address fields when address was found by postcode lookup
   *
   * @param {int} addressId
   * @private
   */
  _setAddress = (addressId) => {
    const addressList = this.state.addressList;
    if (addressId && addressList[addressId]) {
      const selectedAddress = addressList[addressId];

      if (this.props.setAddress) {
        this.props.setAddress(
          selectedAddress.addressline1,
          selectedAddress.addressline2,
          selectedAddress.posttown
        );
      }
    }
  };

  render() {
    const { disabled } = this.props;
    return (
      <div key="postcode-lookup">
        <button
          type="button"
          className="btn btn-sm btn-info btn-postcode"
          onClick={this.loadAddressList}
          disabled={disabled}
        >Find address</button>
        <div className="form-control-postcode-wrap">
          <FormTextFieldWithTooltip
            name={this.props.postcodeName}
            placeholder="Postcode"
            className="form-control input-sm form-control-postcode"
            disabled={disabled}
          />
          <ModalAddressLookup
            addressList={this.state.addressList}
            isOpen={this.state.openAddressModal}
            addressListIsLoaded={this.state.addressListIsLoaded}
            onSelectAddress={this.onSelectAddress}
            error={this.state.error}
            onCloseModal={this.onCloseModal}
          />
        </div>
      </div>);
  }
}

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Link } from 'react-router';
import serviceLocator from './../../base/service/service-locator';
import UpdatableComponent from '../../base/components/updatable-component';
import InfiniteLoad from './../../base/components/infinite-load';
import FormattedCurrency from './../../base/components/formatted-currency';
import Loader from './../../app/components/loader';
import Config from 'Config';
import TradeConfirmation from './trade-confirmation';

export default class TradeList extends UpdatableComponent {

  utilsDate = serviceLocator.get('UtilsDate');
  tradeGateway = serviceLocator.get('TradeGateway');
  utils = serviceLocator.get('Utils');
  logger = serviceLocator.get('Logger');

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      limit: props.limit || 10,
      error: null,
      tradeList: [],

      // settings for infinite loading
      infiniteLoadingEnabled: props.infiniteEnabled,
      isLoading: true,
      infiniteLoadOffset: 450,

      showAllButton: false,
    };
  }

  componentDidMount() {
    this.getTradeList();
  }

  /**
   * Get trade list from backend
   */
  getTradeList = () => {
    this.setState({
      isLoading: true,
    });

    this.tradeGateway.get({
      per_page: this.state.limit,
      page: this.state.page,
    }).then(data => {
      if (!this.utils.isComponentMounted(this)) {
        return;
      }

      const tradeList = this.state.tradeList.concat(data.items);
      const nextState = {
        tradeList,
        page: this.state.page + 1,
        isLoading: false,
        showAllButton: tradeList.length < data.count
          && !this.state.infiniteLoadingEnabled,
      };

      if (this.state.page * this.state.limit >= data.count) { // nothing to load
        nextState.infiniteLoadingEnabled = false;
      }

      this.setState(nextState);
    }).catch(error => {
      if (this.logger.handlePromiseCatch(error)) {
        return;
      }
      this.setState({
        error,
        infiniteLoadingEnabled: false,
      });
    });
  };

  renderTable() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="table-responsive"
        transitionEnterTimeout={Config.transitionTimeout.opacity}
        transitionLeaveTimeout={Config.transitionTimeout.opacity}
      >
        <div className="table-responsive">
          <InfiniteLoad
            scrollHandler={this.getTradeList}
            isEnabled={this.state.infiniteLoadingEnabled}
            isLoading={this.state.isLoading}
            bottomOffset={Config.infiniteLoadBottomOffset}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Trade Date</th>
                  <th>Trade Id</th>
                  <th className="text-right">Sell Amount</th>
                  <th className="text-right">Buy Amount</th>
                  <th>Value Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                  <th>Confirmation</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.tradeList.map((trade, i) => (
                    <tr key={`row-${i}`}>
                      <td>{this.utilsDate.getUKDate(trade.date)}</td>
                      <td>{trade.signedId}</td>
                      <td className="text-right">
                        <FormattedCurrency
                          value={trade.clientSellAmount}
                          currency={trade.sellCurrency}
                        />
                      </td>
                      <td className="text-right">
                        <FormattedCurrency
                          value={trade.clientBuyAmount}
                          currency={trade.buyCurrency}
                        />
                      </td>
                      <td>{this.utilsDate.getUKDate(trade.valueDate)}</td>
                      <td className="text-success">{trade.status}</td>
                      <td>
                        {
                          parseFloat(trade.transferId)
                            ? <Link
                              to={`/send-out?id=${parseFloat(trade.transferId)}`}
                            >Send Out</Link>
                            : null
                        }
                      </td>
                      <td>
                        {
                          trade.sendConfirmationNote === 1
                            ? <TradeConfirmation
                              tradeId={parseInt(trade.id, 0)}
                              signedTradeId={trade.signedId}
                            />
                            : null
                        }
                      </td>
                    </tr>))
                }
              </tbody>
            </table>
          </InfiniteLoad>
        </div>

        {
          this.state.showAllButton
          ? <div className="btn-more-wrap">
            <Link to="/trade-history" className="btn btn-sm btn-search">Show all past trades</Link>
          </div>
          : null
        }
      </ReactCSSTransitionGroup>);
  }

  /**
  * Render trade list
  */
  render() {
    return (
      <div>
        {this.state.tradeList.length > 0 ? this.renderTable() : null}

        {
          !this.state.tradeList.length && !this.state.isLoading
          ? <h6 className="text-center">There are no trades booked.</h6>
          : null
        }

        {this.state.isLoading ? <Loader /> : null}
      </div>);
  }
}

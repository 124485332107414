import React from 'react';
import classNames from 'classnames';
import serviceLocator from './../../base/service/service-locator';
import FormattedCurrency from './../../base/components/formatted-currency';

const QuoteBar = ({ quoteValues, quoteTimeout }) => {
  const { getFormatedTimer } = serviceLocator.get('UtilsDate');
  const timerClassNames = classNames({
    'text-danger': quoteTimeout <= 30,
  });

  return (
    <div className="make-transfer-quote-bar">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <ul className="make-transfer-quote-bar-data">
              <li>
                Buy Amount <strong><FormattedCurrency
                  value={quoteValues.buyAmount}
                  currency={quoteValues.buyCurrency}
                /></strong>
              </li>
              <li>
                Exchange rate
                <strong>{quoteValues.rate} {quoteValues.ratePair}</strong>
                <span className="visible-lg-inline"><strong>
                  ({quoteValues.invertedRate} {quoteValues.invertedRatePair})
                </strong></span>
              </li>
              <li>
                Fees <strong><FormattedCurrency
                  value={quoteValues.totalFees}
                  currency={quoteValues.sellCurrency}
                /></strong>
              </li>
            </ul>
          </div>

          <div className="col-md-4">
            <div className="make-transfer-quote-bar-timer">
              <span className="icon-quote-timer-bar"></span>
              <span className={timerClassNames}>
                {getFormatedTimer(quoteTimeout)}
              </span>
            </div>

            <div className="make-transfer-quote-bar-total">
              Total Cost <strong><FormattedCurrency
                value={quoteValues.totalAmount}
                currency={quoteValues.sellCurrency}
              /></strong>
            </div>
          </div>
        </div>
      </div>
    </div>);
};
QuoteBar.propTypes = {
  quoteValues: React.PropTypes.object.isRequired,
  quoteTimeout: React.PropTypes.number.isRequired,
};

export default QuoteBar;

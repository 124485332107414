import serviceLocator from './../base/service/service-locator';
import CrudModel from './../base/models/crud-model';

export default () => {
  serviceLocator.register('BalancesGateway', CrudModel, ['client/balances'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('StatementsGateway', CrudModel, ['client/statements'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
};

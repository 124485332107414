import React from 'react';
import { Modal } from 'react-bootstrap';
import UpdatableComponent from './../../base/components/updatable-component';
import Loader from './../../app/components/loader';
import Radio from './../../base/react-formal/inputs/radio';

export default class ModalAddressLookup extends UpdatableComponent {

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: props.isOpen,
      addressList: props.addressList,
      addressListIsLoaded: props.addressListIsLoaded,
      error: false,
    };
  }

  componentWillReceiveProps(newProps) {
    if (typeof newProps.isOpen !== 'undefined') {
      this.setState({
        isModalOpen: newProps.isOpen,
        addressList: newProps.addressList,
        addressListIsLoaded: newProps.addressListIsLoaded,
      }, () => {
        if (newProps.isOpen) {
          this.checkModalState();
        }
      });
    }

    if (newProps.error) {
      this.setState({ error: newProps.error });
    }
  }

  componentDidMount = this.checkModalState;
  componentDidUpdate = this.checkModalState;

  checkModalState() {
    if (document.querySelector('.wrapper-outer')) {
      if (this.state.isModalOpen) {
        document.querySelector('.wrapper-outer').classList.add('blur');
      } else {
        document.querySelector('.wrapper-outer').classList.remove('blur');
      }
    }
  }

  onSelectAddress = (event) => {
    this.setState({ isModalOpen: false });

    if (event && event.target && event.target.value && this.props.onSelectAddress) {
      this.props.onSelectAddress(event.target.value);
    }
  };

  onCloseModal = () => {
    this.setState({
      addressListIsLoaded: false,
      error: false,
    });
    this.props.onCloseModal();
  };

  renderTitle() {
    if (!this.state.addressListIsLoaded) {
      return <Modal.Title>Loading</Modal.Title>;
    } else if (this.state.addressListIsLoaded && !this.state.error) {
      return (
        <Modal.Title>
          We have found <strong>{Object.keys(this.state.addressList).length} addresses</strong>
        </Modal.Title>);
    }
    return <Modal.Title>Can't lookup address</Modal.Title>;
  }

  renderErrors() {
    return this.state.error
      ? <div>
        {this.state.error.map((error, i) => (
          <div key={i} className="text-center">{error.message}</div>)
        )}
      </div>
      : null;
  }

  renderAddressList() {
    return (this.state.addressListIsLoaded && this.state.addressList.length)
      ? <ul className="address-list">
        {this.state.addressList.map((address, i) => (
          <li key={`address-${i}`}>
            <label>
              <Radio name="addressId" optionValue={i} onClick={this.onSelectAddress} />
              <span>{address.summaryline}</span>
            </label>
          </li>)
        )}
      </ul>
      : null;
  }

  render() {
    return (
      <Modal
        show={this.state.isModalOpen}
        onHide={this.onCloseModal}
        className="modal-address-lookup"
      >
        <Modal.Header closeButton>
          {this.renderTitle()}
        </Modal.Header>
        <Modal.Body>
          <div className="address-list-wrap">
            {!this.state.addressListIsLoaded ? <Loader /> : null}
            {this.renderErrors()}
            {this.renderAddressList()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a href="#" onClick={this.onCloseModal}>I can't find my address</a>
        </Modal.Footer>
      </Modal>);
  }
}

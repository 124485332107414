export default class UtilsCurrency {
  /**
   *
   * @param toTop Array of currencies that need to be moved up,
   *  for instance ['GBP', 'EUR', 'USD']
   * @param currencyList Array a list of currencies where toTop currencies need to be moved to top,
   *  [{value: 'EUR', label: 'EUR'}, {value: 'GBP', label: 'GBP'}]
   * @returns Array
   */
  currenciesToTop(toTop, currencyList) {
    const top = [];
    const sorted = [];
    for (let i = 0; i < currencyList.length; i++) {
      const order = toTop.indexOf(currencyList[i].value);
      if (order > -1) {
        top[order] = currencyList[i];
      } else {
        sorted.push(currencyList[i]);
      }
    }
    return top.concat(sorted);
  }
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator';

const ConfirmPopup = ({ showModal, text, onConfirm, onCancel }) => {
  const { setBlurEffect, removeBlurEffect } = serviceLocator.get('Utils');
  return (<Modal
    className="modal-confirm"
    show={showModal}
  >
    <Modal.Body>
      <p className="text-center">{text}</p>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-sm btn-primary" onClick={onConfirm}>Confirm</button>
      <button className="btn btn-sm btn-link" onClick={onCancel}>Cancel</button>
    </Modal.Footer>
  </Modal>);
};
ConfirmPopup.propTypes = {
  showModal: React.PropTypes.bool.isRequired,
  text: React.PropTypes.string.isRequired,
  onConfirm: React.PropTypes.func.isRequired,
  onCancel: React.PropTypes.func.isRequired,
};

export default ConfirmPopup;

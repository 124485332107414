import React from 'react';
import { browserHistory } from 'react-router';
import UpdatableComponent from './../../base/components/updatable-component';
import serviceLocator from '../../base/service/service-locator.js';
import ReactivateInProgress from './reactivate-in-progress';
import ReactivateCorporate from './reactivate-corporate';
import ReactivateStep1 from './reactivate-step1';
import ReactivateStep2 from './reactivate-step2';
import Loader from './../../app/components/loader';

export default class Reactivate extends UpdatableComponent {
  auth = serviceLocator.get('Auth');
  aclService = serviceLocator.get('AclService');
  currentDetailsGateway = serviceLocator.get('UserCurrentDetails');
  submitGateway = serviceLocator.get('ReactivateSubmit');

  state = {
    isLoading: true,
    user:null,
    userData: null,
    isCorporate: false,
    step1Complete: false,
    submitted: false,
    step1Values: null,
    step2Values: null,
    reactivationInProgress: false,
    regId: 0,
  };

  utils = serviceLocator.get('Utils');

  componentDidMount() {
    if (this.auth.isUserDetailsLoaded()) {
      this.onCurrentUserLoaded();
    } else {
      this.auth.addListener('onCurrentUserLoaded', () => { this.onCurrentUserLoaded(); });
    }
  }

  onCurrentUserLoaded() {
    const user = this.auth.getCurrentUser();
    this.setState({
      user,
      isCorporate: user.profileType !== 'personal',
    });
    this.loadData();
  }
  

  loadData() {
    this.currentDetailsGateway
      .get()
      .then(data => {
        this.setState({
          isLoading: false,
          userData: data,
        });
      });
  }

  onStep1Complete = (formData) => {
    this.setState({
      step1Complete: true,
      step1Values: formData,
    });
    this.utils.scrollScreenTo();
  };

  onStep2Complete = (formData) => {
    this.setState({
      step2Values: formData,
    });
    const { step1Values } = this.state;

    this.submitGateway
      .post({ ...step1Values, ...formData }, true)
      .then((data) => {
        this.setState({
          /*submitted: true,*/
          regId: data.regId ? data.regId : 0,
        });
        this.utils.scrollScreenTo();

        this.auth.getToken(true).then(() => {
          this.aclService.reload().then(() => {
            browserHistory.replace('/');
          });
        });
      });
  };

  wrapSteps(content) {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active tab-reactivate" id="tab-reactivate">
          {content ? content : null}
        </section>
      </div>
    );
  };

  render() {
    const { isLoading, reactivationInProgress, isCorporate, step1Complete, submitted,
      user, userData } = this.state;

    if (isLoading) {
      return this.wrapSteps(
        <Loader />
      );
    }

    if (isCorporate) {
      return this.wrapSteps(<ReactivateCorporate />);
    }

    if (reactivationInProgress) {
      return this.wrapSteps(<ReactivateInProgress />);
    }

    if (!step1Complete) {
      return this.wrapSteps(<ReactivateStep1 onComplete={this.onStep1Complete} initialValues={{ ...user, ...userData }} />);
    }

    if (!submitted) {
      return this.wrapSteps(<ReactivateStep2 onComplete={this.onStep2Complete} initialValues={{ ...user, ...userData }} />);
    }

    return this.wrapSteps(
      <div className="clearfix">
        <div className="col-md-8 col-lg-8 col-lg-offset-1">
          <h3>Thank you for updating your information.</h3>
          <p>
            <br />
            We are processing your details. Please wait...<br />
          </p>
        </div>
      </div>
    );
  }
}

import serviceLocator from './../base/service/service-locator';
import CrudModel from './../base/models/crud-model';
import DataModel from './../base/models/data-model';

export default () => {
  serviceLocator.register('TransferGateway', CrudModel, ['transfer'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('ClientRegistrationGateway', DataModel, ['client/registration'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('GetQuoteGateway', DataModel, ['transfer/get-quote'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('MakeTransferGateway', DataModel, ['transfer/make'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('TermsAndConditionsGateway', DataModel, ['terms-and-conditions'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('LastEditableTransfersGateway', DataModel, ['transfer/get-last-editable'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('GetTransferGateway', DataModel, ['transfer/get'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
  serviceLocator.register('MakeEnquiryGateway', DataModel, ['client/enquiry'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');
};

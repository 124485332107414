export default class ServerError {

  constructor(err) {
    this.error = err;
  }

  getError() {
    return this.error;
  }

  getMessage() {
    return this.error && this.error.message || '';
  }

  getReference() {
    return this.error && this.error.userReference || '';
  }

}

import Config from 'Config';
import DataModel from './../../base/models/data-model';
import CustomEvent from 'custom-event';

export default class AclService extends DataModel {
  initialized = false;

  constructor(route) {
    super(route);
    this.callbacks = [];
    this.resources = [];
    this.resourceMapping = Config.acl.resources ? Config.acl.resources : {};
  }

  bootstrap() {
    if (!this.auth.isAuthorized()) {
      this.auth.addListener('onAuthorize', () => {
        this.load();
      });
      this.addResources(Config.default_acl);
    } else {
      this.load();
    }
  }

  isReady() {
    return this.initialized;
  }

  load() {
    this.initialized = false;

    this.post().then((data) => {
      this.addResources(data);
    });
  }

  reload() {
    this.initialized = false;
    this.resources = [];

    return this.post().then((data) => {
      this.addResources(data);
    });
  }

  /**
   * Add new resource
   * @param resource
   */
  addResource(resource) {
    if (this.resources.indexOf(resource) === -1) {
      this.resources.push(resource);
    }
  }

  /**
   * @param resources
   */
  addResources(resources) {
    this.resources = [];

    resources.forEach((resource) => {
      this.addResource(resource);
    });

    this.setInitialized();

    const event = new CustomEvent('onAclReady');
    document.dispatchEvent(event);
  }

  setInitialized() {
    this.initialized = true;
  }

  /**
   * @param resource
   */
  isAllowed(resource) {
    if (!resource) {
      return false;
    }

    return this.resourceMapping[resource]
      ? this.resources.indexOf(this.resourceMapping[resource]) !== -1
      : this.resources.indexOf(resource) !== -1;
  }
}

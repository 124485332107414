import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import yup from 'yup';
import UpdatableComponent from './updatable-component';
import serviceLocator from './../service/service-locator';

/**
 *
 * If you extend this component you should:
 *
 * 1. Insert all validation rules in schema property
 * 2. Pass state object to parent constructor as second param
 * 3. Render Form this way:
 *
 * <Form ref="form"
 *    component="div"
 *    class="form"
 *    schema={this.schema}
 *    value={this.state.value}
 *    errors={this.state.formErrors}
 *    onError={this.formOnError}
 *    onChange={this.formOnChange}
 *    onSubmit={this.formOnSubmit}
 * >...</Form>
 *
 * 5. If you need to extend childContext you must fully rewrite static childContextTypes
 * and getChildContext() method in child class
 *
 */
export default class FormComponent extends UpdatableComponent {

  logger = serviceLocator.get('Logger');
  utils = serviceLocator.get('Utils');

  static childContextTypes = {
    formValue: React.PropTypes.object,
    formErrors: React.PropTypes.object,
    formSuccess: React.PropTypes.object,
    formDisabledFields: React.PropTypes.object,
    addValidationRules: React.PropTypes.func,
    changeFieldValidation: React.PropTypes.func,
    setFieldsValues: React.PropTypes.func,
    setFieldError: React.PropTypes.func,
    setFieldSuccess: React.PropTypes.func,
  };

  constructor(props, state = {}, schema = {}) {
    super(props);

    this.schema = yup.object(schema);
    this.allFieldsSetAndValidated = false;
    const defaultValue = Object.assign({}, this.schema.default());

    this.state = Object.assign({}, {
      formErrors: {},
      formSuccess: {},
      value: defaultValue,
      disabledFields: {},
      allFieldsSetAndValidated: false,
    }, state);
  }

  isExcludedField = (field) => {
    const formElement = yup.reach(this.schema, field);
    return (formElement &&
      formElement._exclusive &&
      !('required' in formElement._exclusive)
    );
  };

  doesFieldTriggerSuccess = () => true;

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  /**
   * DON'T USE this function in your code (system React function)
   *
   * @deprecated DON'T USE this function in your code
   * @returns {Object}
   */
  getChildContext() {
    const childContext = {
      formValue: this.state.value,
      formErrors: this.state.formErrors,
      formSuccess: this.state.formSuccess,
      formDisabledFields: this.state.disabledFields,
      addValidationRules: this.addValidationRules,
      changeFieldValidation: this.changeFieldValidation,
      setFieldsValues: this.setFieldsValues,
      setFieldError: this.setFieldError,
      setFieldSuccess: this.setFieldSuccess,
    };
    return childContext;
  }

  /**
   * Add rules to schema and trigger handler on finish
   *
   * @param {Object} validationRules
   * @param {function} onStateChanged
   */
  addValidationRules = (validationRules, onStateChanged) => {
    const addingSchema = yup.object(validationRules);
    this.schema = this.schema.concat(addingSchema);
    if (Object.keys(validationRules).length === addingSchema._nodes.length) {
      this.setFieldsValues(addingSchema.default(), onStateChanged);
    } else {
      this.setFieldsValues({}, onStateChanged);
    }
  };

  /**
   * Change field validation (yup object)
   *
   * @param {string} fieldName
   * @param {yup} newFieldValidation
   */
  changeFieldValidation = (fieldName, newFieldValidation) => {
    const addingSchema = {};
    addingSchema[fieldName] = newFieldValidation;

    this.schema = this.schema.concat(yup.object(addingSchema));
  };

  /**
   * Set form fields values
   *
   * @param {Object} fields fieldName => fieldValue
   * @param afterSetFieldsValuesHandler
   */
  setFieldsValues = (fields, afterSetFieldsValuesHandler = null) => {
    let value = Object.assign({}, this.state.value);
    const updatedPaths = [];

    if (fields) {
      Object.keys(fields).forEach(key => {
        if (typeof fields[key] === 'undefined' || fields[key] === null) {
          // eslint-disable-next-line no-param-reassign
          delete fields[key];
          delete value[key];
        } else if (!value.hasOwnProperty(key) || value[key] !== fields[key]) {
          updatedPaths.push(key);
        } else {
          // eslint-disable-next-line no-param-reassign
          delete fields[key];
        }
      });
    }

    value = Object.assign({}, value, fields);

    this.formOnChange(value, updatedPaths, () => {
      this.validateFields(updatedPaths, afterSetFieldsValuesHandler);
    });
  };

  /**
   * Validate selected fields
   *
   * @param {Array} updatedPaths
   * @param afterValidateHandler
   */
  validateFields(updatedPaths, afterValidateHandler = null) {
    const updatedPathsFiltered = updatedPaths.filter(el => el !== 'restoring');
    if (this.refs.form) {
      this.refs.form.validate(updatedPathsFiltered).then((formErrors) => {
        this.formOnError(formErrors, () => {
          if (afterValidateHandler) {
            afterValidateHandler();
          }
        });
      });
    } else {
      if (afterValidateHandler) {
        afterValidateHandler();
      }
    }
  }

  setFieldError = (fieldName, message, onFieldErrorSetHandler = null) => {
    const formErrors = Object.assign({}, this.state.formErrors);
    if (message) {
      formErrors[fieldName] = [{ message }];
    } else {
      delete(formErrors[fieldName]);
    }
    this._formOnError(formErrors, onFieldErrorSetHandler);
  };

  setFieldSuccess = (fieldName, isSuccess) => {
    const formSuccess = Object.assign({}, this.state.formSuccess);
    const formValue = Object.assign({}, this.state.formValue);
    if (isSuccess) {
      formSuccess[fieldName] = true;
    } else {
      delete(formSuccess[fieldName]);
    }
    if (isSuccess && formValue[fieldName]) {
      this.setState({ formSuccess }, () => {
        this.validateFields([fieldName]);
      });
    }
  };

  clearFieldsValues = () => {
    this.setState({
      value: {},
    });
  };

  _formOnSubmit = (fields) => {
    Object.keys(this.schema.fields).forEach(key => {
      if (typeof fields[key] === 'object' && fields[key] !== null && fields[key].value !== undefined) {
        // eslint-disable-next-line no-param-reassign
        fields[key] = fields[key].value;
      } else if (fields[key] === undefined || fields[key] === null) {
        // eslint-disable-next-line no-param-reassign
        fields[key] = '';
      }
    });
  };
  formOnSubmit = this._formOnSubmit;

  _formOnError = (formErrors, onErrorSetHandler = null) => {
    if (!this.utils.isComponentMounted(this)) {
      return;
    }

    const formSuccess = Object.assign({}, this.state.formSuccess);
    const formValue = this.state.value;
    let allFieldsSetAndValidated = true;

    Object.keys(this.schema.fields).forEach(key => {
      if (typeof this.isExcludedField === 'function' && this.isExcludedField(key)) {
        return;
      }

      if (!formErrors[key] && typeof formValue[key] !== 'undefined') {
        if (typeof this.doesFieldTriggerSuccess === 'function' &&
          this.doesFieldTriggerSuccess(key)
        ) {
          formSuccess[key] = true;
        }
      } else {
        delete(formSuccess[key]);
        allFieldsSetAndValidated = false;
      }
    });

    this.allFieldsSetAndValidated = allFieldsSetAndValidated;
    this.setState({
      formErrors,
      formSuccess,
    }, () => {
      if (onErrorSetHandler) {
        onErrorSetHandler();
      }
    });
  };
  formOnError = this._formOnError;

  _formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    if (this.props.storageNamespace && typeof this.store === 'function' && !value.restoring) {
      this.store(value);
    }
    // eslint-disable-next-line no-param-reassign
    delete value.restoring;

    this.setState({
      value,
    }, () => {
      if (onValueUpdateHandler) {
        onValueUpdateHandler();
      }
    });
  };
  formOnChange = this._formOnChange;

  _onFailResponse = (failResponse) => {
    if (this.logger.handlePromiseCatch(failResponse)) {
      return;
    }

    let formErrors = {};

    if (Array.isArray(failResponse)) {
      formErrors = this.onLegacyValidation(failResponse);
    }

    if (failResponse.code === 'ERROR_VALIDATION' && failResponse.message) {
      if (typeof failResponse.message === 'string') {
        formErrors = { common: [{ message: failResponse.message }]};
      } else {
        formErrors = this.onValidation(failResponse);
      }
    }

    this._formOnError(formErrors);
  };

  onLegacyValidation = failResponse => Object.keys(failResponse)
    .reduce((fields, key) => {
      if (failResponse[key].code && failResponse[key].field) { // field errors
        let errorKey = failResponse[key].field;
        if (!this.schema.fields[errorKey]) {
          errorKey = 'common';
        }
        if (!fields[errorKey]) {
          fields[errorKey] = [];
        }
        fields[errorKey].push({message: failResponse[key].message});
      } else { // Other errors
        const message = failResponse[key].message || failResponse[key];
        if (!fields.common) {
          fields.common = [];
        }
        fields.common.push({message});
      }

      return fields;
    }, {});

  onValidation = failResponse => Object.keys(failResponse.message)
    .reduce((fields, key) => {
      let errorKey = key;
      if (!this.schema.fields[errorKey]) {
        errorKey = 'common';
      }
      if (!fields[errorKey]) {
        fields[errorKey] = [];
      }
      fields[errorKey].push({message: failResponse.message[key]});

      return fields;
    }, {});
}

import React from 'react';
import Form from 'react-formal';
import Select from 'react-select';
import yup from 'yup';
import serviceLocator from '../../base/service/service-locator';
import UpdatableComponent from './../../base/components/updatable-component';
import { transferReasonOther } from '../payment';
import Tooltip from './../../app/components/tooltip';
import FormGroup from './../../app/components/form-group';
import FormTextField from './../../base/components/form-text-field';
import Radio from './../../base/react-formal/inputs/radio';
import BeneficiaryForm from './../../beneficiary/components/beneficiary-form';

export default class SendOutFieldset extends UpdatableComponent {
  reasonsForTransferGateway = serviceLocator.get('ReasonsForTransferGateway');

  state = {
    reasonsList: [],
  };

  componentWillMount() {
    this.loadData();
  }

  loadData() {
    this.reasonsForTransferGateway.getList(reasonsList => {
      this.setState({
        reasonsList,
      });
    });
  }

  renderOtherReasonRow() {
    return this.props.showOtherReason
      ? <div className="form-group row">
        <label htmlFor="beneficiaryReasonOther" className="label-col"></label>
        <FormGroup
          formGroupClassName="input-col"
          fieldName="otherReason"
          icons
        >
          <FormTextField
            name="otherReason"
            placeholder="Please specify"
            type="text"
            className="form-control input-sm"
            id="beneficiaryReasonOther"
          />
        </FormGroup>
      </div>
      : null;
  }

  render() {
    return (
      <div className="form-group send-out-fieldset">
        <BeneficiaryForm />
        <div className="form-group row">
          <label htmlFor="sendOutReference" className="label-col">Reference</label>
          <FormGroup
            formGroupClassName="input-col form-group-sm"
            fieldName="reference1"
            icons
          >
            <FormTextField
              name="reference1"
              placeholder="Additional Details"
              type="text"
              className="form-control input-sm"
              id="sendOutReference"
            />
          </FormGroup>

          <div className="tooltip-col hidden-xs">
            <Tooltip text="Reference of this send out" />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="beneficiaryReason" className="label-col">Reason for Transfer</label>
          <FormGroup
            formGroupClassName="form-group-select input-col form-group-sm"
            fieldName="reason"
            icons
          >
            <Form.Field
              type={Select}
              name="reason"
              id="beneficiaryReason"
              className="input-sm"
              placeholder="Specify reason"
              options={this.state.reasonsList}
              clearable={false}
              searchable={false}
            />
          </FormGroup>
        </div>
        {this.renderOtherReasonRow()}
        <div className="form-group row">
          <label htmlFor="thirdPartyTrigger" className="label-col">This account belongs to</label>
          <FormGroup formGroupClassName="input-col form-group-sm">
            <div className="radio">
              <label key="thirdParty-1" htmlFor="thirdParty-1">
                <Form.Field type={Radio} name="thirdParty" id="thirdParty-1" optionValue={'0'} />
                <span className="input"></span>
                  Me or I’m a joint account holder
              </label>
            </div>
            <div className="radio">
              <label key="thirdParty-2" htmlFor="thirdParty-2">
                <Form.Field type={Radio} name="thirdParty" id="thirdParty-2" optionValue={'1'} />
                <span className="input"></span>
                  A third party
              </label>
            </div>
          </FormGroup>
        </div>
      </div>);
  }
}

export const schema = {
  reference1: yup.string(),
  reason: yup.object().required('Specify reason'),
  otherReason: yup.string().when('reason', (reason) => {
    if (reason !== undefined && reason.value === transferReasonOther) {
      return yup.string().required('Please specify');
    }
    return yup.string();
  }),
  thirdParty: yup.string().default('0'),
};

import DataClientAdapterInterface from './data-client-adapter-interface';
import serviceLocator from './../../base/service/service-locator';
import ServerError from './../../base/error/server-error';
import AssertionError from 'assertion-error';
import { browserHistory } from 'react-router';

export default class HttpClientAdapter extends DataClientAdapterInterface {
  /**
   * @param {string} url
   * @param {Object} client
   */
  constructor(url = '', client = null) {
    super();
    this.setClient(client);
    this.setUrl(url);
  }

  /**
   * @param {Object} client
   */
  setClient(client) {
    this.client = client;
  }

  /**
   * @returns {Object}
   */
  getClient() {
    return this.client;
  }

  /**
   * @param {string} url
   */
  setUrl(url) {
    this.url = url;
  }

  /**
   * @returns {string}
   */
  getUrl() {
    return this.url;
  }

  /**
   * @param {string} command
   * @param {string} url
   * @param {mixed} data
   * @param {Object|null} config
   * @returns {Promise}
   * @private
   */
  _clientCommand(command, url, data = null, config = null) {
    const args = [];
    const { asJson, ...pureConfig } = config;

    // add url
    args.push((this.url || '') + url);

    switch (command) {
      case 'get':
      case 'head':
      case 'delete':
      case 'del':
        break;

      case 'put':
      case 'post':
        if (data === null) {
          args.push(null);
        } else {
          args.push(asJson ? data : this.normalizeParams(data));
        }
        break;

      default:
        throw new Error(`Incorrect command ${command}`);
    }

    // add config
    if (pureConfig !== null) {
      args.push(pureConfig);
    }

    return this.client[command](...args);
  }

  /**
   * @param {string} command
   * @param {string} url
   * @param {Object|null} data
   * @param {Object|null} config
   * @param {int} times
   * @returns {Promise}
   * @private
   */
  _clientCommandRetry(command, url, data = null, config = null, times = 0) {
    return this._clientCommand(command, url, data, config)
      .then(response => this._onSuccess(response))
      .catch(error => {
        if (error.response && [401, 403, 412].includes(error.response.status)) {
          return this._onError(error);
        }

        if (times > 1) {
          return this._clientCommandRetry(command, url, data, config, times - 1);
        }

        if (url !== 'log') {
          const logger = serviceLocator.get('Logger');
          logger.pushLogToQueue({
            message: `Request [${command}] ${url} ${JSON.stringify(data)}. Response ${JSON.stringify(error.response)}`,
            level: 'error',
          });
          logger.startLoggerTask();
        }

        return this._onError(error);
      });
  }

  /**
   * @param {mixed} params
   * @returns {*}
   */
  normalizeParams(params) {
    if (params && typeof params === 'object') {
      if (params instanceof FormData) {
        return params;
      }
      if (params.toString() !== '[object Object]') {
        return params.toString();
      }
      const results = [];
      Object.keys(params).forEach(parentIndex => {
        if (params[parentIndex] instanceof Object) {
          Object.keys(params[parentIndex]).forEach(subIndex => {
            results.push(
              // eslint-disable-next-line max-len
              `${encodeURIComponent(parentIndex)}[${encodeURIComponent(subIndex)}]=${encodeURIComponent(params[parentIndex][subIndex])}`
            );
          });
        } else {
          results.push(
            `${encodeURIComponent(parentIndex)}=${encodeURIComponent(params[parentIndex])}`
          );
        }
      });
      return results.join('&');
    }
    if (params && typeof params === 'function') {
      return params();
    }
    return params.toString();
  }

  /**
   * Get query object from query string (with leading ?)
   *
   * @param {string} queryString
   * @returns {{}}
   */
  parseQueryString(queryString) {
    const query = queryString.substring(1);
    const vars = query.split('&');
    const result = {};
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return result;
  }

  /**
   * Get query string param from query string (with leading ?)
   *
   * @param {string} queryString
   * @param {string} variable
   * @returns {string}
   */
  getQueryVariable(queryString, variable) {
    const queryObject = this.parseQueryString(queryString);
    if (queryObject[variable]) {
      return queryObject[variable];
    }
    return null;
  }

  /**
   * @param response
   * @returns {*}
   * @private
   */
  _onSuccess(response) {
    return response.data;
  }

  /**
   * @param error
   * @returns {*}
   * @private
   */
  _onError(error) {
    if (error instanceof AssertionError) {
      console.log(error);
    }

    const response = (error instanceof Error) ? error.response : error;
    if (!response || !response.status || !response.data) {
      throw new Error('Wrong REST response');
    }

    if (response.status === 403) {
      browserHistory.replace('deny');
    }

    if (response.status === 401) {
      serviceLocator.get('Jwt').set(null);
      browserHistory.replace('expired');
    }

    if (response.status >= 400 && response.status < 500) {
      return response.data;
    }

    if (response.data.content &&
      response.data.content.error &&
      response.data.content.error[0]
    ) {
      const serverError = new ServerError(response.data.content.error[0]);
      serviceLocator.get('Logger').report(serverError);
      throw serverError;
    }

    throw new Error('Wrong REST response');
  }

  logError(errorMessage) {
    const error = (errorMessage instanceof Error || errorMessage instanceof ServerError)
      ? errorMessage
      : new Error(errorMessage);
    serviceLocator.get('Logger').report(error);
  }

  /**
   * @param {string} url
   * @param {Object|null} config
   * @returns {Promise}
   */
  get(url, config = null) {
    return this._clientCommandRetry('get', url, null, config, 3);
  }

  /**
   * @param {string} url
   * @param {Object|null} config
   * @returns {Promise}
   */
  del(url, config = null) {
    return this._clientCommandRetry('delete', url, null, config, 1);
  }

  /**
   * @param {string} url
   * @param {Object|config} config
   * @returns {Promise}
   */
  head(url, config = null) {
    return this._clientCommandRetry('head', url, null, config, 1);
  }

  /**
   * @param {string} url
   * @param {Object|null} data
   * @param {Object|null} config
   * @returns {Promise}
   */
  post(url, data = null, config = null) {
    return this._clientCommandRetry('post', url, data, config, 1);
  }

  /**
   * @param {string} url
   * @param {Object|null} data
   * @param {Object|null} config
   * @returns {Promise}
   */
  put(url, data = null, config = null) {
    return this._clientCommandRetry('put', url, data, config, 1);
  }

}

import React from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import FormTextField from './../../base/components/form-text-field';
import Loader from './../../app/components/loader';

/**
 * Reset password step 1: send link to reset password
 */
export default class ResetPasswordStep1 extends FormComponent {

  utils = serviceLocator.get('Utils');

  constructor(props) {
    const state = {
      success: false,
      error: false,
      isLoading: false,
    };

    const schema = {
      email: yup.string().email().required('Enter your e-mail'),
    };

    super(props, state, schema);
  }

  componentDidMount() {
  }

  formOnSubmit = (fields) => {
    this._formOnSubmit(fields);
    const resetPasswordStep1Gateway = serviceLocator.get('ResetPasswordStep1Gateway');

    this.setState({
      error: false,
      isLoading: true,
    });

    resetPasswordStep1Gateway
      .post({
        email: fields.email,
      })
      .then(() => {
        this.setState({
          success: true,
          isLoading: false,
        });
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.setState({
          error: true,
          isLoading: false,
        });
      });
  };

  renderBody() {
    if (this.state.success) {
      return (
        <p className="success-message text-center">
          {/* eslint-disable max-len */}
          Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.
          {/* eslint-enable max-len */}
        </p>);
    }

    return (
      <div>
        {/* eslint-disable max-len */}
        <p className="text-center">Enter your email address and we will send you a link to reset your password</p>
        {/* eslint-enable max-len */}
        {
          this.state.error
          ? <p className="text-center text-danger">
            Can't find accounts with this email, please check and try again
          </p>
          : null
        }
        <FormGroup
          fieldName="email"
          context={this.getChildContext()}
          icons
          formGroupClassName="form-group-sm"
        >
          <label htmlFor="email" className="sr-only">E-mail</label>
          <FormTextField
            name="email"
            placeholder="E-mail"
            className="form-control input-sm"
            id="email"
          />
        </FormGroup>
        {this.state.isLoading ? <Loader /> : null}
      </div>);
  }

  renderFooter() {
    let footerContent = null;
    const backToLogin = (
      <div className="form-group">
        <a href="/" className="btn btn-sm btn-block btn-link" >Go back to Login</a>
      </div>
    );

    if (!this.state.success) {
      footerContent = (
        <div>
          <FormGroup
            formGroupClassName="form-group-btn-submit"
            context={this.getChildContext()}
          >
            <Form.Button
              type="submit"
              disabled={this.state.isLoading}
              className="btn btn-primary btn-lg btn-block btn-reset-password"
            >Send reset link</Form.Button>
          </FormGroup>
          {backToLogin}
        </div>
      );
    } else {
      footerContent = backToLogin;
    }

    return <Modal.Footer>{footerContent}</Modal.Footer>;
  }

  render() {
    return (
      <Modal
        className="modal-forgot-password"
        show
        animation={false}
        bsSize="small"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className="navbar-brand">CURRENCY SOLUTIONS</div>
          <Modal.Title>Forgot password</Modal.Title>
        </Modal.Header>
        <Form
          ref="form"
          component="div"
          className="form"
          schema={this.schema}
          value={this.state.value}
          errors={this.state.formErrors}
          onError={this.formOnError}
          onChange={this.formOnChange}
          onSubmit={this.formOnSubmit}
        >
          <Modal.Body>
            {this.renderBody()}
          </Modal.Body>
          {this.renderFooter()}
        </Form>
      </Modal>);
  }
}

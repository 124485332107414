import MenuTabModel from './menuTab.js';
import MenuConfig from '../config.js';

export default class MenuModel {

  constructor() {
    this.setCurrentTab(0);
  }

  /**
   * @returns {Promise}
   */
  loadMenu() {
    return this.getPreparedData(MenuConfig.tabs);
  }

  /**
   * @param data
   * @returns {*}
   */
  getPreparedData(data) {
    const current = this.getCurrentTab();
    data.forEach((tab, index, self) => {
      const mm = new MenuTabModel(tab, index === current);
      // eslint-disable-next-line no-param-reassign
      self[index] = mm.getTab();
    });

    return data;
  }

  /**
   * @param menu
   */
  setMenu(menu) {
    this.menu = menu;
  }

  /**
   * @returns {*}
   */
  getMenu() {
    return this.menu;
  }

  /**
   * @param current
   */
  setCurrentTab(current) {
    this.currnetTab = current;
  }

  /**
   * @returns {number}
   */
  getCurrentTab() {
    return this.currnetTab;
  }
}

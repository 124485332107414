import React from 'react';
import serviceLocator from './../../base/service/service-locator';

const AccessDenied = () => {
  const jwt = serviceLocator.get('Jwt');
  jwt.set(null);

  return (
    <div className="tab-content platform-tabs">
      <div className="tab-pane fade in active tab-access-denied">
        <h1>Access Denied!</h1>

        <p>Sorry you do not have access to this page.</p>
        <a href="/">Go to the main page</a>
      </div>
    </div>
  );
};

export default AccessDenied;

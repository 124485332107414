import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Form from 'react-formal';
import Config from 'Config';

const FormMessage = (props, context) => {
  const c = props.context || context;
  const fieldName = props.htmlFor || props.for;
  const hasError = (c.formErrors && c.formErrors[fieldName]);
  let message = null;

  if (fieldName && hasError) {
    /* eslint-disable react/no-unknown-property */
    message = (
      <div className={`form-group-message ${(hasError ? ' alert-danger' : '')}`}>
        <Form.Message for={fieldName} />
      </div>
    );
    /* eslint-enable react/no-unknown-property */
  }

  return (
    <ReactCSSTransitionGroup
      component="div"
      transitionName="form-group-message"
      transitionEnterTimeout={Config.transitionTimeout.opacity}
      transitionLeaveTimeout={Config.transitionTimeout.opacity}
    >
      {message}
    </ReactCSSTransitionGroup>
  );
};

FormMessage.contextTypes = {
  formErrors: React.PropTypes.object,
  formSuccess: React.PropTypes.object,
};
FormMessage.propTypes = {
  context: React.PropTypes.object,
  for: React.PropTypes.string,
  htmlFor: React.PropTypes.string,
};

export default FormMessage;

import React from 'react';
import UpdatableComponent from '../../base/components/updatable-component';
import UploadDocumentsForm from './upload-documents-form';
import serviceLocator from "../../base/service/service-locator";

export default class UploadDocuments extends UpdatableComponent {
  auth = serviceLocator.get('Auth');

  state = {
    user: null,
    showForm: true,
  };

  onSuccess = () => {
    this.setState({
      showForm: false,
    });
  };

  componentDidMount() {
    if (this.auth.isUserDetailsLoaded) {
      this.onCurrentUserLoaded();
    } else {
      this.auth.addListener('onCurrentUserLoaded', () => { this.onCurrentUserLoaded(); });
    }
  }

  onCurrentUserLoaded() {
    this.setState({
      user: this.auth.getCurrentUser(),
    });
  }

  renderForm() {
    const { user } = this.state;
    if (!user) {
      return null;
    }

    /* eslint-disable max-len */
    return (
      <UploadDocumentsForm
        subtitle="Your registration hasn't processed yet. Here you can upload additional documents to make the process faster. If all documents have been uploaded just wait we will check it. For any additional questions please call us: +44 (0)20 7740 0000"
        showSkipLink={false}
        onSuccess={this.onSuccess}
        idIsRequired={true}
        billIsRequired={true}
        showForm={this.state.showForm}
        entityType={user.profileType === 'corporate' ? 'reg_corporates' : 'reg_personals'}
        entityId={`${user.regId}`}
      />);
    /* eslint-enable max-len */
  }

  render() {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active">
          {this.renderForm()}
        </section>
      </div>);
  }
}

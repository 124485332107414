import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Config from 'Config';

const FilesList = ({ files, onFileRemove }) => {
  const items = files.map((listValue, i) => (
    <li className="row list-item" key={`${i}Tooltip`}>
      <div className="col-xs-10">{listValue.name}</div>
      <div className="col-xs-2">
        <a
          href="#"
          className="text-danger"
          onClick={
                    (e) => { e.preventDefault(); onFileRemove(listValue.id); }
                  }
        >Delete
        </a>
      </div>
    </li>
  ));

  return (<ReactCSSTransitionGroup
    component="ul"
    className="upload-documents-list"
    transitionName="list-item"
    transitionEnterTimeout={Config.transitionTimeout.opacity}
    transitionLeaveTimeout={Config.transitionTimeout.opacity}
  >
    {items}
  </ReactCSSTransitionGroup>);
};
FilesList.propTypes = {
  files: React.PropTypes.array.isRequired,
  onFileRemove: React.PropTypes.func.isRequired,
};

export default FilesList;

import DataModel from './../../base/models/data-model';

export default class PostcodeLookupModel extends DataModel {

  getList(postcode, country, onSuccess, onError) {
    this
      .get({
        postcode,
        country,
      })
      .then(response => {
        const addresses = Object.keys(response).reduce((options, key) => {
          options.push(response[key]);
          return options;
        }, []);

        if (typeof onSuccess === 'function') {
          return onSuccess(addresses);
        }

        return addresses;
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        if (typeof onError === 'function') {
          onError(error);
        }
      });
  }
}

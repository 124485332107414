export default class UtilsDate {

  monthsInAYear = 12;


  getCurrentDate() {
    return new Date();
  }

  getDateShiftByMonths(monthsCount) {
    const date = this.getCurrentDate();
    date.setMonth(date.getMonth() + monthsCount);

    return date;
  }

  getDateShiftByYears(yearsCount) {
    return this.getDateShiftByMonths(this.monthsInAYear * yearsCount);
  }


  getFormatedDateTime(stringDateTime, delimiter = '-', showTime = true) {
    const formatedStringDateTime = stringDateTime && typeof stringDateTime === 'string' ?
      stringDateTime.replace(/-/g, '/') :
      stringDateTime;
    let date = isNaN(Date.parse(stringDateTime)) ? formatedStringDateTime : stringDateTime;
    if (isNaN(Date.parse(date))) {
      return '';
    }
    if (typeof date === 'string') {
      date = new Date(date);
    }
    let formated = (`0${date.getDate()}`).slice(-2) +
      delimiter +
      (`0${date.getMonth() + 1}`).slice(-2) +
      delimiter +
      date.getFullYear();
    if (showTime) {
      formated +=
        // eslint-disable-next-line prefer-template
        ' ' +
        (`0${date.getHours()}`).slice(-2) +
        ':' +
        (`0${date.getMinutes()}`).slice(-2);
    }

    return formated;
  }

  getUKDate(stringDate) {
    return this.getFormatedDateTime(stringDate, '/', false);
  }

  getUKDateTime(stringDateTime) {
    return this.getFormatedDateTime(stringDateTime, '/', true);
  }

  getRFCDate(stringDate) {
    return this.getFormatedDateTime(stringDate, '-', false);
  }

  getRFCDateTime(stringDateTime) {
    return this.getFormatedDateTime(stringDateTime, '-', true);
  }

  getISODateTime(stringDateTime, delimiter = '-', showTime = true) {
    let date = stringDateTime;
    if (isNaN(Date.parse(stringDateTime))) {
      return '';
    }
    if (typeof stringDateTime === 'string') {
      date = new Date(stringDateTime);
    }
    let formated = date.getFullYear() +
      delimiter +
      (`0${date.getMonth() + 1}`).slice(-2) +
      delimiter +
      (`0${date.getDate()}`).slice(-2);
    if (showTime) {
      formated +=
        // eslint-disable-next-line prefer-template
        ' ' +
        (`0${date.getHours()}`).slice(-2) +
        ':' +
        (`0${date.getMinutes()}`).slice(-2);
    }

    return formated;
  }

  getISODate(stringDate) {
    return this.getISODateTime(stringDate, '-', false);
  }

  /**
   * @param {Date} date
   * @param {string} format
   * @return {string}
   */
  getFormatedDate(date, format = 'YYYY-MM-DD') {
    return format.replace(/YYYY/g, date.getFullYear())
      .replace(/MM/g, `0${date.getMonth() + 1}`.substr(-2, 2))
      .replace(/DD/g, `0${date.getDate()}`.substr(-2, 2));
  }

  getFormatedTimer(time) {
    const minutes = Math.floor(time / 60);
    const seconds = (time - minutes * 60);

    let t = `${seconds}s`;
    if (minutes) {
      t = `${minutes}m:${t}`;
    }

    return t;
  }
}

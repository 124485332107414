import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator.js';

const ModalQuoteExpired = ({ showModal, hideModalHandler }) => {
  const { setBlurEffect, removeBlurEffect } = serviceLocator.get('Utils');

  return (
    <Modal
      className="modal-user-dialog modal-quote-expired"
      bsSize="sm"
      show={showModal}
    >
      <Modal.Header closeButton onHide={hideModalHandler} />
      <Modal.Body>
        <p className="text-center"><strong>Your quote has elapsed.</strong></p>

        <div className="form-group text-center">
          <button className="btn btn-primary" onClick={hideModalHandler}>OK</button>
        </div>
      </Modal.Body>
    </Modal>);
};
ModalQuoteExpired.propTypes = {
  showModal: React.PropTypes.bool,
  hideModalHandler: React.PropTypes.func,
};

export default ModalQuoteExpired;

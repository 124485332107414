import intlPolyfill from './base/polyfills/intl.js';

import React from 'react';
import ReactDom from 'react-dom';
import { Router, browserHistory } from 'react-router';
import { IntlProvider } from 'react-intl';
import CustomEvent from 'custom-event';
import serviceLocator from './base/service/service-locator';
import localization from './localization';
import counters from './app/components/counters';

import baseModule from './../src/base/base';
import appModule from './../src/app/app';
import userModule from './../src/user/user';
import menuModule from './../src/menu/menu';
import beneficiaryModule from './../src/beneficiary/beneficiary';
import emailVerificationModule from './../src/email-verification/email-verification';
import notificationModule from './../src/notification/notification';
import paymentModule from './../src/payment/payment';
import phoneVerifyModule from './../src/phone-verify/phone-verify';
import postcodeLookupModule from './../src/postcode-lookup/postcode-lookup';
import registrationModule from './../src/registration/registration';
import statementModule from './../src/statement/statement';
import tradeModule from './../src/trade/trade';
import transferModule from './../src/transfer/transfer';
import routerModule from './../src/router/router';
import affiliateModule from './../src/affiliate/affiliate';
import uploadDocumentsModule from './../src/upload-documents/upload-documents';
import captchaModule from './../src/captcha/captcha';
import reactivateModule from './../src/reactivate/reactivate';

const logPageView = () => serviceLocator.get('GA').logPageView(window.location.pathname);

let csWpObject;
window.csWpObject = csWpObject;

let customOptions;
window.customOptions = customOptions;

// This method is not testable, so modify it with care!
const onRouterReady = () => {
  document.removeEventListener('onRouteReady', onRouterReady, false);
  const logger = serviceLocator.get('Logger');

  if (process.env.NODE_ENV === 'test') {
    return;
  }

  try {
    const RouteConfig = serviceLocator.get('RouteConfig');
    ReactDom.render(
      <IntlProvider locale="en">
        <Router
          history={browserHistory}
          routes={RouteConfig.getRoutesConfig()}
          onUpdate={logPageView}
        />
      </IntlProvider>,
      document.querySelector('#main')
    );
  } catch (e) {
    logger.report(e);
  }
};

const bootstrapModules = () => {
  counters();
  intlPolyfill();
  baseModule();
  appModule();
  userModule();
  menuModule();
  beneficiaryModule();
  emailVerificationModule();
  notificationModule();
  paymentModule();
  phoneVerifyModule();
  captchaModule();
  postcodeLookupModule();
  registrationModule();
  statementModule();
  tradeModule();
  transferModule();
  routerModule();
  affiliateModule();
  uploadDocumentsModule();
  reactivateModule();
  localization();

  const event = new CustomEvent('onBootstrap');
  document.dispatchEvent(event);
};

const onDOMLoaded = () => {
  window.removeEventListener('load', onDOMLoaded, false);
  document.removeEventListener('DOMContentLoaded', onDOMLoaded, false);

  bootstrapModules();

  document.addEventListener('onRouteReady', onRouterReady);

  if (sessionStorage && !sessionStorage.getItem('originalQueryString')) {
    let search = document.location.search || '';
    if (document.referrer) {
      search = `${search !== ''
        ? `${search}&`
        : '?'
      }httpRef=${encodeURIComponent(document.referrer)}`;
    }
    if (search) {
      sessionStorage.setItem('originalQueryString', search);
    }
  }

  const event = new CustomEvent('onAppReady');
  document.dispatchEvent(event);

  document.body.classList.add('loader-fade-out');
  setTimeout(() => {
    document.body.classList.remove('loader-fade-out');
    document.body.classList.remove('loading');
  }, 1000);
};

export const bootstrap = () => {
  if (document.readyState === 'complete') {
    // Already loaded!
    onDOMLoaded();
  } else {
    // Add onload or DOMContentLoaded event listeners
    window.addEventListener('load', onDOMLoaded, false);
    // or
    document.addEventListener('DOMContentLoaded', onDOMLoaded, false);
  }
};

import React from 'react';

const PasswordStrengthMeter = ({ passwordScore }) => {
  // eslint-disable-next-line max-len
  const fillClassName = `password-strength-meter-fill ${(passwordScore !== null ? (`score${passwordScore}`) : '')}`;
  return (
    <div className="password-strength-meter">
      <div className={fillClassName} />
    </div>
  );
};
PasswordStrengthMeter.propTypes = {
  passwordScore: React.PropTypes.number,
};

export default PasswordStrengthMeter;

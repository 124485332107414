import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator';
import Loader from './../../app/components/loader';

const PERSONAL_TERMS = 1;
const CORPORATE_TERMS = 2;

export default class TermsAndConditions extends React.Component {
  static propTypes = {
    show: React.PropTypes.bool.isRequired,
    close: React.PropTypes.func.isRequired,
    template: React.PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      text: null,
      title: 'Loading',
    };
    this.onEntering = this.onEntering.bind(this);

    this.terms = {
      personal: PERSONAL_TERMS,
      corporate: CORPORATE_TERMS,
    };
  }

  componentWillMount() {
    this.setTemplate(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setTemplate(nextProps);
  }

  onEntering() {
    this.utils.setBlurEffect();
    if (!this.state.text) {
      this.load();
    }
  }

  setTemplate(props) {
    if (!props.template) {
      return this.terms.personal;
    }
    this.tmpl = this.terms.hasOwnProperty(props.template) ?
      this.terms[props.template] : this.terms.personal;
    return this.tmpl;
  }

  utils = serviceLocator.get('Utils');

  load = () => {
    const termsAndConditionsGateway = serviceLocator.get('TermsAndConditionsGateway');
    termsAndConditionsGateway.get({
      tmpl: this.tmpl,
    }).then(response => {
      this.setState({
        text: response.snippet,
        title: 'Terms and Conditions',
      });
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        className="modal fade modal-terms-and-conditions"
        id="modalTermsAndConditions"
        onHide={this.props.close}
        onEntering={this.onEntering}
        onExiting={this.utils.removeBlurEffect}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-sm-12">
              {
                this.state.text
                ? <div dangerouslySetInnerHTML={{ __html: this.state.text }} />
                : <Loader />
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>);
  }
}

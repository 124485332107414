import React from 'react';
import Form from 'react-formal';
import yup from 'yup';
import Config from 'Config';
import UpdatableComponent from './../../base/components/updatable-component';
import FormGroup from './../../app/components/form-group';
import SelectCountry from './../../base/form/fields/select-country';
import BankDetailsForm from './bank-details-form';
import PhoneVerifyGroup from './../../phone-verify/components/phone-verify-form-group';
import serviceLocator from '../../base/service/service-locator';

export default class BeneficiaryForm extends UpdatableComponent {
  static contextTypes = {
    formValue: React.PropTypes.object,
    formDisabledFields: React.PropTypes.object,
  };

  state = {
    userVerificationDone: false,
    newBeneficiarySelected: true,
    context: {},
  };
  jwt = serviceLocator.get('Jwt');

  constructor(props, context) {
    context = props.context || context; // eslint-disable-line no-param-reassign
    const state = {
      context,
    };
    super(props, state);
    this.state.context = context;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    nextContext = nextProps.context || nextContext; // eslint-disable-line no-param-reassign
    this.setState({
      context: nextContext,
    });
    if (nextContext.formValue.beneficiaryId &&
      nextContext.formValue.beneficiaryId.value === '-1'
    ) {
      const newState = {
        newBeneficiarySelected: true,
      };
      let amount = nextContext.formValue.amount;
      if (typeof amount === 'string') {
        amount = parseFloat(amount.replace(/[,\s]/, ''));
      }
      if (amount >= 10000 || this.jwt.isCsmsToken()) {
        newState.userVerificationDone = true;
      }
      this.setState(newState);
    } else {
      this.setState({
        newBeneficiarySelected: false,
      });
    }
  }

  onUserVerificationDone = () => {
    this.setState({ userVerificationDone: true });
  };

  renderBeneficiaryConfirmation() {
    return (this.state.newBeneficiarySelected && !this.state.userVerificationDone)
      ? <div className="form-group row user-verify-form-group">
        <label htmlFor="mobileField" className="label-col">Confirm new beneficiary</label>
        <FormGroup
          formGroupClassName="input-col form-group-mobile"
          fieldName="mobile"
          icons
        >
          <PhoneVerifyGroup
            verifyUser={true}
            setUserVerificationDone={this.onUserVerificationDone}
            verifyLabel="Confirm"
            verifiedLabel="Confirmed"
            modalTitle="Please confirm new beneficiary"
          />
        </FormGroup>
      </div>
      : null;
  }

  render() {
    return (
      <div className="form-part beneficiary-form">
        <div className="form-group row">
          <label htmlFor="beneficiaryName" className="label-col">Beneficiary Name</label>
          <FormGroup
            formGroupClassName="input-col form-group-sm"
            fieldName="name"
            icons
          >
            <Form.Field
              name="name"
              placeholder="e.g. John Smith"
              type="text"
              className="form-control input-sm"
              id="beneficiaryName"
              disabled={this.context.formDisabledFields.name}
            />
          </FormGroup>
        </div>

        <div className="form-group row">
          <label htmlFor="beneficiaryReference" className="label-col">Beneficiary Reference</label>
          <FormGroup
            formGroupClassName="input-col form-group-sm"
            fieldName="reference1"
            icons
          >
            <Form.Field
              name="reference1"
              type="text"
              className="form-control input-sm"
              id="beneficiaryReference"
              disabled={this.context.formDisabledFields.reference1}
            />
          </FormGroup>
        </div>

        <div className="form-group row">
          <label htmlFor="beneficiaryCountryToField" className="label-col">Send funds to</label>
          <SelectCountry
            icons
            className="input-sm"
            formGroupClassName="input-col form-group-sm"
            name="destinationCountryId"
            id="destinationCountryId"
            placeholder="Destination country"
            disabled={this.context.formDisabledFields.destinationCountryId}
          />
        </div>
        <BankDetailsForm context={this.state.context} />
        {this.renderBeneficiaryConfirmation()}
      </div>);
  }
}

export const schema = {
  name: yup.string().required('Specify beneficiary name'),
  reference1: yup.string(),
  destinationCountryId: yup.object().default(Config.defaultCountry),
};

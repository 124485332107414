import DataModel from './../../base/models/data-model';

export default class CurrencyModel extends DataModel {

  currency = null;
  sellCurrency = null;
  buyCurrency = null;
  cardCurrency = null;

  /**
   * Returns currency list as array: currencyCode => currencyCode
   */
  getList(callback) {
    if (this.currency) {
      if (typeof callback === 'function') {
        return callback(this.currency);
      }

      return this.currency;
    }

    return this
      .get()
      .then((response) => {
        const currency = [];
        const sellCurrency = [];
        const buyCurrency = [];
        const cardCurrency = [];

        Object.keys(response).forEach((key) => {
          const c = {
            value: key,
            label: key,
          };

          currency.push(c);

          if (response[key].sell) {
            sellCurrency.push(c);
          }
          if (response[key].buy) {
            buyCurrency.push(c);
          }
          if (response[key].card) {
            cardCurrency.push(c);
          }
        });

        this.currency = currency;

        this.setSellCurrency(sellCurrency);
        this.setBuyCurrency(buyCurrency);
        this.setCardCurrency(cardCurrency);

        if (typeof callback === 'function') {
          return callback(this.currency);
        }

        return this.currency;
      });
  }

  /**
   * @param currency
   * @returns {CurrencyModel}
   */
  setSellCurrency(currency) {
    this.sellCurrency = currency;
    return this;
  }

  /**
   * @returns {*}
   */
  getSellCurrency() {
    return this.sellCurrency;
  }

  /**
   * @param currency
   * @returns {CurrencyModel}
   */
  setBuyCurrency(currency) {
    this.buyCurrency = currency;
    return this;
  }

  /**
   * @returns {*}
   */
  getBuyCurrency() {
    return this.buyCurrency;
  }

  /**
   * @param currency
   * @returns {CurrencyModel}
   */
  setCardCurrency(currency) {
    this.cardCurrency = currency;
    return this;
  }

  /**
   * @returns {*}
   */
  getCardCurrency() {
    return this.cardCurrency;
  }
}

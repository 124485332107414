import React from 'react';
import serviceLocator from './../../base/service/service-locator';
import Balances from './../../statement/components/balances';
import SVGInline from "react-svg-inline";

export default class HeaderUser extends React.Component {
  static propTypes = {
    logoutHandler: React.PropTypes.func,
    onClose: React.PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
    this.auth = serviceLocator.get('Auth');
  }

  componentWillMount() {
    if (this.auth.isUserDetailsLoaded()) {
      this.onCurrentUserAvailable();
    }
    this.auth.onCurrentUserLoaded(() => {
      this.onCurrentUserAvailable();
    });
  }

  onCurrentUserAvailable() {
    const currUser = this.auth.getCurrentUser();
    this.setState({
      user: currUser,
    });
  }

  onClickHandler = e => {
    e.preventDefault();
    this.props.logoutHandler();
  };

  render() {
    const {user} = this.state;
    const {onClose} = this.props;

    if (!user || !user.id) {
      return null;
    }

    return (
      <div className="menu-user-data-block">
        <div className="menu-user-data">
          <button onClick={onClose} className="close-btn"><div className="close-icon" /></button>
          <div className="user-header">
            <SVGInline className="user-icon" svg={`<svg
                height="57"
                width="40"
                viewBox="0 0 40 57">
                <path
                  id="path8"
                  d="M 20.464844 0.01953125 A 10.985879 11.008472 0 0 0 9.0175781 11.023438 A 10.985879 11.008472 0 0 0 19.998047 22.027344 A 10.985879 11.008472 0 0 0 20.464844 0.01953125 z M 9.2871094 25.142578 A 9.286177 9.3052749 0 0 0 0 34.447266 L 0 49.865234 C 0 53.811668 8.9551657 57.009766 19.998047 57.009766 C 31.040928 57.009766 40 53.811668 40 49.865234 L 40 34.447266 A 9.286177 9.3052749 0 0 0 30.712891 25.142578 L 9.2871094 25.142578 z " />
              </svg>`}
              />
            <div className="user-details">
              <div className="user-name">{user.name}</div>
              <div className="user-email">{user.email}</div>
            </div>
          </div>
          <div className="account-balances">
            <div className="row">
              <div className="col-xs-8 col-xs-offset-2">
                <div className="my-details-accnr">
                  <label>Account Number:</label>
                  <div>{user.clientSignedId ? user.clientSignedId : user.clientId}</div>
                </div>
              </div>
              <div className="col-xs-8 col-xs-offset-2">
                <div className="available-funds">
                  <label>Available funds:</label>
                  <Balances />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="logout-button text-center">
          <a
            href="#"
            className="btn btn-link btn-header-login"
            onClick={this.onClickHandler}
            >
            Logout
          </a>
        </div>
      </div>
    );
  }
}

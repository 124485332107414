import React from 'react';

const AppHeaderTop = ({}) => (
  <nav className="header-top-wrapper">
    <div className="container">
      <ul className="header-top-list">
        <li><a href="tel:+442077400000" className="link phone">+44 (0)20 7740 0000</a></li>
        <li><a href="https://help.currencysolutions.co.uk" className="link">Help</a></li>
      </ul>
    </div>
  </nav>
);

export default AppHeaderTop;
